export default [
  '#E6F6F1', // 0 additional green
  '#7AEACB', // 1 green-10
  '#1DC899', // 2 green-20
  '#00A175', // 3 green-30
  '#008964', // 4 green-40
  '#00734D', // 5 green-50
  '#E6A008', // 6
  '#E0FFF7', // 7
  '#2DA96D', // 8 icon/success
  '#299963', // 9 primary/green-70
  '#F0FAF5', // 10
]
