import React, { ComponentProps, useEffect, useState } from 'react'
import { StyleAttribute, Unarray } from '../../../types'

import { Box } from '../box'
import { Padder } from '../padder'
import { Text } from '../text'
import { Icon } from '../icon'
import { Checkbox } from '../checkbox'
import { Loader } from '../loader'

import { ContentPart } from './_content'
import { PaginationPart } from './_pagination'
import { RowsPart } from './_row'
import { Tooltip } from '../tooltip'

import Styles from './styles'
import { PaginationCirclePart } from './_pagination-circle'
import { Colors } from '../../../constants'
import { Checkbox as CheckboxDS } from '../../ds/checkbox'

export const Table = ({
  onToogleSort,
  ...props
}: {
  readonly loading?: boolean
  readonly pagination?: boolean
  readonly index?: number
  readonly total?: number
  readonly checkbox?: boolean
  readonly removeItems?: boolean
  readonly headers: {
    title: string
    description?: string | React.ReactNode
    tooltip?: string
    asterisk?: boolean
    circleInfo?: boolean
    key?: string
    width: number
    align?: ComponentProps<typeof Text>['align']
    tooltipConfig?: ComponentProps<typeof Tooltip>['config']
    sort?: boolean
    order?: 'asc' | 'desc'
    onToggleSort?: () => void
    fontSize?: ComponentProps<typeof Text>['size']
  }[]
  readonly rows: (Omit<
    React.ComponentProps<typeof RowsPart>,
    'data' | 'checkbox'
  > & {
    data: Omit<React.ComponentProps<typeof ContentPart>, 'width'>[]
  })[]
  readonly footers?: {
    title?: string
    description?: string
    content?: React.ReactNode
  }[]
  readonly onCheckAll?: (checked: boolean) => void
  readonly onPaginationChange?: (index: number) => void
  readonly onToogleSort?: (key: string) => void
  readonly sort?: string
  readonly order?: 'asc' | 'desc'
  readonly style?: StyleAttribute
  readonly header?: StyleAttribute
  readonly footer?: StyleAttribute
  readonly empty?: React.ReactNode
  readonly paginationStyle?: 'default' | 'circle'
  readonly append?: ComponentProps<typeof RowsPart>
  readonly hidePageNumber?: boolean
  readonly topPagination?: boolean
  readonly fontSize?: ComponentProps<typeof Text>['size']
  readonly type?: 'default' | 'ds'
  readonly disableCheckAll?: boolean
}) => {
  const rows = props.rows.slice()
  const [headers, setHeaders] = useState<
    (Unarray<typeof props.headers> & {
      onToogle: () => void
    })[]
  >([])

  if (props.append) {
    rows.push(props.append)
  }

  useEffect(() => {
    setHeaders(
      props.headers.map((h) => ({
        onToogle: () => {
          if (onToogleSort) {
            onToogleSort(h.key ?? h.title)
          }
        },
        ...h,
      }))
    )
  }, [props.headers, onToogleSort])

  return (
    <Box flex style={props.style}>
      {props.topPagination && (
        <Box>
          {props.paginationStyle === 'circle' ? (
            <PaginationCirclePart
              index={props.index ?? 0}
              total={props.total ?? Infinity}
              onChangeIndex={props.onPaginationChange}
              style={Styles.paging}
              hidePageNumber={props.hidePageNumber}
            />
          ) : props.topPagination ? (
            <PaginationPart
              index={props.index ?? 0}
              total={props.total ?? Infinity}
              onChangeIndex={props.onPaginationChange}
              style={Styles.paging}
              hidePageNumber={props.hidePageNumber}
            />
          ) : (
            false
          )}
        </Box>
      )}

      <Box row style={[Styles.header, props.header, props.style]}>
        {props.checkbox !== undefined &&
          (props.type === 'ds' ? (
            <CheckboxDS
              disabled={props.disableCheckAll}
              style={Styles.checkbox}
              active={props.checkbox}
              onChange={props.onCheckAll}
            />
          ) : (
            <Checkbox
              theme="secondary"
              style={Styles.checkbox}
              active={props.checkbox}
              onChange={props.onCheckAll}
            />
          ))}
        {props.removeItems && <Padder pad width={24} />}
        {headers.map((header, index) => {
          return (
            <ContentPart
              key={index}
              width={header.width}
              onPress={header.onToggleSort ?? header.onToogle}
            >
              <Box flex row centering="v">
                {header.tooltip ? (
                  <Tooltip
                    message={header.tooltip}
                    config={header.tooltipConfig}
                  >
                    <Box row centering="v">
                      <Text
                        size={header.fontSize ?? 'heading-4'}
                        align={header.align}
                        style={Styles.text}
                      >
                        {header.title}
                      </Text>
                      <Text
                        size={header.fontSize ?? 'heading-4'}
                        align={header.align}
                        style={Styles.text}
                      >
                        {header.asterisk && (
                          <Text element="span" style={Styles.required}>
                            {' '}
                            *
                          </Text>
                        )}
                      </Text>

                      {header.circleInfo && (
                        <>
                          {' '}
                          <Icon
                            name="outline.help"
                            size={15}
                            style={Styles.inlineBlock}
                            color={Colors.palette('grey')[8]}
                          />
                        </>
                      )}
                    </Box>
                  </Tooltip>
                ) : (
                  <Box row centering="v">
                    <Text
                      size={header.fontSize ?? 'heading-4'}
                      align={header.align}
                      style={Styles.text}
                    >
                      {header.title}
                    </Text>
                    <Text
                      size={header.fontSize ?? 'heading-4'}
                      align={header.align}
                      style={Styles.text}
                    >
                      {header.asterisk && (
                        <Text element="span" style={Styles.required}>
                          {' '}
                          *
                        </Text>
                      )}
                    </Text>

                    {header.circleInfo && (
                      <>
                        {' '}
                        <Icon
                          name="outline.help"
                          size={15}
                          style={Styles.inlineBlock}
                          color={Colors.palette('grey')[8]}
                        />
                      </>
                    )}
                  </Box>
                )}
                {header.sort && !props.sort && (
                  <Box>
                    <Icon
                      name="arrow.alt.up"
                      size={header.order === 'asc' ? 12 : 10}
                    />
                    <Icon
                      size={header.order === 'desc' ? 12 : 10}
                      name="arrow.alt.down"
                    />
                  </Box>
                )}
                {header.sort && props.sort && (
                  <Box>
                    {props.sort === header.key ? (
                      props.order === 'desc' ? (
                        <Icon name="arrow.alt.down" size={14} />
                      ) : (
                        <Icon name="arrow.alt.up" size={14} />
                      )
                    ) : (
                      <>
                        <Icon name="arrow.alt.up" size={10} />
                        <Icon name="arrow.alt.down" size={10} />
                      </>
                    )}
                  </Box>
                )}
              </Box>
              {header.description ? (
                <Text size="regular" style={Styles.note}>
                  {header.description}
                </Text>
              ) : (
                false
              )}
            </ContentPart>
          )
        })}
      </Box>

      <Box flex>
        {props.loading ? (
          <Box centering style={Styles.loader}>
            <Loader />
          </Box>
        ) : props.rows.length ? (
          <>
            {rows.map((row, index) => (
              <RowsPart
                key={row.id ?? index}
                index={index}
                onPress={row.onPress}
                removeItems={props.removeItems}
                checkbox={props.checkbox !== undefined}
                checked={row.checked}
                data={row.data.map((d, i) => ({
                  ...d,
                  width: props.headers[i]?.width ?? 1,
                }))}
                onRemove={row.onRemove}
                onCheckChange={row.onCheckChange}
                style={row.style}
                error={row.error}
                errorMessage={row.errorMessage}
                fontSize={props.fontSize}
                type={props.type}
                disableCheckbox={
                  props.type === 'ds' ? row.disableCheckbox : false
                }
              />
            ))}
          </>
        ) : (
          props.empty
        )}

        {props.footers && (
          <Box row style={[Styles.footer, props.footer]}>
            {(props.footers ?? []).map((footer, index) => {
              return (
                <ContentPart
                  key={index}
                  width={props.headers[index]?.width ?? 1}
                >
                  {footer.title ? (
                    <Text size="heading-4" style={Styles.text}>
                      {footer.title}
                    </Text>
                  ) : (
                    false
                  )}
                  {footer.description ? (
                    <Text size="regular" style={Styles.note}>
                      {footer.description}
                    </Text>
                  ) : (
                    false
                  )}
                  {footer.content && footer.content}
                </ContentPart>
              )
            })}
          </Box>
        )}
      </Box>
      <Box>
        {props.pagination && props.paginationStyle === 'circle' ? (
          <PaginationCirclePart
            index={props.index ?? 0}
            total={props.total ?? Infinity}
            onChangeIndex={props.onPaginationChange}
            style={Styles.paging}
            hidePageNumber={props.hidePageNumber}
          />
        ) : props.pagination ? (
          <PaginationPart
            index={props.index ?? 0}
            total={props.total ?? Infinity}
            onChangeIndex={props.onPaginationChange}
            style={Styles.paging}
            hidePageNumber={props.hidePageNumber}
          />
        ) : (
          false
        )}
      </Box>
    </Box>
  )
}
