import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  to: {
    margin: [0, Sizes.margin.default],
  },

  flex: {
    flex: 1,
  },

  upperText: {
    margin: [0, 0, Sizes.margin.pad],
    color: Colors.palette('grey')[4],
  },

  marginTopDefault: {
    marginTop: Sizes.margin.default,
  },
})
