export default [
  '#F8F8F8', // 0  grey-10
  '#EDEDED', // 1  grey-20
  '#CACCCF', // 2  grey-30
  '#A0A4A8', // 3  grey-40
  '#52575C', // 4  grey-50
  '#65686C', // 5  Text/Subdued
  '#979B9F', // 6  Checkbox border Text/Secondary
  '#C9CBCF', // 7  Icon/Disabled
  '#777B7E', // 8  Icon/Subdued
  '#53575A', // 9  Text/Subdued
  '#F9FAFA', // 10 Surface/Subdued
  '#D9DBDD', // 11 Inner Border
  '#1E1F1F', // 12 Extended gray-100
  '#2F3031', // 13 Extended gray-90
  '#EFF0F0', // 14 Surface/Hovered
  '#C4C4C4', // 15
  '#808489', // 16
  '#E5E5E5', // 17
  '#EFEFEF', // 18
  '#25282B', // 19
  '#45474A', // 20
]
