import React, {
  ComponentProps,
  ReactElement,
  useCallback,
  useState,
} from 'react'
import { CommonHelper } from '../../../helpers'
import { Defaults } from '../../../constants'
import { useUpdate } from '../../../hooks'

import { Box } from '../box'
import { InputCalendar } from '../input.calendar'
import { Text } from '../text'

import Styles from './styles'

export function InputDuration(
  props: Omit<
    ComponentProps<typeof InputCalendar>,
    'value' | 'onChange' | 'onSubmit'
  > & {
    readonly testId?: string
    readonly value?: [Date, Date]
    readonly onChange?: (duration?: [Date, Date]) => void
    readonly onSubmit?: (duration?: [Date, Date]) => void
  }
): ReactElement {
  const {
    fit,
    value,
    autofocus,
    dates: propDates,
    onChange,
    onSubmit,
    style,
    middleText,
    upperTextFrom,
    upperTextTo,
    ...otherProps
  } = props
  const [dates, setDates] = useState<[Date, Date]>([
    CommonHelper.date.valid(propDates ? propDates[0] : undefined)
      ? (propDates as Date[])[0]
      : new Date(
          Defaults.YEAR - 10,
          new Date().getMonth(),
          new Date().getDate()
        ),
    CommonHelper.date.valid(propDates ? propDates[1] : undefined)
      ? (propDates as Date[])[1]
      : new Date(
          Defaults.YEAR + 10,
          new Date().getMonth(),
          new Date().getDate()
        ),
  ])
  const [date, setDate] = useState<[Date?, Date?]>(
    value
      ? [
          CommonHelper.date.valid(value[0]) ? value[0] : undefined,
          CommonHelper.date.valid(value[1]) ? value[1] : undefined,
        ]
      : [undefined, undefined]
  )

  const onInputChange = useCallback(
    (index: number) => {
      return (d?: Date) => {
        const newDate: [Date?, Date?] = [
          index === 0 ? d : date[0],
          index === 1 ? d : date[1],
        ]

        setDate(newDate)

        if (onChange) {
          if (newDate[0] && newDate[1]) {
            onChange(newDate as [Date, Date])
          } else {
            onChange(undefined)
          }
        }
      }
    },
    [date, setDate, onChange]
  )

  const onInputSubmit = useCallback(() => {
    if (onSubmit) {
      if (date[0] && date[1]) {
        onSubmit(date as [Date, Date])
      } else {
        onSubmit(undefined)
      }
    }
  }, [date, onSubmit])

  useUpdate(() => {
    // Update on propDates changed
    setDates([
      CommonHelper.date.valid(propDates ? propDates[0] : undefined)
        ? (propDates as Date[])[0]
        : new Date(
            Defaults.YEAR - 10,
            new Date().getMonth(),
            new Date().getDate()
          ),
      CommonHelper.date.valid(propDates ? propDates[1] : undefined)
        ? (propDates as Date[])[1]
        : new Date(
            Defaults.YEAR + 10,
            new Date().getMonth(),
            new Date().getDate()
          ),
    ])
  }, [propDates])

  useUpdate(() => {
    // // Update on props.value changed
    setDate(
      value
        ? [
            CommonHelper.date.valid(value[0]) ? value[0] : undefined,
            CommonHelper.date.valid(value[1]) ? value[1] : undefined,
          ]
        : [undefined, undefined]
    )
  }, [value])

  return (
    <Box row centering="v" style={style}>
      <Box style={Styles.flex}>
        {upperTextFrom && (
          <Text size="regular" style={Styles.upperText}>
            {upperTextFrom}
          </Text>
        )}
        <InputCalendar
          {...otherProps}
          testId={props.testId && `${props.testId}-from`}
          value={date[0]}
          dates={[dates[0], date[1] ?? dates[1]]}
          fit={fit ?? true}
          autofocus={autofocus}
          onChange={onInputChange(0)}
          onSubmit={onInputSubmit}
          enableBackDate={props.enableBackDate}
          bodyCalendar={props.bodyCalendar}
        />
      </Box>
      <Text
        size="regular"
        style={[Styles.to, upperTextFrom && Styles.marginTopDefault]}
      >
        {middleText ?? 'to'}
      </Text>
      <Box style={Styles.flex}>
        {upperTextTo && (
          <Text size="regular" style={Styles.upperText}>
            {upperTextTo}
          </Text>
        )}
        <InputCalendar
          {...otherProps}
          testId={props.testId && `${props.testId}-to`}
          defaultValue={date[0]}
          value={date[1]}
          dates={[date[0] ?? dates[0], dates[1]]}
          fit={fit ?? true}
          onChange={onInputChange(1)}
          onSubmit={onInputSubmit}
          bodyCalendar={props.bodyCalendar}
        />
      </Box>
    </Box>
  )
}
