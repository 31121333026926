import { StyleSheet } from '../libs/com'
import { Colors } from '../constants'

export const CommonStyles = StyleSheet.create({
  transition: {
    transition: '.3s all',
  },

  gradient: {
    background: `${Colors.palette('turquoise')[2]} linear-gradient(257.46deg, ${Colors.primary} -11.86%, ${
      Colors.palette('turquoise')[2]
    } 124.4%)`,
  },
})
