import JSS, {
  JssStyle,
  SheetsRegistry,
  StyleSheet as SS,
  Classes as C,
  createGenerateId,
  GenerateId,
  StyleSheetFactoryOptions,
} from 'jss'
import flattenDeep from 'lodash/flattenDeep'
import { Properties } from 'csstype'
import { Style, StyleAttribute } from '../../../types'

class StyleSheetModel {
  private base = {
    className: '',
    style: undefined,
  }

  private registry = new SheetsRegistry()
  private generateId = createGenerateId()

  private classNameFromStyle(style: string | false | null | undefined | Style) {
    if (style && typeof style !== 'string') {
      const sheet = JSS.createStyleSheet(
        {
          inline: style as JssStyle,
        },
        {
          generateId: this.generateId,
        }
      )

      this.registry.add(sheet)

      if (typeof window !== undefined) {
        sheet.attach()
      }

      return sheet.classes.inline
    } else {
      return style
    }
  }

  private classNameAndStyleFromStyle(
    sum: {
      className: string
      style?: Properties<string | number>
    } = this.base,
    style: string | false | null | undefined | Style
  ): {
    className: string
    style?: Properties<string | number>
  } {
    if (style && typeof style !== 'string') {
      return {
        ...sum,
        style: sum.style
          ? ({
              ...sum.style,
              ...style,
            } as Properties<string | number>)
          : (style as Properties<string | number>),
      }
    } else {
      return {
        ...sum,
        className: sum.className ? sum.className + ' ' + style : (style as string),
      }
    }
  }

  public create<K extends string>(
    styles: Record<K, Style | Record<string, Style>>,
    options?: StyleSheetFactoryOptions
  ) {
    // const sheet = createUseStyles(styles as Record<K, string>)
    const sheet = JSS.createStyleSheet(styles as Record<K, string>, {
      generateId: this.generateId,
      ...options,
    })

    this.registry.add(sheet)

    if (typeof window !== undefined) {
      sheet.attach()
    }

    return sheet.classes
  }

  public className(classes: StyleAttribute): string | undefined {
    return Array.isArray(classes)
      ? flattenDeep(classes)
          .map((c) => this.classNameFromStyle(c))
          .filter((c) => !!c)
          .join(' ')
      : this.classNameFromStyle(classes) || undefined
  }

  public classNameAndStyle(classes: StyleAttribute) {
    return Array.isArray(classes)
      ? flattenDeep(classes)
          .filter((c) => !!c)
          .reduce(this.classNameAndStyleFromStyle, this.base)
      : this.classNameAndStyleFromStyle(this.base, classes)
  }

  public reinitRegistry(registry: SheetsRegistry, generateId: GenerateId) {
    this.registry = registry
    this.generateId = generateId
    // this.registry.reset()
  }

  public getStyleSheetString() {
    return this.registry.toString()
  }
}

export const StyleSheet = new StyleSheetModel()

export type Classes<K extends SS> = K extends SS<infer U> ? C<U> : never
