import { ReactNode } from 'react'
import { Colors, STATE } from '../../../constants'
import { Icon } from '../../blocks/icon'

export type State = {
  state?: STATE
  message?: string
  loading?: boolean
  children?: ReactNode
  icon?: boolean | React.ComponentProps<typeof Icon>['name']
}

export const color = (state?: STATE, loading?: boolean, disabled?: boolean) => {
  if (loading || disabled) {
    return Colors.palette('grey')[2]
  }

  switch (state) {
    case STATE.ERROR:
      return Colors.error
    case STATE.VALID: // there is
    default:
      return Colors.opacity(Colors.black, 0.6)
  }
}
