import React from 'react'
import { StyleSheet } from '../../../libs/com'
import { CommonHelper } from '../../../helpers'
import { Colors, STATE } from '../../../constants'
import { StyleAttribute } from '../../../types'

import { Box } from '../../blocks/box'
import { Icon } from '../../blocks/icon'
import { Loader } from '../../blocks/loader'
import { Text } from '../../blocks/text'

import { State, color } from './__helper'

import Styles from './styles'

const text = CommonHelper.fn.memoize((state?: STATE, loading?: boolean, disabled?: boolean) => {
  if (loading || disabled) {
    return StyleSheet.create({
      text: {
        color: Colors.palette('grey')[2],
      },
    }).text
  }

  return StyleSheet.create({
    text: {
      color: color(state, loading, disabled),
    },
  }).text
})

export const MessagePart = ({
  icon = false,
  message,
  loading,
  state,
  children,
  style,
}: State & {
  style?: StyleAttribute
}) => {
  return (
    (children as JSX.Element) ??
    (message ? (
      <Box row style={[Styles.message, style]}>
        {loading ? (
          <Box style={Styles.icon}>
            <Loader size={16} color={Colors.palette('grey')[2]} />
          </Box>
        ) : icon !== false ? (
          <Icon
            name={typeof icon === 'string' ? icon : 'confirm'}
            size={12}
            color={color(state, loading)}
            style={Styles.icon}
          />
        ) : (
          false
        )}
        <Text size="tiny" style={text(state, loading)}>
          {message}
        </Text>
      </Box>
    ) : null)
  )
}
