import React from 'react'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Loader } from '../loader'
import { Shadow } from '../shadow'
import { Text } from '../text'

import Styles from './styles'

export const PageLoader = (props: {
  readonly children?: React.ReactNode | string
  readonly style?: StyleAttribute
}) => {
  return (
    <Shadow style={Styles.shadow}>
      <Box centering style={[Styles.container, props.style]}>
        <Loader />
        {typeof props.children === 'string' ? (
          <Text size="small" style={Styles.text}>
            {props.children}
          </Text>
        ) : (
          props.children
        )}
      </Box>
    </Shadow>
  )
}
