import React, { useCallback } from 'react'
import { Colors } from '../../../constants'
import { AlertConfig } from '../../../types'

import { Box, Icon, Text } from '../../blocks'
import { ButtonPart } from './_button'

import Styles from './styles'

export const PromptPart = ({
  onCloseModal,
  onClose: propsOnClose,
  ...props
}: Readonly<AlertConfig> & {
  readonly accessible: boolean
  readonly onCloseModal: () => void
}) => {
  const closable = props.closable ?? true

  const onClose = useCallback(() => {
    onCloseModal()

    if (propsOnClose) {
      propsOnClose()
    }
  }, [onCloseModal, propsOnClose])

  return (
    <Box style={[Styles.prompt, props.style]}>
      <Box row spread={!props.icon ? 'between' : 'flex-end'}>
        {!props.icon && (
          <Text size="heading-2" style={Styles.padder}>
            {props.title}
          </Text>
        )}
        {closable ? (
          <Box style={Styles.closer}>
            <Icon
              name="decline"
              onPress={onClose}
              color={Colors.palette('grey')[3]}
            />
          </Box>
        ) : null}
      </Box>
      {props.icon ? (
        <Box row>
          <Icon
            name={props.icon.name}
            size={40}
            color={props.icon.color ?? Colors.error}
            style={[Styles.icon, Styles.padder]}
          />
        </Box>
      ) : (
        false
      )}

      {props.icon && (
        <Text size="heading-2" style={Styles.padder}>
          {props.title}
        </Text>
      )}

      <Box style={Styles.content}>
        {typeof props.message === 'string' ? (
          <Text size="regular">{props.message}</Text>
        ) : (
          props.message
        )}
      </Box>
      <Box row centering="v">
        {props.actions.map((action, i) => {
          return (
            <ButtonPart
              key={i}
              type={action.type}
              title={action.title}
              onPress={() => {
                if (action.onPress) {
                  action.onPress()
                }

                onCloseModal()
              }}
              style={[Styles.action, action.style]}
            />
          )
        })}
      </Box>
    </Box>
  )
}
