import React, { ComponentProps, useCallback, useState } from 'react'
import type { StyleSheet as SS } from 'jss'
import { Classes, StyleSheet } from '../../../libs/com'
import { useUpdate } from '../../../hooks'
import { CommonHelper } from '../../../helpers'
import { CommonStyles } from '../../../styles'
import { IconName, Style, StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Icon } from '../icon'
import { Loader } from '../loader'
import { Text } from '../text'

import Styles from './styles'

type State = 'normal' | 'active' | 'disabled' | 'loading'

function getState(
  pressing?: boolean,
  disabled?: boolean,
  loading?: boolean,
  active?: boolean
): State {
  if (disabled) {
    return 'disabled'
  } else if (loading) {
    return 'loading'
  } else if (active) {
    return 'active'
  } else if (pressing) {
    return 'active'
  } else {
    return 'normal'
  }
}

export function ButtonBlockFactory<
  Themes extends {
    [key: string]: {
      [k in State]: {
        foreground: string
        style?: Style | Record<string, Style>
      }
    } & {
      style: Style | Record<string, Style>
    }
  },
  SizeConfig extends {
    [key: string]: {
      text: ComponentProps<typeof Text>['size']
      weight: ComponentProps<typeof Text>['weight']
      height: number
      width?: number
    }
  }
>(
  themes: Themes,
  sizes: SizeConfig,
  defaults: {
    family: ComponentProps<typeof Text>['family']
    theme: keyof Themes
    size: keyof SizeConfig
  }
) {
  type Size = keyof SizeConfig
  type Theme = keyof Themes

  // create style
  const ThemeStyles = StyleSheet.create(
    CommonHelper.object
      .keys(themes)
      .map((theme) => {
        return {
          [theme]: themes[theme].style,
          ...(themes[theme].normal.style
            ? {
                [`${theme}-normal`]: themes[theme].normal.style,
              }
            : {}),
          ...(themes[theme].active.style
            ? {
                [`${theme}-active`]: themes[theme].active.style,
              }
            : {}),
          ...(themes[theme].disabled.style
            ? {
                [`${theme}-disabled`]: themes[theme].disabled.style,
              }
            : {}),
          ...(themes[theme].loading.style
            ? {
                [`${theme}-loading`]: themes[theme].loading.style,
              }
            : {}),
        } as Record<string, Style>
      })
      .reduce(CommonHelper.reduce.object, {})
  ) as Classes<SS<Theme | `${string & Theme}-${State}`>>

  return function Button({
    onPress: propsOnPress,
    onDoublePress: propsOnDoublePress,
    ...props
  }: {
    readonly theme?: Theme
    readonly size?: Size
    readonly ellipsis?: boolean | number
    readonly block?: boolean
    readonly reverse?: boolean
    readonly icon?:
      | IconName
      | {
          name: IconName
          size: number
          color?: string
          style?: StyleAttribute
        }
    readonly iconRight?:
      | IconName
      | {
          name: IconName
          size: number
          color?: string
          style?: StyleAttribute
        }
    readonly weight?: ComponentProps<typeof Text>['weight']
    readonly flex?: boolean
    readonly align?: ComponentProps<typeof Text>['align']
    readonly loading?: boolean
    readonly disabled?: boolean
    readonly active?: boolean
    readonly tracker?: string
    readonly trackerParam?: Record<string, string>
    readonly children?: string | ((foreground: string) => React.ReactNode)
    readonly onPress?: () => void
    readonly onDoublePress?: () => void
    readonly style?: StyleAttribute
  }) {
    // const Component = Text as React.ComponentType<Pick<ComponentProps<Text>, 'family' | 'selectable' | 'size' | 'align' | 'weight' | 'style' | 'children'>>
    const [theme, setTheme] = useState(themes[props.theme ?? defaults.theme])
    const [size, setSize] = useState(sizes[props.size ?? defaults.size])
    const [pressing, setPressing] = useState(false)
    const state: State = getState(
      pressing,
      props.disabled,
      props.loading,
      props.active
    )

    const onPress = useCallback(
      (e: React.PointerEvent<HTMLDivElement>) => {
        if (propsOnPress) {
          e.preventDefault()
          e.stopPropagation()
          propsOnPress()
        }
      },
      [propsOnPress]
    )

    const onDoublePress = useCallback(
      (e: React.PointerEvent<HTMLDivElement>) => {
        if (propsOnDoublePress) {
          e.preventDefault()
          e.stopPropagation()
          propsOnDoublePress()
        }
      },
      [propsOnDoublePress]
    )

    useUpdate(() => {
      setTheme(themes[props.theme ?? defaults.theme])
    }, [props.theme])

    useUpdate(() => {
      setSize(sizes[props.size ?? defaults.size])
    }, [props.size])

    return (
      <Box
        accessible={state === 'normal' || state === 'active'}
        flex={props.flex}
        feedback={false}
        tracker={props.tracker}
        trackerParam={props.trackerParam}
        onChangePress={setPressing}
        onPress={onPress}
        onDoublePress={onDoublePress}
        style={[
          Styles.container,
          CommonStyles.transition,
          ThemeStyles[props.theme ?? defaults.theme],
          ThemeStyles[`${props.theme ?? defaults.theme}-${state}`],
          {
            height: size.height,
            width: size.width,
          },
          props.style,
          props.block && {
            width: '100%',
          },
        ]}
      >
        <Box
          flex
          reverse={props.reverse}
          row={!!props.icon || !!props.iconRight}
          centering={props.icon || props.iconRight ? true : 'v'}
          style={state === 'loading' ? Styles.hidden : undefined}
        >
          {props.icon ? (
            <Icon
              name={
                typeof props.icon === 'string' ? props.icon : props.icon.name
              }
              color={
                typeof props.icon === 'string'
                  ? theme[state].foreground
                  : props.icon.color ?? theme[state].foreground
              }
              size={
                typeof props.icon === 'string' ? undefined : props.icon.size
              }
              style={typeof props.icon === 'string' ? false : props.icon.style}
            />
          ) : (
            false
          )}
          {props.children ? (
            typeof props.children === 'string' ? (
              <Text
                family={defaults.family as undefined}
                ellipsis={!!props.ellipsis}
                lines={
                  !!props.ellipsis && typeof props.ellipsis === 'number'
                    ? props.ellipsis
                    : undefined
                }
                selectable={false}
                size={size.text}
                align={props.align ?? 'center'}
                color={theme[state].foreground}
                style={Styles.title}
                weight={props.weight ?? size.weight}
              >
                {props.children}
              </Text>
            ) : (
              props.children(theme[state].foreground)
            )
          ) : (
            false
          )}
          {props.iconRight ? (
            <Icon
              name={
                typeof props.iconRight === 'string'
                  ? props.iconRight
                  : props.iconRight.name
              }
              color={
                typeof props.iconRight === 'string'
                  ? theme[state].foreground
                  : props.iconRight.color ?? theme[state].foreground
              }
              size={
                typeof props.iconRight === 'string'
                  ? undefined
                  : props.iconRight.size
              }
              style={
                typeof props.iconRight === 'string'
                  ? false
                  : props.iconRight.style
              }
            />
          ) : (
            false
          )}
        </Box>
        {state === 'loading' ? (
          <Box centering style={Styles.loader}>
            <Loader color={theme[state].foreground} size={size.height * 0.7} />
          </Box>
        ) : (
          false
        )}
      </Box>
    )
  }
}
