import { Router } from 'recore/libs/advanced/router'

const config: Parameters<typeof Router['Root']>[0] = [
  {
    type: 'route',
    component: Router.Page(() => import('pages/maintenance')),
  },
]

export default function () {
  return Router.Root(config)
}
