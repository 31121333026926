import React, {
  ReactElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA, StyleSheet } from '../../../libs/com'
import { AppContext, PageContext } from '../../../contexts'
import { useUpdate } from '../../../hooks'
import { Colors } from '../../../constants'
import { Lang, StyleAttribute } from '../../../types'
import { InputStyles } from '../../../styles'

import { Box } from '../box'
import { Button } from '../button'
import { Icon } from '../icon'
import { Loader } from '../loader'
import { Text } from '../text'

import Styles from './style'

enum DOCUMENT_STATUSES {
  ON_REVIEW = 'ON_REVIEW',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  // added for monthly report document status
  NEED_REVISE = 'NEED_REVISE',
  APPROVED = 'APPROVED',
  ON_HOLD = 'ON_HOLD',
}

async function getBase64(files: FileList) {
  return Promise.resolve(files).then((fs) => {
    return Promise.all(
      Array.from(fs).map(async (file) => {
        return new Promise<string>((res, rej) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => res(reader.result as string)
          reader.onerror = (error) => rej(error)
        })
      })
    )
  })
}

function getButtonTitle(lang: Lang, value: boolean, placeholder?: string) {
  switch (lang) {
    case 'en':
      return value ? 'Browse' : placeholder ?? 'Select file'
    case 'id':
      return value ? 'Ganti File' : placeholder ?? 'Pilih FIle'
  }
}

function getDeleteButtonTitle(lang: Lang) {
  switch (lang) {
    case 'en':
      return 'Delete file'
    case 'id':
      return 'Hapus file'
  }
}

export function InputFileSecondary({
  onBeforeClear,
  onChange: propsOnChange,
  onChangeFocus,
  onSubmit,
  ...props
}: {
  readonly lang?: Lang
  readonly value?: string
  readonly fit?: boolean
  readonly autofocus?: boolean
  readonly placeholder?: string
  readonly loading?: boolean
  readonly accept?: string
  readonly multiple?: boolean
  readonly clearable?: boolean
  readonly valid?: boolean
  readonly readonly?: boolean
  readonly disabled?: boolean
  readonly tabindex?: number
  readonly buttonPosition?:
    | 'between'
    | 'around'
    | 'evenly'
    | 'flex-end'
    | undefined
  readonly tracker?: string
  readonly status?: DOCUMENT_STATUSES
  readonly trackerParam?: Record<string, string>
  readonly onBeforeClear?: () => Promise<boolean>
  readonly onChange?: (
    files: FileList | undefined,
    base64?: Promise<string[]>
  ) => void
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onSubmit?: (
    date: FileList | undefined,
    base64?: Promise<string[]>
  ) => void
  readonly input?: StyleAttribute
  readonly style?: StyleAttribute
  readonly buttonStyle?: StyleAttribute
  readonly buttonTheme?:
    | 'black'
    | 'white'
    | 'text'
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'text-primary'
    | 'border-text'
    | 'border-text-secondary'
    | 'border-text-error'
    | 'text-error-border'
  fullDs?: boolean
  downloadText?: string
}): ReactElement {
  const app = useContext(AppContext)
  const page = useContext(PageContext)
  const input = useRef<HTMLInputElement>(null)
  const fit = props.fit ?? false
  const lang = props.lang ?? app.lang ?? 'id'
  const clearable = props.clearable ?? true
  const placeholder =
    props.placeholder ?? (lang === 'id' ? 'Pilih File' : 'Select a file')
  const isMobile = app.media.smaller('tablet')
  const [loading, setLoading] = useState(false)
  const [clearer, setClearer] = useState(0)
  const [value, setValue] = useState(props.value || '')
  const [files, setFiles] = useState<FileList | null>(null)
  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(() => {
    setFocused(true)

    onChangeFocus?.(true)
  }, [onChangeFocus])

  const onBlur = useCallback(() => {
    setFocused(false)

    onChangeFocus?.(false)
  }, [onChangeFocus])

  const onClear = useCallback(() => {
    Promise.resolve()
      .then(() => {
        setLoading(true)
        if (onBeforeClear) return onBeforeClear()
        return true
      })
      .then((clear) => {
        if (!clear) return

        setClearer(clearer + 1)
        setFiles(null)
        setValue('')

        propsOnChange?.(undefined, undefined)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [clearer, onBeforeClear, propsOnChange])

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onSubmit && e.key === 'Enter') {
        if (onSubmit.length > 1) {
          onSubmit(files || undefined, files ? getBase64(files) : undefined)
        } else {
          onSubmit(files || undefined)
        }
      }
    },
    [onSubmit, files]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFiles(e.currentTarget.files)
      setValue(
        e.currentTarget.files
          ? Array.from(e.currentTarget.files)
              .map((f) => f.name)
              .join(', ')
          : ''
      )

      propsOnChange?.(
        e.currentTarget.files || undefined,
        e.currentTarget.files ? getBase64(e.currentTarget.files) : undefined
      )
    },
    [propsOnChange]
  )

  const onPressDownload = useCallback(() => {
    page.navigator.tab(`files?name=${props.value}`)
  }, [page.navigator, props.value])

  const onPress = useCallback(() => {
    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }

    if (input.current) {
      input.current.click()
    }
  }, [props.tracker, props.trackerParam])

  // Update focus when props autofocus changed
  useLayoutEffect(() => {
    if (props.autofocus) {
      setFocused(true)
    }
  }, [props.autofocus])

  // Update when receiving new preview link
  useUpdate(() => {
    setValue(props.value ?? '')
  }, [props.value])

  return (
    <Box
      style={[
        Styles.container,
        fit && InputStyles.fit,
        props.disabled
          ? InputStyles['container-disabled']
          : props.readonly
          ? InputStyles['container-readonly']
          : undefined,
        props.valid && InputStyles['container-valid'],
        props.valid === false && InputStyles['container-invalid'],
        focused && InputStyles['container-focused'],
        props.fullDs && Styles.inputfile,
        props.style,
      ]}
    >
      {value && !props.loading && !loading && (
        <Box>
          {value ? (
            <input
              readOnly
              tabIndex={-1}
              disabled={props.disabled}
              placeholder={placeholder}
              value={value}
              {...StyleSheet.classNameAndStyle([
                InputStyles.input,
                props.disabled
                  ? InputStyles['input-disabled']
                  : props.readonly && InputStyles['input-readonly'],
                props.valid && InputStyles['input-valid'],
                props.valid === false && InputStyles['input-invalid'],
                focused && InputStyles['input-focused'],
                Styles.input,
                props.input,
              ])}
            />
          ) : (
            false
          )}
        </Box>
      )}
      <input
        type="file"
        key={clearer}
        ref={input}
        readOnly={props.readonly}
        disabled={props.disabled}
        autoFocus={props.autofocus}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onChange={onChange}
        tabIndex={props.tabindex}
        accept={props.accept}
        multiple={props.multiple}
        {...StyleSheet.classNameAndStyle(Styles.file)}
      />
      <Box
        row
        spread={
          value
            ? 'between'
            : isMobile
            ? 'flex-end'
            : props.buttonPosition ?? 'around'
        }
        centering="v"
      >
        {(value && (loading || props.loading) ? (
          <Box centering style={Styles.clearer}>
            <Loader />
          </Box>
        ) : undefined) ??
          (value && !props.readonly && !props.disabled && clearable ? (
            <Box row centering="v" onPress={onClear} style={Styles.clearer}>
              <Icon
                name="decline"
                size={18}
                color={Colors.error}
                style={Styles.icon}
              />
              <Text size="heading-5" color={Colors.error}>
                {getDeleteButtonTitle(lang)}
              </Text>
            </Box>
          ) : value ? (
            // To move the button to the right if it has value
            <Box />
          ) : (
            false
          ))}
        {props.status === DOCUMENT_STATUSES.VERIFIED ||
        props.status === DOCUMENT_STATUSES.APPROVED ||
        props.status === DOCUMENT_STATUSES.ON_HOLD ||
        props.status === DOCUMENT_STATUSES.ON_REVIEW ? (
          <Button
            loading={loading || props.loading}
            theme={'secondary'}
            size="small"
            style={[Styles.button]}
            onPress={onPressDownload}
          >
            {props.downloadText ?? 'Download File'}
          </Button>
        ) : (
          <Button
            loading={loading || props.loading}
            theme={value ? 'secondary' : props.buttonTheme ?? 'primary'}
            size="small"
            style={[
              Styles.button,
              props.fullDs && { borderRadius: 12 },
              props.buttonStyle,
            ]}
            disabled={props.readonly || props.disabled}
            onPress={onPress}
          >
            {getButtonTitle(lang, !!value, placeholder)}
          </Button>
        )}
      </Box>
    </Box>
  )
}
