import React, { useContext } from 'react'
import { AppContext } from '../../../contexts'

import { Box } from '../box'

type Size = React.ContextType<typeof AppContext>['media']['size']

export function Wrapper(
  props: React.ComponentProps<typeof Box> & {
    readonly width: number | string
    readonly is?: Size[] | Size
    readonly larger?: Size
    readonly smaller?: Size
    readonly wrap?: boolean
  }
) {
  const { width, is, larger, smaller, wrap: wrapp, ...prop } = props
  const app = useContext(AppContext)
  const wrap =
    wrapp ??
    ((is && (Array.isArray(is) ? app.media.is(...is) : app.media.is(is))) ||
      (larger && app.media.larger(larger)) ||
      (smaller && app.media.smaller(smaller)) ||
      false)

  return wrap ? (
    <Box flex={prop.flex} centering="h">
      <Box
        {...prop}
        style={[
          {
            width,
          },
          props.style,
        ]}
      />
    </Box>
  ) : (
    <Box {...prop} />
  )
}
