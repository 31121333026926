import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    zIndex: 1,
  },

  navigation: {
    position: 'absolute',
    margin: [Sizes.margin.half, 0],
    borderWidth: 1,
    borderColor: Colors.palette('grey')[1],
    borderRadius: 8,
    background: Colors.white,
    width: 'max-content',
    overflow: 'hidden',
  },

  big: {
    padding: [Sizes.margin.half, Sizes.margin.compact],
    border: 0,
  },

  block: {
    width: '100%',
  },

  clicker: {
    cursor: 'pointer',
    alignSelf: 'flex-start',
    width: '100%',
  },

  button: {
    pointerEvents: 'none',
  },

  top: {
    top: '100%',
  },

  bottom: {
    bottom: '100%',
  },

  left: {
    left: 0,
  },

  right: {
    right: 0,
  },
})
