import React, { ReactNode, useCallback, useContext, useState } from 'react'
import { useMount, useUpdate } from '../../../hooks'
import { AppContext, FormContext } from '../../../contexts'
import { CommonHelper, StateHelper } from '../../../helpers'
import { STATE } from '../../../constants'
import { Lang } from '../../../types'

import { State } from './__helper'
import { StatedPart, Props } from './_stated'

type Compile = boolean | 'state'

function getMessage(lang: Lang, type: Props['type'] | 'require') {
  switch (type) {
    case 'require':
    default:
      switch (lang) {
        case 'en':
        default:
          return 'Field cannot be empty'
        case 'id':
          return 'Wajib diisi'
      }
    case 'email':
      switch (lang) {
        case 'en':
        default:
          return 'Email format invalid'
        case 'id':
          return 'Format email tidak valid'
      }
    case 'tel':
      switch (lang) {
        case 'en':
        default:
          return 'Phone number invalid'
        case 'id':
          return 'Nomor ponsel harus terdiri atas 7-13 digit angka'
      }
    case 'password':
      switch (lang) {
        case 'en':
        default:
          return 'Password must be at least 8 characters with combinations of lowercase, uppercase and number'
        case 'id':
          return 'Minimal 8 karakter dan mengandung kombinasi huruf kecil, huruf besar, dan angka'
      }
  }
}

function requireField(
  use: boolean,
  value:
    | boolean
    | string
    | Date
    | null
    | number
    | undefined
    | [Date, Date]
    | FileList,
  lang: Lang,
  message?: string
): State | null {
  if (use) {
    return value || value === 0
      ? {
          state: STATE.VALID,
        }
      : message
      ? {
          message: message,
          state: STATE.ERROR,
          icon: false,
        }
      : {
          message: getMessage(lang, 'require'),
          state: STATE.ERROR,
          icon: false,
        }
  }

  return null
}

function validation(
  use: boolean,
  value: string | Date,
  lang: Lang,
  type?: Props['type'],
  message?: string
): State | null {
  if (use) {
    switch (type) {
      case 'email':
        return (value as string).match(
          /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
          ? {
              state: STATE.VALID,
            }
          : {
              message: message ?? getMessage(lang, type),
              state: STATE.ERROR,
              icon: false,
            }
      // case 'number':
      // 	return (value as string).match(/^[+-]?[0-9,.]*/) ? {
      // 		state: STATE.VALID,
      // 	} : {
      // 		message: message ?? 'Number format invalid',
      // 		state: STATE.ERROR,
      // 		icon: false,
      // 	}
      case 'tel':
        return (value as string).match(/^\+?\d{7,13}$/)
          ? {
              state: STATE.VALID,
            }
          : {
              message: message ?? getMessage(lang, type),
              state: STATE.ERROR,
              icon: false,
            }
      case 'password':
        return (value as string).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)
          ? {
              state: STATE.VALID,
            }
          : {
              message: message ?? getMessage(lang, type),
              state: STATE.ERROR,
              icon: false,
            }
      case 'text':
      default:
        return null
    }
  }

  return null
}

function updateStates(
  compile: Compile,
  description: string | State | ReactNode | undefined,
  ...states: (State | null)[]
) {
  const cleanStates = states.filter((s) => s !== null) as State[]
  const def: State[] = []

  if (compile) {
    if (compile === true && description) {
      if (typeof description === 'string') {
        def.push({
          state: STATE.VALID,
          message: description,
        })
      } else {
        def.push({
          state: STATE.VALID,
          children: description,
        })
      }
    }

    return cleanStates.concat(def).reduce((sum, s) => {
      if (sum[0]?.state === STATE.ERROR) {
        return sum
      } else {
        return [s]
      }
    }, [] as State[])
  } else {
    return cleanStates
  }
}

type Input = {
  readonly id?: string
  readonly lang?: Lang
  readonly validate?: boolean | string
  readonly required?: boolean | string
  readonly compile?: Compile
  readonly validateOn?: 'blur' | 'change' | 'mixed'
  readonly revalidate?: number | boolean | string
  readonly validateDebounce?: number
  readonly onStateChange?: (state: STATE) => void
  readonly bodyCalendar?: ReactNode
}

type ValidationParam = Parameters<Exclude<Props['validation'], undefined>>

export function InputComponent(props: Props & Input) {
  const app = useContext(AppContext)

  const {
    id,
    validate = false,
    required = false,
    compile = true,
    description,
    validateOn = 'mixed',
    validation: propValidation,
    revalidate,
    state: propState,
    states: propStates = CommonHelper.defaults.array,
    value: propValue = '',
    type = 'text',
    lang = app.lang ?? 'en',
    validateDebounce,
    onChange,
    onStateChange,
    onChangeFocus,
    ...statedProps
  } = props

  const form = useContext(FormContext)
  const [requiredState, setRequired] = useState<State | null>(null)
  const [validationState, setValidation] = useState<State | null>(null)
  const [propValidationState, setPropValidation] = useState<State | null>(null)
  const [focus, setFocus] = useState(false)
  const [states, setStates] = useState(
    updateStates(
      compile,
      description,
      requiredState,
      validationState,
      propValidationState,
      ...propStates
    )
  )
  const [state, setState] = useState(
    propState ??
      StateHelper.sumState(states.map((s) => s.state ?? STATE.DEFAULT))
  )
  const [value, setValue] = useState<
    [
      boolean | string | number | Date | FileList | [Date, Date] | undefined,
      string | Promise<string[]> | undefined,
      string | number | boolean | undefined
    ]
  >([propValue, undefined, undefined])

  const onChangeFn = useCallback(
    (
      val: string | undefined,
      formatted?: string,
      key?: string | number | boolean
    ) => {
      setValue([val, formatted, key])

      if (onChange) {
        onChange(
          val as string & Date & FileList & [Date, Date],
          formatted as string & Promise<string[]>,
          key
        )
      }
    },
    [onChange]
  )

  // Debounced to allow input calendar change focus temporarily
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeFocusFn = useCallback(
    CommonHelper.fn.debounce((focused: boolean) => {
      setFocus(focused)

      if (onChangeFocus) {
        onChangeFocus(focused)
      }
    }, 100),
    [onChangeFocus]
  )

  // Debounce prop validation
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateDebounced = useCallback(
    propValidation
      ? validateDebounce && validateDebounce > 0
        ? CommonHelper.fn.debounce(
            (setter: (s: State | null) => void, ...args: ValidationParam) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return Promise.resolve(propValidation(...(args as any))).then(
                setter
              )
            },
            validateDebounce
          )
        : (setter: (s: State | null) => void, ...args: ValidationParam) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return Promise.resolve(propValidation(...(args as any))).then(
              setter
            )
          }
      : () => null,
    [propValidation, validateDebounce]
  ) as (setter: (s: State | null) => void, ...args: ValidationParam) => void

  useUpdate(() => {
    const isRequiredMet = requireField(
      !!required,
      value[0],
      lang,
      typeof required === 'string' ? required : undefined
    )
    const isValidationMet = validation(
      !!validate,
      String(value[0]),
      lang,
      type,
      typeof validate === 'string' ? validate : undefined
    )

    setRequired(isRequiredMet)
    setValidation(isValidationMet)

    if (
      (isRequiredMet?.state === STATE.VALID || isRequiredMet === null) &&
      (isValidationMet?.state === STATE.VALID || isValidationMet === null) &&
      propValidation
    ) {
      validateDebounced(
        setPropValidation,
        value[0] as string & Date & FileList & [Date, Date],
        value[1] as string & Promise<string[]>,
        value[2] as string
      )
    }
  }, [required, value, validate, type, propValidation, revalidate])

  useUpdate(() => {
    if (propValue !== value[0]) {
      setValue([propValue, undefined, undefined])
    }
  }, [propValue])

  // Update states based on requiredState or validationState change
  useUpdate(() => {
    // Default set to true if not focused, meaning that state will be set if changes happen when input is not focused
    // set to false if currently focused
    let set = !focus
    // Compile to single state instead of multiple state
    if (validateOn === 'blur' && !focus) {
      set = true
    } else if (validateOn === 'change') {
      set = true
    } else if (validateOn === 'mixed') {
      // Set state only if current state === ERROR
      // Or if !focus
      const currentSum = StateHelper.sumState(
        states.map((s) => s.state ?? STATE.DEFAULT)
      )

      if (currentSum === STATE.ERROR || !focus) {
        set = true
      }
    }

    if (set) {
      const updatedStates = updateStates(
        compile,
        description,
        requiredState,
        validationState,
        propValidationState,
        ...propStates
      )

      setStates(updatedStates)
      setState(
        propState ??
          StateHelper.sumState(
            updatedStates.map((s) => s.state ?? STATE.DEFAULT)
          )
      )
    }
  }, [
    validateOn,
    focus,
    compile,
    description,
    requiredState,
    validationState,
    propValidationState,
    propStates,
  ])

  useUpdate(() => {
    if (onStateChange) {
      onStateChange(state)
    }
  }, [state, onStateChange])

  // Update form
  useMount(() => {
    // Do the form.update only when ID is set
    if (id) {
      // Validate the input silently
      Promise.resolve(value)
        .then(async (val) => {
          if (propValidation) {
            return propValidation(
              val[0] as string & Date & FileList & [Date, Date],
              val[1] as string & Promise<string[]>,
              val[2] as string
            )
          }

          return null
        })
        .then((st) => {
          form.update(id, {
            value,
            state:
              propState ??
              StateHelper.sumState(
                updateStates(
                  compile,
                  description,
                  requireField(
                    !!required,
                    value[0],
                    lang,
                    typeof required === 'string' ? required : undefined
                  ),
                  validation(
                    !!validate,
                    String(value[0]),
                    lang,
                    type,
                    typeof validate === 'string' ? validate : undefined
                  ),
                  st,
                  ...propStates
                ).map((s) => s.state ?? STATE.DEFAULT)
              ),
          })
        })
    }
  })

  useUpdate(() => {
    if (id) {
      form.update(id, {
        value,
        state,
      })
    }
  }, [value, state, form, id])

  return (
    <StatedPart
      {...(statedProps as Props & { type: 'text' })}
      type={type as 'text'}
      value={propValue as string}
      state={state}
      states={states}
      description={!compile && description}
      onChange={onChangeFn as (Props & { type: 'text' })['onChange']}
      onSubmit={props.onSubmit as (Props & { type: 'text' })['onSubmit']}
      onChangeFocus={onChangeFocusFn}
    />
  )
}
