/* eslint-disable prettier/prettier */
import store from 'store'
import { Defaults, ERRORS } from '../../../constants'

import { Err } from '../err'
import { Logger } from '../logger'

class StoreModel {

  public async save(key: string, value: unknown, expiry = -1) {
    Logger.log('INFO', 'Saving to local storage, key:', key)

    await store.set(`${ Defaults.STORE_KEY }/${ key }`, {
      value,
      expiry,
    })

    return value
  }

  public async load<T extends unknown>(key: string): Promise<T> {
    Logger.log('INFO', 'Loading from local storage, key:', key)
    const jsonValue = await store.get(`${ Defaults.STORE_KEY }/${ key }`)

    if (jsonValue !== undefined) {
      if (jsonValue.expiry === -1 || jsonValue.expiry > Date.now()) {
        return jsonValue.value
      } else {
        store.remove(`${ Defaults.STORE_KEY }/${ key }`)
      }
    }

    throw new Err(ERRORS.NOT_FOUND, 'Data not found in local storage')
  }

  public async clear(key?: string) {
    if (key) {
      store.remove(`${ Defaults.STORE_KEY }/${ key }`)

      return true
    } else {
      Logger.log('WARN', 'Dangerously clear all storage.')
      store.clearAll()

      return true
    }
  }
}

export const Store = new StoreModel()
