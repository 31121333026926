import { StyleSheet } from '../../../libs/com'
import { Colors } from '../../../constants'

export default StyleSheet.create({
  tooltip: {
    background: Colors.palette('grey')[0],
    width: 218,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.palette('grey')[2],
    borderRadius: 8,
    padding: 12,
    zIndex: 10,
  },

  large: {
    width: 442,
  },

  dark: {
    backgroundColor: Colors.palette('grey')[13],
    color: Colors.white,
  },

  icon: {
    marginRight: 8,
    alignSelf: 'flex-start',
    maxWidth: 24,
  },

  message: {
    flexGrow: 1,
  },
})
