import React, { useRef } from 'react'
import { Logger } from '../../libs/com'
import { StateHelper } from '../../helpers'
import { STATE } from '../../constants'

type Data = {
  value: [
    boolean | string | number | Date | FileList | [Date, Date] | undefined,
    string | Promise<string[]> | undefined,
    string | number | boolean | undefined
  ]
  state: STATE
}

function updateState(
  state: STATE,
  states: STATE[],
  callbacks: Set<(state: STATE) => void>
) {
  const finalState = StateHelper.sumState(states)

  if (state !== finalState) {
    Array.from(callbacks).forEach((c) => c(finalState))
  }

  return finalState
}

// Static context doesn't trigger rerender because it doesn't use setState inside of the implementation
// Instead we use observer pattern, because we don't want to trigger re-render on such high level context
export function FormContextFactory() {
  const context = React.createContext({
    map: new Map<string, Data>(),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: (id: string, data: Data): boolean => {
      Logger.log(
        'WARN',
        'You are calling form.update from outside of a form context.'
      )

      return false
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange: (callback: (map: Map<string, Data>) => void) => {
      Logger.log(
        'WARN',
        'You are calling form.onChange from outside of a form context.'
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onStateChange: (callback: (state: STATE) => void) => {
      Logger.log(
        'WARN',
        'You are calling form.onStateChange from outside of a form context.'
      )
    },
  })

  return {
    FormContext: context,
    useFormContext() {
      const callbacks = useRef<Set<(map: Map<string, Data>) => void>>(new Set())
      const stateChangeCallbacks = useRef<Set<(state: STATE) => void>>(
        new Set()
      )
      const state = useRef(STATE.DEFAULT)
      // const inputs = useRef<Set<string>>(new Set())
      const map = useRef(new Map<string, Data>())

      return {
        map: map.current,
        update(id: string, data: Data) {
          map.current.set(id, data)

          // Broadcast changes to listeners
          callbacks.current.forEach((c) => c(map.current))

          // Update final state and broadcast changes
          state.current = updateState(
            state.current,
            Array.from(map.current.values()).map((d) => d.state),
            stateChangeCallbacks.current
          )

          return true
        },
        onChange(callback: (map: Map<string, Data>) => void) {
          callbacks.current.add(callback)

          callback(map.current)
        },
        onStateChange(callback: (state: STATE) => void) {
          stateChangeCallbacks.current.add(callback)

          callback(state.current)
        },
      } as React.ContextType<typeof context>
    },
  }
}
