import React from 'react'
import { Colors } from '../../../constants'
import { StyleAttribute, NotificationConfig, Lang } from '../../../types'

import { Box, Shadow, Text } from '../../blocks'

import Styles from './styles'

const foreground = (type: NotificationConfig['type']) => {
  switch (type) {
    case 'ERROR':
      return Colors.palette('red')[2]
    case 'WARNING':
      return Colors.palette('yellow')[2]
    case 'SUCCESS':
      return Colors.success
    case 'DARK':
      return Colors.black

    case 'INFO':
    default:
      return Colors.palette('blue')[2]
  }
}

const cta = (lang?: Lang) => {
  switch (lang) {
    case 'en':
      return 'CLOSE'
    case 'id':
    default:
      return 'TUTUP'
  }
}

export const NotifierPart = (
  props: Readonly<NotificationConfig> & {
    readonly lang?: Lang
    readonly isMobile: boolean
    readonly onClose: () => void
    readonly style: StyleAttribute
  }
) => {
  return (
    <Shadow
      animated
      y={2}
      blur={2}
      onPress={props.onPress}
      style={[Styles.notifier, props.isMobile && Styles.mobile, props.style]}
    >
      <Box
        row
        centering="v"
        style={[Styles.content, { backgroundColor: foreground(props.type) }]}
      >
        <Box flex style={Styles.message}>
          <Text size="small" color={Colors.white}>
            {props.message}
          </Text>
        </Box>
        <Text size="heading-6" color={Colors.white} onPress={props.onClose}>
          {cta(props.lang)}
        </Text>
      </Box>
    </Shadow>
  )
}
