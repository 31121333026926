import { StyleSheet } from '../../../../libs/com'
import { Colors, Sizes } from '../../../../constants'

export default StyleSheet.create({
  paging: {
    margin: [0, -Sizes.margin.compact],
  },

  box: {
    height: 40,
    padding: [0, Sizes.margin.default],
    backgroundColor: Colors.palette('grey')[0],
  },

  bordered: {
    borderWidth: 1,
    borderColor: Colors.palette('grey')[1],
    borderRadius: 4,
  },

  icon: {
    margin: [0, -Sizes.margin.half],
  },

  noLeftBorder: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    // borderLeftWidth: 0,
  },

  noRightBorder: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },

  active: {
    backgroundColor: Colors.white,
  },

  disabled: {
    cursor: 'not-allowed',
  },

  padder: {
    margin: [0, Sizes.margin.compact],
  },
})
