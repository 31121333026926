import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: Sizes.app.percent(100, 'w'),
    // We want this to be defeated only by notification
    zIndex: 998,
    overflow: 'hidden',
  },

  transitioner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  overlay: {
    backgroundColor: Colors.opacity(Colors.black, 0.5),
  },

  prompt: {
    minWidth: 560,
    width: Sizes.app.percent(20, 'w'),
    backgroundColor: Colors.white,
    borderRadius: 8,
    padding: Sizes.margin.thick,
  },

  closer: {
    alignItems: 'flex-end',
    marginBottom: Sizes.margin.compact,
  },

  icon: {
    alignSelf: 'flex-start',
  },

  padder: {
    marginBottom: Sizes.margin.medium,
  },

  content: {
    marginBottom: Sizes.margin.thick,
  },

  action: {
    marginRight: Sizes.margin.compact,
    paddingLeft: Sizes.margin.medium,
    paddingRight: Sizes.margin.medium,
  },

  iconWrapper: {
    width: '60px',
    height: '60px',
    marginBottom: Sizes.margin.default,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.palette('grey')[18],
  },
})
