import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA, StyleSheet } from '../../../libs/com'
import { CommonHelper } from '../../../helpers'
import { Colors } from '../../../constants'
import { InputStyles } from '../../../styles'
import {
  Autocomplete,
  NestedOption,
  Option,
  StyleAttribute,
} from '../../../types'

import { Box } from '../box'
import { Icon } from '../icon'
import { Loader } from '../loader'
import { ScrollView } from '../scroll.view'

import { SuggestionPart } from './_suggestion'
import { TitlePart } from './_title'

import Styles from './styles'

type Type = 'password' | 'text' | 'tel' | 'email'

function createTraversalIndex(
  options: (Option | NestedOption)[],
  indexes: number[] = [],
  result: string[] = []
): string[] {
  return result.concat(
    ...options.map((option, i) => {
      if ((option as NestedOption).options) {
        return createTraversalIndex(
          (option as NestedOption).options,
          indexes.concat(i),
          result
        )
      } else {
        return indexes.concat(i).join('.')
      }
    })
  )
}

function getValueAtIndex(
  options: (Option | NestedOption)[],
  traversalKey: string
): Option {
  const keys = traversalKey.split('.')
  const value = options[parseInt(keys.shift() as string, 10)]

  return (value as NestedOption).options
    ? getValueAtIndex((value as NestedOption).options, keys.join('.'))
    : value
}

export function InputText({
  onChange: propsOnChange,
  onChangeFocus: propsOnChangeFocus,
  onBackspace: propsOnBackspace,
  onSubmit: propsOnSubmit,
  ...props
}: {
  readonly testId?: string
  readonly fit?: boolean
  readonly type?: Type
  readonly placeholder?: string
  readonly autofocus?: boolean
  readonly autocomplete?:
    | Autocomplete
    | ((value: string) => Promise<(Option | NestedOption)[]>)
  readonly debounce?: number
  readonly autocorrect?: boolean
  readonly autocapitalize?: boolean
  readonly defaultValue?: string | number
  readonly value?: string | number
  readonly valid?: boolean
  readonly clearable?: boolean
  readonly disabled?: boolean
  readonly readonly?: boolean
  readonly spellcheck?: boolean
  readonly minlength?: number
  readonly maxlength?: number
  readonly tabindex?: number
  readonly prefix?: React.ReactNode
  readonly suffix?: React.ReactNode
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onChange?: (value: string, key?: string | number | boolean) => void
  readonly onBackspace?: (value: string) => void
  readonly onSubmit?: (value: string) => void
  readonly input?: StyleAttribute
  readonly style?: StyleAttribute
  readonly asterisk?: boolean
}): ReactElement {
  const fit = props.fit ?? false
  const placeholder = props.placeholder ?? 'Input here…'
  const debounce = props.debounce ?? 300
  const isFunction =
    props.autocomplete && typeof props.autocomplete === 'function'
  const input = useRef<HTMLInputElement>(null)
  const cursor = useRef<number | null>(null)
  const shouldSendTracker = useRef(true)
  const [traversalIndex, setTraversalIndex] = useState<string[]>([])
  const [suggestions, setSuggestions] = useState<
    (Option | NestedOption)[] | null
  >(null)
  const [focusIndex, setFocusIndex] = useState('')
  const [hovering, setHover] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shouldScrollIntoView, setScrollIntoView] = useState(false)
  const [hasValue, setHasValue] = useState(
    !!(props.defaultValue ?? props.value)
  )
  const [masked, setMasked] = useState(props.type === 'password' ? true : false)
  const [focused, setFocused] = useState(false)

  useLayoutEffect(() => {
    if (props.autofocus && input.current) {
      input.current.focus()
    }
  }, [props.autofocus])

  const onPress = useCallback(() => {
    input.current?.focus()
  }, [])

  const onClear = useCallback(() => {
    if (input.current) {
      input.current.value = ''

      setHasValue(false)

      if (propsOnChange) {
        propsOnChange('')
      }
    }
  }, [propsOnChange])

  const onFocus = useCallback(() => {
    setFocused(true)

    if (propsOnChangeFocus) {
      propsOnChangeFocus(true)
    }
  }, [propsOnChangeFocus])

  const onBlur = useCallback(() => {
    setFocused(false)
    shouldSendTracker.current = true

    if (propsOnChangeFocus) {
      propsOnChangeFocus(false)
    }
  }, [propsOnChangeFocus])

  const onSelectSuggestion = useCallback(
    (opt: Option) => {
      const value = String(opt.value)

      if (input.current) {
        input.current.value = value
      }

      if (hovering) {
        setHover(false)
      }

      setSuggestions(null)
      setFocusIndex('')

      setImmediate(() => {
        if (input.current) {
          input.current.blur()
        }
      })

      if (propsOnChange) {
        propsOnChange(value, opt.key)
      }
    },
    [propsOnChange, hovering]
  )

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (propsOnBackspace && (e.key === 'Delete' || e.key === 'Backspace')) {
        propsOnBackspace(e.currentTarget.value)
      }

      const index = traversalIndex.indexOf(focusIndex)

      if (isFunction && suggestions?.length) {
        if (e.key === 'ArrowUp') {
          // TODO: rotation if reach end of list?
          if (index <= 0) {
            setFocusIndex(traversalIndex[traversalIndex.length - 1])
          } else {
            setFocusIndex(traversalIndex[index - 1])
          }
          setScrollIntoView(true)
        } else if (e.key === 'ArrowDown') {
          if (index >= traversalIndex.length - 1) {
            setFocusIndex(traversalIndex[0])
          } else {
            setFocusIndex(traversalIndex[index + 1])
          }
          setScrollIntoView(true)
        } else if (e.key === 'Enter') {
          onSelectSuggestion(getValueAtIndex(suggestions, focusIndex))
        }
      } else if (propsOnSubmit && e.key === 'Enter') {
        propsOnSubmit(e.currentTarget.value)
      }
    },
    [
      propsOnBackspace,
      propsOnSubmit,
      suggestions,
      focusIndex,
      onSelectSuggestion,
      isFunction,
      traversalIndex,
    ]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLookUp = useCallback(
    CommonHelper.fn.debounce(
      (val: string) => {
        setLoading(true)
        ;(props.autocomplete as (value: string) => Promise<Option[]>)(val)
          .then((sugg) => {
            // Make sure the value is still the same
            if (val === input.current?.value) {
              setLoading(false)
              setFocusIndex('')
              setSuggestions(sugg)
              setTraversalIndex(createTraversalIndex(sugg))
            }
          })
          .catch(() => {
            if (val === input.current?.value) {
              setLoading(false)
              setFocusIndex('')
              setSuggestions([])
            }
          })
      },
      debounce,
      {
        leading: false,
        trailing: true,
      }
    ),
    [debounce, props.autocomplete]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value

      setHasValue(!!val)

      // Send analytics to GA
      if (props.tracker && shouldSendTracker.current) {
        shouldSendTracker.current = false
        GA.sendEvent('fill_input', {
          input_name: props.tracker,
          ...(props.trackerParam ?? {}),
        })
      }

      if (propsOnChange) {
        propsOnChange(val)
      }

      if (isFunction) {
        onLookUp(val)
      }
    },
    [propsOnChange, isFunction, onLookUp, props.tracker, props.trackerParam]
  )

  const onToggleMask = useCallback(() => {
    setMasked(!masked)
  }, [masked])

  useLayoutEffect(() => {
    if (input.current && cursor.current !== null && props.type === 'text') {
      input.current.selectionStart = cursor.current
      input.current.selectionEnd = cursor.current
    }
  })

  const ti = traversalIndex.slice()

  return (
    <Box
      row
      centering
      onPress={onPress}
      accessible={!props.disabled}
      style={[
        InputStyles.container,
        props.disabled
          ? InputStyles['container-disabled']
          : props.readonly
          ? InputStyles['container-readonly']
          : undefined,
        props.valid && InputStyles['container-valid'],
        props.valid === false && InputStyles['container-invalid'],
        focused && InputStyles['container-focused'],
        fit && InputStyles.fit,
        props.style,
      ]}
    >
      {props.prefix}
      <input
        data-testid={props.testId}
        ref={input}
        placeholder={placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        autoFocus={props.autofocus}
        autoComplete={
          typeof props.autocomplete === 'string'
            ? props.autocomplete
            : undefined
        }
        autoCapitalize={props.autocapitalize ? 'on' : 'off'}
        autoCorrect={props.autocorrect ? 'on' : 'off'}
        spellCheck={props.spellcheck ? 'true' : 'false'}
        minLength={props.minlength}
        maxLength={props.maxlength}
        disabled={props.disabled}
        readOnly={props.readonly}
        tabIndex={props.tabindex}
        type={props.type === 'password' && !masked ? 'text' : props.type}
        onFocus={onFocus}
        onBlur={onBlur}
        {...StyleSheet.classNameAndStyle([
          InputStyles.input,
          props.disabled
            ? InputStyles['input-disabled']
            : props.readonly
            ? InputStyles['input-readonly']
            : undefined,
          props.valid && InputStyles['input-valid'],
          props.valid === false && InputStyles['input-invalid'],
          focused && InputStyles['input-focused'],
          props.input,
        ])}
      />
      {isFunction && loading && (
        <Box centering style={Styles.clearer}>
          <Loader color={Colors.border} />
        </Box>
      )}
      {props.suffix ??
        (props.type === 'password' ? (
          <Box centering style={Styles.clearer} onPress={onToggleMask}>
            <Icon name={masked ? 'visible' : 'visible.hidden'} />
          </Box>
        ) : (
          hasValue &&
          !props.readonly &&
          !props.disabled &&
          props.clearable && (
            <Box centering style={Styles.clearer} onPress={onClear}>
              <Icon name="decline" size={12} color={Colors.border} />
            </Box>
          )
        ))}
      {isFunction && (focused || hovering) && !loading && suggestions && (
        <ScrollView onHover={setHover} style={Styles.suggestions}>
          {suggestions.length ? (
            suggestions.map((suggestion, i) => {
              const k = (suggestion as NestedOption).options ? null : ti.shift()
              return (suggestion as NestedOption).options ? (
                <Box key={i}>
                  <TitlePart title={String(suggestion.value)} />
                  <Box style={Styles.group}>
                    {(suggestion as NestedOption).options.map((s) => {
                      const key = ti.shift() as string
                      return (
                        <SuggestionPart
                          key={key}
                          shouldScrollIntoView={shouldScrollIntoView}
                          focused={focusIndex === key}
                          onHover={(f) => {
                            if (f) {
                              setFocusIndex(key)
                              setScrollIntoView(false)
                            }
                          }}
                          value={s}
                          onPress={onSelectSuggestion}
                        />
                      )
                    })}
                  </Box>
                </Box>
              ) : (
                <SuggestionPart
                  key={i}
                  shouldScrollIntoView={shouldScrollIntoView}
                  focused={focusIndex === k}
                  onHover={(f) => {
                    if (f) {
                      setFocusIndex(k as string)
                      setScrollIntoView(false)
                    }
                  }}
                  value={suggestion}
                  onPress={onSelectSuggestion}
                />
              )
            })
          ) : (
            <SuggestionPart
              key="none"
              value={{
                value: 'Data tidak ditemukan',
              }}
              selectable={false}
            />
          )}
        </ScrollView>
      )}
    </Box>
  )
}
