import React from 'react'
import { StyleSheet } from '../../../libs/com'
import { Colors } from '../../../constants'
import { StyleAttribute } from '../../../types'

import Styles from './styles'

export const Loader = React.memo(function loader(props: {
  readonly color?: string
  readonly size?: number
  readonly duration?: number
  readonly style?: StyleAttribute
}) {
  const size = props.size ?? 24
  const color = props.color ?? Colors.black
  const duration = (props.duration ?? 2500) / 1000

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...StyleSheet.classNameAndStyle(props.style)}
    >
      <circle
        cx="0"
        cy="50"
        r="10"
        fill={color}
        className={Styles.anim}
        style={{
          animationDelay: '0s',
          animationDuration: `${duration}s`,
        }}
      />
      <circle
        cx="0"
        cy="50"
        r="10"
        fill={color}
        className={Styles.anim}
        style={{
          animationDelay: `${-duration / 4}s`,
          animationDuration: `${duration}s`,
        }}
      />
      <circle
        cx="0"
        cy="50"
        r="10"
        fill={color}
        className={Styles.anim}
        style={{
          animationDelay: `${-duration / 2}s`,
          animationDuration: `${duration}s`,
        }}
      />
      <circle
        cx="0"
        cy="50"
        r="10"
        fill={color}
        className={Styles.anim}
        style={{
          animationDelay: `${(-duration * 3) / 4}s`,
          animationDuration: `${duration}s`,
        }}
      />
    </svg>
  )
})
