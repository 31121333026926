import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
    flex: 1,
  },

  cover: {
    objectFit: 'cover',
  },

  contain: {
    objectFit: 'contain',
  },

  fill: {
    objectFit: 'fill',
  },

  none: {
    objectFit: 'none',
  },

  rounded: {
    borderRadius: 16,
  },

  broken: {
    maxWidth: 32,
    maxHeight: 35,
    width: '50%',
  },
})
