import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.half,
    paddingRight: Sizes.margin.half,
    borderWidth: 1,
    borderColor: Colors.palette('blue')[2],
    backgroundColor: Colors.transparent,
  },
  normal: {
    foreground: Colors.palette('blue')[2],
  },
  active: {
    foreground: Colors.palette('blue')[3],
  },
  disabled: {
    foreground: Colors.border,
  },
  loading: {
    foreground: Colors.palette('blue')[3],
  },
}
