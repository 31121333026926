import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  disableScroll: {
    overflow: 'hidden',
  },

  content: {
    flexGrow: 1,
  },

  horizontal: {
    height: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },

  scrollAllDirection: {
    overflowY: 'auto',
    overflowX: 'auto',
  },
})
