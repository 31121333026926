import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.half,
    paddingRight: Sizes.margin.half,
    borderWidth: 1,
    borderColor: Colors.palette('grey')[1],
  },
  normal: {
    foreground: Colors.black,
  },
  active: {
    foreground: Colors.black,
    style: {
      borderColor: Colors.palette('grey')[3],
    },
  },
  disabled: {
    foreground: Colors.palette('grey')[2],
  },
  loading: {
    foreground: Colors.black,
    style: {
      borderColor: Colors.palette('grey')[3],
    },
  },
}
