import capitalize from 'lodash/capitalize'
import snakeCase from 'lodash/snakeCase'

export const StringHelper = {
  safeToString(val?: boolean | string | number | null): string {
    return val || val === 0 ? String(val) : ''
  },

  stripTag(string: string) {
    const result = string.replace(/(<([^>]+)>)/gi, '')
    const div = document.createElement('div')
    div.innerHTML = result
    return div.textContent || div.innerText
  },

  readableStatus(status: string) {
    return status
      .split('_')
      .map((t) => StringHelper.capitalize(t))
      .join(' ')
  },

  trim(string: string, length: number) {
    return string.length > length ? `${string.substr(0, length - 1)}…` : string
  },

  snakeCase,

  capitalize,
}
