import React, { useCallback, useState, useRef, ReactNode } from 'react'
import { CommonHelper } from '../../../helpers'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Button } from '../button'
import { Shadow } from '../shadow'
import { OptionPart } from './_option'

import Styles from './styles'

type ButtonProps = React.ComponentProps<typeof Button>

type BoxProps = React.ComponentProps<typeof Box>

type ButtonType = {
  readonly type?: 'button'
} & Pick<
  ButtonProps,
  | 'theme'
  | 'size'
  | 'ellipsis'
  | 'reverse'
  | 'icon'
  | 'iconRight'
  | 'weight'
  | 'align'
  | 'loading'
  | 'disabled'
  | 'active'
  | 'children'
>

type NodeType = {
  readonly type: 'node'
  readonly children: ReactNode | ((active: boolean) => ReactNode)
} & Pick<
  BoxProps,
  | 'centering'
  | 'element'
  | 'innerHTML'
  | 'row'
  | 'reverse'
  | 'spread'
  | 'feedback'
>

export const Menu = ({
  options,
  position = 'default',
  type = 'button',
  layout = 'default',
  tabindex,
  style,
  block,
  onPress,
  ...props
}: (ButtonType | NodeType) & {
  readonly flex?: boolean | number
  readonly feedback?: boolean
  readonly layout?: React.ComponentProps<typeof OptionPart>['layout']
  readonly block?: boolean
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly tabindex?: number
  readonly options: Omit<React.ComponentProps<typeof OptionPart>, 'layout'>[]
  readonly style?: StyleAttribute
  readonly buttonStyle?: StyleAttribute
  readonly menuStyle?: StyleAttribute
  readonly testId?: string
  readonly position?: 'default' | 'bottom-right' | 'bottom-left'
  readonly onPress?: () => void
  readonly onBlur?: () => void
}) => {
  const div = useRef<HTMLDivElement>(null)
  const [onTopHalfOfTheScreen, setOnTopHalfOfTheScreen] = useState(true)
  const [onLeftHalfOfTheScreen, setOnLeftHalfOfTheScreen] = useState(true)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [visible, setVisibility] = useState(false)

  const onFocus = useCallback(() => {
    if (position === 'bottom-right') {
      setOnTopHalfOfTheScreen(true)
      setOnLeftHalfOfTheScreen(true)
    } else if (position === 'bottom-left') {
      setOnTopHalfOfTheScreen(true)
      setOnLeftHalfOfTheScreen(false)
    } else {
      const rect = div.current?.getBoundingClientRect()
      const isOnTop = rect && rect.top < window.innerHeight / 2
      const isOnLeft = rect && rect.left < window.innerWidth / 2

      if (onTopHalfOfTheScreen !== !!isOnTop) {
        setOnTopHalfOfTheScreen(!!isOnTop)
      }

      if (onLeftHalfOfTheScreen !== !!isOnLeft) {
        setOnLeftHalfOfTheScreen(!!isOnLeft)
      }
    }
    setVisibility(true)
    onPress?.()
  }, [onLeftHalfOfTheScreen, onPress, onTopHalfOfTheScreen, position])

  const onBlur = useCallback(() => {
    setVisibility(false)
    props.onBlur?.()
  }, [props])

  return (
    <Box row flex={props.flex} style={[Styles.container, style]}>
      <Box
        testId={props.testId}
        flex={props.flex}
        ref={div}
        tabindex={tabindex ?? 1}
        tracker={props.tracker}
        trackerParam={props.trackerParam}
        feedback={props.feedback}
        onPress={CommonHelper.fn.NOOP}
        onFocus={onFocus}
        onBlur={onBlur}
        style={Styles.clicker}
      >
        {type === 'button' ? (
          <Button
            {...(props as ButtonType)}
            active={visible}
            style={[Styles.button, props.buttonStyle]}
          />
        ) : type === 'node' ? (
          <Box
            {...(props as NodeType)}
            accessible={false}
            children={
              typeof props.children === 'function'
                ? (props.children as (active: boolean) => ReactNode)(visible)
                : props.children
            }
          />
        ) : (
          false
        )}
        {(visible || hoverIndex !== -1) && (
          <Shadow
            x={1}
            y={1}
            blur={5}
            style={[
              Styles.navigation,
              layout === 'big' && Styles.big,
              onTopHalfOfTheScreen ? Styles.top : Styles.bottom,
              onLeftHalfOfTheScreen ? Styles.left : Styles.right,
              block && Styles.block,
              props.menuStyle,
            ]}
          >
            {options.map((option, i) => (
              <OptionPart
                key={i}
                {...option}
                layout={layout}
                borderless={option.borderless ?? i === 0}
                onHover={(hovering) => {
                  if (hovering) {
                    setHoverIndex(i)
                  } else if (hoverIndex === i) {
                    setHoverIndex(-1)
                  }
                  if (option.onHover) option.onHover(hovering)
                }}
                onPress={() => {
                  setHoverIndex(-1)
                  div.current?.blur()
                  if (option.onPress) option.onPress()
                }}
              />
            ))}
          </Shadow>
        )}
      </Box>
    </Box>
  )
}
