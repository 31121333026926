import { StyleSheet } from '../../../../libs/com'
import { Colors, Sizes } from '../../../../constants'

export default StyleSheet.create({
  container: {
    width: 320,
    backgroundColor: Colors.white,
    padding: Sizes.margin.compact,
  },

  header: {
    paddingTop: Sizes.margin.default,
    paddingBottom: Sizes.margin.default,
  },

  month: {
    flex: 2,
    marginRight: Sizes.margin.compact,
  },

  year: {
    flex: 1,
  },

  content: {
    paddingBottom: Sizes.margin.thick,
  },

  row: {
    marginTop: Sizes.margin.default,
  },

  box: {
    height: 40,
    margin: 1,
  },

  boxActive: {
    borderRadius: 2,
    backgroundColor: Colors.primary,
  },

  empty: {
    background: Colors.palette('grey')[1],
    borderRadius: 2,
  },

  unselectable: {
    background: Colors.palette('grey')[0],
  },

  text: {
    color: Colors.palette('grey')[4],
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
  },

  weekend: {
    color: Colors.palette('grey')[3],
  },

  textActive: {
    color: Colors.white,
  },

  button: {
    marginRight: Sizes.margin.compact,
  },
})
