import React, { useCallback } from 'react'
import { Colors } from '../../../constants'
import { AlertConfig } from '../../../types'

import { Box, Icon, Padder, Text } from '../../blocks'
import { ButtonBossaPart } from './_button-bossa'

import Styles from './styles'

export const PromptBossaPart = ({
  onCloseModal,
  onClose: propsOnClose,
  ...props
}: Readonly<AlertConfig> & {
  readonly accessible: boolean
  readonly onCloseModal: () => void
}) => {
  const closable = props.closable ?? true

  const onClose = useCallback(() => {
    onCloseModal()

    if (propsOnClose) {
      propsOnClose()
    }
  }, [onCloseModal, propsOnClose])

  return (
    <Box style={[Styles.prompt, props.style]}>
      {closable ? (
        <Box style={Styles.closer}>
          <Icon
            name="decline"
            onPress={onClose}
            color={Colors.palette('grey')[3]}
          />
        </Box>
      ) : (
        <Box style={Styles.closer}>
          <Padder pad height={24} />
        </Box>
      )}
      {props.icon ? (
        <Box row style={Styles.iconWrapper}>
          <Icon
            name={props.icon.name}
            size={30}
            color={props.icon.color ?? Colors.error}
          />
        </Box>
      ) : (
        false
      )}
      <Text size="heading-2" style={Styles.padder}>
        {props.title}
      </Text>
      <Box style={Styles.content}>
        {typeof props.message === 'string' ? (
          <Text size="regular">{props.message}</Text>
        ) : (
          props.message
        )}
      </Box>
      <Box row centering="v" spread="flex-end">
        {props.actions.map((action, i) => {
          return (
            <ButtonBossaPart
              key={i}
              type={action.type}
              title={action.title}
              onPress={() => {
                if (action.onPress) {
                  action.onPress()
                }

                onCloseModal()
              }}
              style={Styles.action}
            />
          )
        })}
      </Box>
    </Box>
  )
}
