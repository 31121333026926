import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  icon: {
    height: 24,
    width: 24,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.border,
    transition: '.3s color, .3s border, .3s opacity',
  },

  focused: {
    borderColor: Colors.primary,
  },

  label: {
    paddingLeft: Sizes.margin.compact,
  },

  primary: {},

  'primary-active': {},

  secondary: {},

  'secondary-active': {
    borderColor: Colors.palette('blue')[3],
    background: Colors.palette('blue')[2],
  },

  blue: {},

  'blue-active': {
    borderColor: Colors.palette('blue')[10],
    background: Colors.palette('blue')[10],
  },

  readonly: {
    borderColor: Colors.palette('grey')[2],
    background: Colors.palette('grey')[7],
  },

  checkboxDisabled: {
    position: 'absolute',
    backgroundColor: Colors.white,
    width: '50%',
    height: 2,
  },
})
