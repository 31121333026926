/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import React, { ReactNode } from 'react'
import { CommonHelper } from '../../../helpers'
import { Defaults } from '../../../constants'
import { Lang } from '../../../types'

type Keys = 'b' | 'i' | 'em' | 'br'

const defaultTagSubtitution: Record<
  Keys,
  (child?: string | ReactNode[], key?: number | string) => ReactNode
> = {
  b: (str, key) => <b key={key}>{str}</b>,
  i: (str, key) => <i key={key}>{str}</i>,
  em: (str, key) => <em key={key}>{str}</em>,
  br: () => '\n',
}

class LangResolverModel {
  // Process the string to break it into ReactNode[]
  // Because we want to replace the {{ KEY }} with ReactNode,
  // We cannot just use str.replaceAll
  // Any other idea how we might achieve this?
  private replaceWithNode(
    str: string,
    key: string,
    replacer: ReactNode
  ): ReactNode[] {
    const newStr = str.split(key).reduce((sum, s) => {
      return sum.concat(s).concat(replacer)
    }, [] as (string | ReactNode)[])

    newStr.pop()
    // Remove last

    return newStr
  }

  // Process if string
  // Receives ReactNode / ReactNode[]
  // Returns processed ReactNode / ReactNode[] 1 layer deep
  private processNodeIfString(
    node: ReactNode | ReactNode[],
    processor: (str: string) => ReactNode | ReactNode[]
  ): ReactNode | ReactNode[] {
    if (typeof node === 'string') {
      return processor(node)
    } else if (Array.isArray(node)) {
      // Try to join if it returns string[]
      return node.reduce((sum: ReactNode[], s) => {
        return sum.concat(this.processNodeIfString(s, processor))
      }, [])
    } else {
      return node
    }
  }

  // Process string into a node
  private processString(
    str: string,
    key: string,
    replacer: ReactNode
  ): string | ReactNode[] {
    const _key = `{{ ${key} }}`

    if (typeof replacer === 'string' || typeof replacer === 'number') {
      return str.replace(new RegExp(_key, 'g'), String(replacer))
    } else {
      // Replacer is not a string
      // Break the string into a ReactChildArray if key is found
      if (str.indexOf(_key) > -1) {
        // Key is found, time to break
        return this.replaceWithNode(str, _key, replacer)
      } else {
        // Key is not found
        return str
      }
    }
  }

  // Exchange html node into react node recursively
  private buildNode(
    nodes: NodeListOf<ChildNode> | undefined,
    tagSubtitution: Record<
      Keys,
      (child?: string | ReactNode[], key?: string | number) => ReactNode
    >,
    key?: { current: number }
  ): string | ReactNode[] {
    if (nodes) {
      const _key = key ?? { current: 1 }
      return Array.from(nodes).reduce((sum, node) => {
        switch (node.nodeName) {
          case '#text':
          default:
            return sum.concat(node.nodeValue ?? '')
          case 'b':
          case 'br':
          case 'em':
          case 'i':
            return node.hasChildNodes()
              ? sum.concat(
                  tagSubtitution[node.nodeName](
                    this.buildNode(node.childNodes, tagSubtitution, _key),
                    _key.current++
                  )
                )
              : sum.concat(
                  tagSubtitution[node.nodeName](
                    node.nodeValue ?? undefined,
                    _key.current++
                  )
                )
        }
      }, [] as ReactNode[])
    } else {
      return ''
    }
  }

  private exchangeTag(
    str: string,
    tagSubtitution?: Partial<
      Record<
        Keys,
        (child?: string | ReactNode[], key?: string | number) => ReactNode
      >
    >
  ): ReactNode | ReactNode[] {
    if (str.match(/(<([^>]+)>)/gi)) {
      // String have tag, parse them
      const parser = new DOMParser()
      const nodes = parser.parseFromString(`<div>${str}</div>`, 'text/xml')
        .firstChild?.childNodes

      return this.buildNode(nodes, {
        ...defaultTagSubtitution,
        ...(tagSubtitution ?? {}),
      })
    } else {
      return str
    }
  }

  private exchangeVariables(
    pack: Record<string, string>,
    key: string,
    variables?: Record<string, ReactNode>
  ): ReactNode | ReactNode[] {
    const string = pack[key]

    if (string) {
      // Found
      if (variables) {
        const ks = Object.keys(variables)

        return ks.reduce((str: ReactNode | ReactNode[], k: string) => {
          return this.processNodeIfString(str, (s) =>
            this.processString(s, k, variables[k])
          )
        }, string)
      } else {
        return string
      }
    } else {
      return ''
    }
  }

  // Resolve language pack using lazy import() => file
  public resolve = CommonHelper.fn.memoize(
    async (
      folder: Lang,
      name: string
    ): Promise<Record<string, string> | null> => {
      return Defaults.LANG_RESOLVER(`${folder}/${name}`).then((res) => {
        return res?.default ?? null
      })
    },
    (folder, name) => {
      return `${folder}|${name}`
    }
  )

  // Exchange a pack for a key, to get the specific string
  public exchange(
    pack: Record<string, string>,
    key: string,
    variables?: Record<string, ReactNode>,
    tagSubtitution?: Partial<
      Record<
        Keys,
        (child?: string | ReactNode[], key?: string | number) => ReactNode
      >
    >
  ) {
    // Exchange variables first
    const result = this.exchangeVariables(pack, key, variables)

    // Exchange html tag
    return this.processNodeIfString(result, (s) =>
      this.exchangeTag(s, tagSubtitution)
    )
  }
}

export const LangResolver = new LangResolverModel()
