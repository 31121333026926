import React, { useRef, useState } from 'react'
import { HtmlPortalNode } from 'react-reverse-portal'
import { Logger } from '../../libs/com'
import { Rect } from '../../types'

type Offset = {
  left?: number
  right?: number
}

type Sticky = {
  el: HTMLDivElement
  portal: HtmlPortalNode
  bottom: boolean
  offset?: Offset
  isVisible: (isIntersecting: boolean) => boolean
}

const _rect: Rect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

function updateRect(rect: Rect, update: Rect) {
  rect.x = update.x
  rect.y = update.y
  rect.width = update.width
  rect.height = update.height

  return rect
}

// Static context doesn't trigger rerender because it doesn't use setState inside of the implementation
// Instead we use observer pattern, because we don't want to trigger re-render on such high level context
export function PageStaticContextFactory() {
  const context = React.createContext({
    layout: {
      rect: _rect,
      header: _rect,
      footer: _rect,
      onChange(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        callback: (layout: { rect: Rect; header: Rect; footer: Rect }) => void
      ) {
        Logger.log(
          'WARN',
          'You are calling page.layout.onChange from outside of a page context.'
        )
      },
    },
    stickies: {
      root: null as HTMLDivElement | null,
      stickies: new Map<number, Sticky>(),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      set(id: number, data: Sticky) {
        Logger.log(
          'WARN',
          'You are calling page.stickies.set from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange(callback: (stickies: Map<number, Sticky>) => void) {
        Logger.log(
          'WARN',
          'You are calling page.stickies.onChange from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setRoot(root: HTMLDivElement | null) {
        Logger.log(
          'WARN',
          'You are calling page.stickies.setRoot from outside of a page context.'
        )
      },
    },
  })

  return {
    PageStaticContext: context,
    usePageStaticContext() {
      const layout = useRef({
        rect: { ..._rect },
        header: { ..._rect },
        footer: { ..._rect },
      })
      const onRectChangeCallbacks = useRef<
        Set<(rect: typeof layout.current) => void>
      >(new Set())

      const [root, setRoot] = useState<HTMLDivElement | null>(null)
      const stickies = useRef(new Map<number, Sticky>())
      const onStickiesChangeCallbacks = useRef<
        Set<(stickies: Map<number, Sticky>) => void>
      >(new Set())

      return {
        layout: {
          get rect() {
            return layout.current.rect
          },
          set rect(r: Rect) {
            // Update
            updateRect(layout.current.rect, r)

            // Broadcast changes
            Array.from(
              onRectChangeCallbacks.current.values()
            ).forEach((callbacks) => callbacks(layout.current))
          },
          get header() {
            return layout.current.header
          },
          set header(r: Rect) {
            // Update
            updateRect(layout.current.header, r)

            // Broadcast changes
            Array.from(
              onRectChangeCallbacks.current.values()
            ).forEach((callbacks) => callbacks(layout.current))
          },
          get footer() {
            return layout.current.footer
          },
          set footer(r: Rect) {
            // Update
            updateRect(layout.current.footer, r)

            // Broadcast changes
            Array.from(
              onRectChangeCallbacks.current.values()
            ).forEach((callbacks) => callbacks(layout.current))
          },
          onChange(
            callback: (layout: {
              rect: Rect
              header: Rect
              footer: Rect
            }) => void
          ) {
            // Register callback
            onRectChangeCallbacks.current.add(callback)

            // Call the callback
            callback(layout.current)
          },
        },
        stickies: {
          root,
          setRoot,
          stickies: stickies.current,
          set(id: number, data: Sticky) {
            // Update
            stickies.current.set(id, data)

            // Broadcast changes
            Array.from(
              onStickiesChangeCallbacks.current.values()
            ).forEach((callbacks) => callbacks(stickies.current))
          },
          onChange(callback: (stickies: Map<number, Sticky>) => void) {
            // Register callback
            onStickiesChangeCallbacks.current.add(callback)

            // Call the callback
            callback(stickies.current)
          },
        },
      } as React.ContextType<typeof context>
    },
  }
}
