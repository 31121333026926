import React, { ComponentProps } from 'react'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Text } from '../text'

import Styles from './styles'

export const ContentPart = (props: {
  width: number
  content?: string
  children?: React.ReactNode
  checkbox?: boolean
  onPress?: () => void
  onHover?: (hovering: boolean) => void
  style?: StyleAttribute
  textStyle?: StyleAttribute
  fontSize?: ComponentProps<typeof Text>['size']
}) => {
  const width = props.width * 100 + '%'

  return (
    <Box
      flex
      onHover={props.onHover}
      onPress={props.onPress}
      style={[Styles.content, { flexBasis: width }, props.style]}
    >
      {props.children ??
        (props.content ? (
          <Text size={props.fontSize ?? 'regular'} style={props.textStyle}>
            {props.content}
          </Text>
        ) : (
          false
        ))}
    </Box>
  )
}
