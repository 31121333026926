import { StyleSheet } from '../../../libs/com'
import { Colors } from '../../../constants'

export default StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // width: Sizes.app.percent(100, 'w'),
    // We want this to be the least top
    zIndex: 899,
    overflow: 'hidden',
  },

  transitioner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  overlay: {
    backgroundColor: Colors.opacity(Colors.black, 0.5),
  },
})
