import React, { useCallback, useState } from 'react'
import { CommonHelper } from '../../../../helpers'
import { Option, StyleAttribute } from '../../../../types'

import { Box } from '../../box'
import { Button } from '../../button'
import { Select } from '../../select'

import Styles from './styles'

const hoursOptions: Option[] = Array(24)
  .fill(undefined)
  .map((m, i) => {
    return {
      key: i,
      value: i,
    }
  })
const minutesOptions: Option[] = Array(60)
  .fill(undefined)
  .map((m, i) => {
    return {
      key: i,
      value: i,
    }
  })

export const TimePart = (props: {
  readonly date?: Date
  readonly onChange?: (date: Date) => void
  readonly onClose?: () => void
  readonly onFocusChange?: (focused: boolean) => void
  readonly style?: StyleAttribute
}) => {
  const d = CommonHelper.date.valid(props.date)
    ? (props.date as Date)
    : new Date()
  const [hours, setHours] = useState(d.getHours())
  const [minutes, setMinutes] = useState(d.getMinutes())
  const [isChanged, setIsChanged] = useState(false)

  const onSelectMinutes = useCallback((key) => {
    const y = parseInt(key, 10)
    setMinutes(y)
    setIsChanged(true)
  }, [])

  const onSelectHours = useCallback((key) => {
    const h = parseInt(key, 10)
    setHours(h)
    setIsChanged(true)
  }, [])

  const onSet = useCallback(() => {
    if (props.onChange) {
      const date = new Date()
      date.setHours(hours)
      date.setMinutes(minutes)
      props.onChange(date)
    }

    if (props.onClose) {
      props.onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, minutes, props.onChange, props.onClose])

  return (
    <Box
      tabindex={1}
      onFocus={
        props.onFocusChange ? () => props.onFocusChange?.(true) : undefined
      }
      onBlur={
        props.onFocusChange ? () => props.onFocusChange?.(false) : undefined
      }
      style={[Styles.container, props.style]}
    >
      <Box row style={Styles.header}>
        <Select
          fit
          options={hoursOptions}
          value={String(hours)}
          onChange={onSelectHours}
          style={Styles.month}
        />
        <Select
          fit
          options={minutesOptions}
          value={String(minutes)}
          onChange={onSelectMinutes}
          style={Styles.year}
        />
      </Box>
      <Box centering="v" row>
        <Box flex />
        <Button
          theme="border-text-secondary"
          onPress={props.onClose}
          style={Styles.button}
        >
          Cancel
        </Button>
        <Button theme="warning" disabled={!isChanged} onPress={onSet}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
