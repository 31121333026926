import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    padding: [Sizes.margin.pad, Sizes.margin.compact, 6],
    borderRadius: 100,
  },

  text: {
    color: Colors.white,
    padding: [Sizes.margin.pad, Sizes.margin.compact],
  },

  primary: {
    backgroundColor: Colors.palette('blue')[2],
  },

  ok: {
    backgroundColor: Colors.palette('green')[3],
  },

  warning: {
    backgroundColor: Colors.palette('yellow')[2],
  },

  unsubmitted: {
    backgroundColor: Colors.palette('yellow')[9],
  },

  submitted: {
    backgroundColor: Colors.palette('salmon')[2],
  },

  black: {
    backgroundColor: Colors.black,
  },

  rejected: {
    backgroundColor: Colors.palette('red')[3],
  },

  revised: {
    backgroundColor: Colors.palette('turquoise')[3],
  },

  ongoing: {
    backgroundColor: Colors.palette('blue')[1],
    color: Colors.palette('blue')[10],
  },

  finished: {
    backgroundColor: Colors.success,
  },

  cancelled: {
    backgroundColor: Colors.palette('red')[1],
  },

  ended: {
    backgroundColor: Colors.palette('grey')[14],
    color: Colors.black,
  },
})
