/* eslint-disable no-console */
import { Defaults } from '../../../constants'

enum LEVEL {
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4,
}

const LEVELS = Object.keys(LEVEL)
const NOOP: () => void = () => undefined

class LoggerModel {
  private method(level: keyof typeof LEVEL) {
    switch (level) {
      case 'DEBUG':
        return 'log'
      case 'INFO':
        return 'info'
      case 'WARN':
        return 'warn'
      case 'ERROR':
        return 'error'
    }
  }

  readonly color = '#1111AA'

  // TODO
  log(level: keyof typeof LEVEL, ...arg: unknown[]): void
  log(...arg: unknown[]): void {
    if (Defaults.DEBUG) {
      // eslint-disable-next-line no-mixed-operators
      const level: keyof typeof LEVEL =
        (arg.length > 1 && LEVELS.indexOf(arg[0] as string) > -1 && (arg.shift() as 'DEBUG')) || 'DEBUG'

      if (Defaults.DEBUG_LEVEL >= LEVEL[level]) {
        console[this.method(level)](
          `%c[${level}]%c`,
          `color:${this.color};font-weight:bold`,
          'font-weight:normal',
          ...arg
        )
      }
    }
  }

  create(
    name: string,
    color: string = this.color
  ): {
    error: (...args: unknown[]) => void
    warn: (...args: unknown[]) => void
    info: (...args: unknown[]) => void
    log: (...args: unknown[]) => void
  } {
    if (Defaults.DEBUG) {
      return {
        get error() {
          if (Defaults.DEBUG_LEVEL >= LEVEL.ERROR) {
            return console['error'].bind(
              console,
              `%c[${name}]%c`,
              `color:${color};font-weight:bold`,
              'font-weight:normal'
            )
          } else {
            return NOOP
          }
        },
        get warn() {
          if (Defaults.DEBUG_LEVEL >= LEVEL.WARN) {
            return console['warn'].bind(
              console,
              `%c[${name}]%c`,
              `color:${color};font-weight:bold`,
              'font-weight:normal'
            )
          } else {
            return NOOP
          }
        },
        get info() {
          if (Defaults.DEBUG_LEVEL >= LEVEL.INFO) {
            return console['info'].bind(
              console,
              `%c[${name}]%c`,
              `color:${color};font-weight:bold`,
              'font-weight:normal'
            )
          } else {
            return NOOP
          }
        },
        get log() {
          if (Defaults.DEBUG_LEVEL >= LEVEL.DEBUG) {
            return console['log'].bind(
              console,
              `%c[${name}]%c`,
              `color:${color};font-weight:bold`,
              'font-weight:normal'
            )
          } else {
            return NOOP
          }
        },
      }
    } else {
      return {
        get error() {
          return NOOP
        },
        get warn() {
          return NOOP
        },
        get info() {
          return NOOP
        },
        get log() {
          return NOOP
        },
      }
    }
  }
}

export const Logger = new LoggerModel()
