import React, { useCallback, useEffect, useState } from 'react'
import { Logger } from '../../libs/com'
import { CommonHelper } from '../../helpers'
import { Lang } from '../../types'

export function AppContextFactory<
  B extends {
    [key: string]: ['min' | 'max', number]
  } & {
    default: ['min' | 'max', number]
  }
>(breakpoints: B) {
  type Keys = keyof B
  type Context = {
    lang?: Lang
    media: {
      size: Keys
      sizes: Keys[]
      is: (...medias: Keys[]) => boolean
      smaller: (media: Keys) => boolean
      larger: (media: Keys) => boolean
    }
    overlay: {
      status: () => {
        status: 'active' | 'inactive'
        alert: number
        modal: number
      }
      set: (type: 'alert' | 'modal', num: number) => void
    }
  }

  function getValue(bp: [string, number]) {
    return bp[0] === 'max' ? bp[1] : 10000 - bp[1]
  }

  const [queries, values]: [string[], Keys[]] = Object.keys(breakpoints)
    .sort((a, b) => {
      return (
        getValue(breakpoints[a as Keys] as [string, number]) -
        getValue(breakpoints[b as Keys] as [string, number])
      )
    })
    .map((key) => {
      const size = breakpoints[key as Keys]
      return [key, `(${size[0]}-width: ${size[1]}px)`]
    })
    .reduce(
      (sum, curr) => {
        sum[0].push(curr[1])
        sum[1].push(curr[0] as Keys)

        return sum
      },
      [[], []] as [string[], Keys[]]
    )

  const ladder = (Object.keys(breakpoints) as Keys[]).sort((a, b) => {
    const _a = breakpoints[a as Keys]
    const _b = breakpoints[b as Keys]

    return (
      _a[1] +
      (_a[0] === 'min' ? 0.1 : -0.1) -
      (_b[1] + (_b[0] === 'min' ? 0.1 : -0.1))
    )
  })

  const context = React.createContext<Context>({
    lang: 'en',
    media: {
      size: 'default',
      sizes: Object.keys(breakpoints) as Keys[],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      is(...medias: Keys[]) {
        Logger.log(
          'WARN',
          'You are calling app.media.is from outside of an app context'
        )
        return false
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      smaller(media: Keys) {
        Logger.log(
          'WARN',
          'You are calling app.media.smaller from outside of an app context'
        )
        return false
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      larger(media: Keys) {
        Logger.log(
          'WARN',
          'You are calling app.media.larger from outside of an app context'
        )
        return false
      },
    },
    overlay: {
      status() {
        Logger.log(
          'WARN',
          'You are calling app.overlay.get from outside of an app context'
        )
        return {
          status: 'inactive',
          alert: 0,
          modal: 0,
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      set(type, num) {
        Logger.log(
          'WARN',
          'You are calling app.overlay.set from outside of an app context'
        )
      },
    },
  })

  return {
    AppContext: context,
    useAppContextMediaQuery(ua: 'mobile' | 'desktop') {
      // const defaultValue: Keys = 'default'

      const match = useCallback((): Keys => {
        return values[queries.findIndex((q) => matchMedia(q).matches)] || ua
      }, [ua])

      const [value, set] = useState<Keys>(match)

      const isMedia = useCallback(
        (...medias: Keys[]) => {
          return medias.indexOf(value) > -1
        },
        [value]
      )

      const isMediaSmaller = useCallback(
        (m: Keys) => {
          return ladder.indexOf(value) < ladder.indexOf(m)
        },
        [value]
      )

      const isMediaLarger = useCallback(
        (m: Keys) => {
          return ladder.indexOf(value) > ladder.indexOf(m)
        },
        [value]
      )

      useEffect(() => {
        const handler = CommonHelper.fn.debounce(() => set(match), 100, {
          leading: true,
          trailing: true,
        })

        window.addEventListener('resize', handler)

        return () => window.removeEventListener('resize', handler)
      }, [match])

      return {
        size: value,
        sizes: ladder,
        is: isMedia,
        smaller: isMediaSmaller,
        larger: isMediaLarger,
      }
    },
    useAppContextOverlayCounter() {
      const $body = document.getElementsByTagName('body')[0]
      let status: 'active' | 'inactive' = 'inactive'
      let alertCount = 0
      let modalCount = 0

      return {
        status() {
          return {
            status,
            alert: alertCount,
            modal: modalCount,
          }
        },
        set(type: 'alert' | 'modal', val: number) {
          if (type === 'alert') {
            alertCount = val
          } else if (type === 'modal') {
            modalCount = val
          }

          if (alertCount || modalCount) {
            status = 'active'
            $body.classList.add('is-noscroll')
          } else {
            status = 'inactive'
            $body.classList.remove('is-noscroll')
          }
        },
      }
    },
  }
}
