import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA } from '../../../libs/com'
import { Colors } from '../../../constants'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Text } from '../text'

import Styles from './styles'

export function Radio({
  onChange,
  ...props
}: {
  readonly active?: boolean
  readonly disabled?: boolean
  readonly color?: string
  readonly label?: string | ReactNode
  readonly autofocus?: boolean
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChange?: (active: boolean) => void
  readonly style?: StyleAttribute
}): ReactElement {
  const [active, setActive] = useState(props.active)
  const [changed, setChanged] = useState<null | boolean>(null)
  const [focused, setFocused] = useState(false)
  const box = useRef({} as HTMLDivElement)
  const color = props.color ?? Colors.black

  if (changed !== !!props.active) {
    setChanged(!!props.active)
    setActive(props.active)
  }

  useLayoutEffect(() => {
    if (props.autofocus && box.current) {
      box.current.focus()
    }
  }, [props.autofocus])

  const onFocus = useCallback(() => {
    if (props.disabled) return
    setFocused(true)
  }, [props.disabled])

  const onBlur = useCallback(() => {
    if (props.disabled) return
    setFocused(false)
  }, [props.disabled])

  const onToggle = useCallback(() => {
    if (props.disabled) return

    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }

    setActive(!active)

    if (onChange) {
      onChange(!active)
    }
  }, [active, onChange, props.tracker, props.trackerParam, props.disabled])

  return (
    <Box
      row
      ref={box}
      tabindex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      onPress={onToggle}
      style={props.style}
    >
      <Box
        centering
        style={[
          Styles.icon,
          props.disabled && active && Styles.disabled,
          focused && Styles.focused,
        ]}
      >
        {active ? (
          <Box
            style={[
              Styles.circle,
              {
                backgroundColor: props.disabled
                  ? Colors.palette('grey')[10]
                  : color,
              },
            ]}
          />
        ) : (
          false
        )}
      </Box>
      {props.label ? (
        typeof props.label === 'string' ? (
          <Text size="small" style={Styles.label}>
            {props.label}
          </Text>
        ) : (
          <Box style={Styles.label}>{props.label}</Box>
        )
      ) : (
        false
      )}
    </Box>
  )
}
