import React, { useContext } from 'react'
import { PageContext } from '../../../contexts'
import { useMount } from '../../../hooks'

import { Text } from '../text'

export function Link(
  props: React.ComponentProps<typeof Text> & {
    readonly href: string
    readonly page?: React.ContextType<typeof PageContext>
    readonly target?: 'blank' | 'self' | 'top' | 'parent'
    readonly rel?: 'noopener' | 'noreferrer' | 'noopener,noreferrer'
    readonly tracker?: string
    readonly trackerParam?: Record<string, string>
    readonly params?: Record<string, unknown>
  }
) {
  const { href, target, onPress: textOnPress, ...textProps } = props
  const _page = useContext(PageContext)
  const page = props.page ?? _page
  const onPress = (
    e: React.MouseEvent<
      | HTMLParagraphElement
      | HTMLAnchorElement
      | HTMLUListElement
      | HTMLOListElement
      | HTMLLIElement
    >
  ) => {
    e.preventDefault()

    // Stop event propragation if told so
    if (props.stopPropagation) {
      e.stopPropagation()
    }

    if (textOnPress) return textOnPress(e)

    switch (true) {
      case !!href.match(/^\//):
        if (target) {
          page.navigator.tab(href.substr(1), target)
        } else {
          page.navigator.navigate(href.substr(1), props.params)
        }
        break
      case !!href.match(/^#/): {
        const elmnt = document.getElementById(href.substr(1))
        elmnt?.scrollIntoView()
        break
      }
      default:
        page.navigator.tab(href, target ?? 'self')
    }
  }

  useMount(() => {
    if (href.match(/^\//)) {
      page.navigator.prefetch(href.substr(1))
    }
  }, [])

  return (
    <Text
      {...textProps}
      element={props.element ?? 'a'}
      underline={props.underline ?? true}
      href={href}
      target={target}
      tracker={props.tracker}
      trackerParam={props.trackerParam}
      onPress={onPress}
    />
  )
}
