import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { HtmlPortalNode, OutPortal } from 'react-reverse-portal'
import { useMount } from '../../../hooks'
import { PageStaticContext } from '../../../contexts'
import { Rect, StyleAttribute } from '../../../types'

import { Box } from '../box'

type PortedSticky = {
  id: number
  portal: HtmlPortalNode
  offset?: {
    left?: number
    right?: number
  }
  isVisible: (isIntersecting: boolean) => boolean
}

function useObserver(
  stickies: Map<HTMLDivElement, PortedSticky>,
  activeIds: number[],
  setter: React.Dispatch<React.SetStateAction<number[]>>,
  margin?: string
) {
  const pageStatic = useContext(PageStaticContext)
  const observer = useRef<IntersectionObserver>()
  const entries = useRef(
    new WeakMap<HTMLDivElement, IntersectionObserverEntry>()
  )

  return () => {
    observer.current?.disconnect()

    observer.current = new IntersectionObserver(
      (e) => {
        // Update entry
        e.forEach((entry) => {
          const sticky = stickies.get(entry.target as HTMLDivElement)

          if (sticky) {
            entries.current.set(entry.target as HTMLDivElement, entry)
          }
        })

        // Set Actives
        const newActiveIds = Array.from(stickies.keys())
          .map((key) => {
            const entry = entries.current.get(key)
            const sticky = stickies.get(key)

            return entry
              ? (sticky?.isVisible(entry.isIntersecting) && sticky.id) || 0
              : 0
          })
          .filter((d) => !!d)

        if (newActiveIds.join(',') !== activeIds.join(',')) {
          setter(newActiveIds)
        }
      },
      {
        root: pageStatic.stickies.root,
        rootMargin: margin,
        // Safari fix, safari can't get to 1.0 threshold somehow
        threshold: 0.999999,
      }
    )

    stickies.forEach((val, el) => {
      observer.current?.observe(el)
    })

    return () => {
      observer.current?.disconnect()
    }
  }
}

function useStickyImplementation(
  stickies: Map<
    HTMLDivElement,
    {
      id: number
      portal: HtmlPortalNode
      isVisible: (isIntersecting: boolean) => boolean
    }
  >,
  layout: {
    current: Rect
    removing: Rect
  },
  bottom?: boolean
) {
  const removingHeight = Math.max(
    0,
    layout.current.height - layout.removing.height
  )
  const [activeLists, setActiveLists] = useState<number[]>([])
  const observer = useObserver(
    stickies,
    activeLists,
    setActiveLists,
    bottom
      ? `10000% 0% ${-layout.current.height}px 0%`
      : `${-layout.current.height}px 0% 10000% 0%`
  )
  const removalObserver = useObserver(
    stickies,
    activeLists,
    setActiveLists,
    bottom
      ? `10000% 0% ${-removingHeight}px 0%`
      : `${-removingHeight}px 0% 10000% 0%`
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(observer, [stickies, activeLists, layout.current.height])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(removalObserver, [stickies, activeLists, removingHeight])

  return activeLists
}

export function StickyPart(props: {
  readonly bottom?: boolean
  readonly style?: StyleAttribute
}): ReactElement {
  const spage = useContext(PageStaticContext)
  const [bounds, setBounds] = useState<Rect>({
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  })
  const [removingBounds, setRemovingBounds] = useState<Rect>({
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  })
  const [stickies, setStickies] = useState<{
    map: Map<HTMLDivElement, PortedSticky>
    array: PortedSticky[]
  }>({
    map: new Map(),
    array: [],
  })

  const activeIds = useStickyImplementation(
    stickies.map,
    {
      current: bounds,
      removing: removingBounds,
    },
    props.bottom
  )

  useMount(() => {
    // Listen for sticky changes
    // Sticky changes happens when there's new sticky on
    // the layout
    spage.stickies.onChange((s) => {
      // Filter sticky to the one dedicated for the placement
      // Either top or bottom
      const myStickies = Array.from(s, ([key, value]) => ({
        id: key,
        ...value,
      }))
        .filter((sticky) => {
          return props.bottom ? sticky.bottom : !sticky.bottom
        })
        .reduce((map, sticky) => {
          return map.set(sticky.el, {
            id: sticky.id,
            portal: sticky.portal,
            offset: sticky.offset,
            isVisible: sticky.isVisible,
          })
        }, new Map() as typeof stickies['map'])

      // Set the sticky,
      // Map for ease of use in useStickyImplementation
      // Array for ease of use in rendering, so we don't need to
      // transform the map into array each time this component rerenders
      setStickies({
        map: myStickies,
        array: Array.from(myStickies.values()),
      })
    })
  })

  useEffect(() => {
    // Update static page layout
    // Conforming to current bound
    if (props.bottom) {
      spage.layout.footer = bounds
    } else {
      spage.layout.header = bounds
    }
  }, [bounds, spage.layout, props.bottom])

  useEffect(() => {
    if (bounds.height < removingBounds.height) {
      setRemovingBounds(bounds)
    }
  }, [bounds, removingBounds])

  return (
    <Box accessible={'children'} style={props.style} onLayout={setBounds}>
      {stickies.array.map((sticky) => {
        const shouldGetBounds = props.bottom
          ? sticky.id === activeIds[0]
          : sticky.id === activeIds[activeIds.length - 1]
        return activeIds.includes(sticky.id) ? (
          <Box
            key={sticky.id}
            onLayout={shouldGetBounds ? setRemovingBounds : undefined}
            style={sticky.offset}
          >
            <OutPortal node={sticky.portal} />
          </Box>
        ) : (
          false
        )
      })}
    </Box>
  )
}
