import { useEffect, useRef, useState } from 'react'

export function useStateSafe<T extends unknown>(
  d: T
): [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<boolean>] {
  const isMounted = useRef(false)
  const [state, setState] = useState(d)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return [
    state,
    (val: React.SetStateAction<T>) => {
      if (isMounted.current) {
        setState(val)
      }
    },
    isMounted,
  ]
}
