import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.medium,
    paddingRight: Sizes.margin.medium,
    borderWidth: 1,
    borderColor: Colors.palette('grey')[9],
  },
  normal: {
    foreground: Colors.palette('grey')[13],
    style: {
      backgroundColor: Colors.white,

      '&:hover': {
        backgroundColor: Colors.palette('grey')[10],
      },
    },
  },
  active: {
    foreground: Colors.palette('grey')[13],
    style: {
      backgroundColor: Colors.palette('grey')[14],
    },
  },
  disabled: {
    foreground: Colors.palette('grey')[8],
    style: {
      borderWidth: 0,
      backgroundColor: Colors.palette('grey')[7],
    },
  },
}
