import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  icon: {
    height: 20,
    width: 20,
    marginRight: Sizes.margin.half,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: Colors.palette('grey')[6],
    transition: '.3s color, .3s border, .3s opacity',
    transform: 'translateY(4px)',
  },

  disabled: { background: '#C9CBCF', borderColor: '#C9CBCF' },

  focused: {
    borderColor: Colors.primary,
  },

  circle: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },

  label: {
    marginTop: 2,
  },
})
