import React, { useCallback, useEffect } from 'react'
import { ImageResolver, StyleSheet } from '../../../libs/com'
import { StyleAttribute } from '../../../types'
import { Colors } from '../../../constants'
import { useStateSafe } from '../../../hooks'

import { Box } from '../box'
// import { Icon } from '../icon'
import { Loader } from '../loader'

import Styles from './styles'

import BrokenImage1 from './broken.png'
import BrokenImage2 from './broken@2x.png'
import BrokenImage3 from './broken@3x.png'

const BrokenImage = [BrokenImage1, BrokenImage2, BrokenImage3]

type Resize = 'cover' | 'contain' | 'fill' | 'none'

function resize(re?: Resize) {
  switch (re) {
    case 'cover':
      return Styles.cover
    case 'contain':
    default:
      return Styles.contain
    case 'fill':
      return Styles.fill
    case 'none':
      return Styles.none
  }
}

function generateSrcSet(
  srcSet: (string | null)[] | undefined
): string | undefined {
  if (srcSet) {
    return srcSet
      .map((s, i) => {
        return s && `${s}${i > 0 ? ` ${i + 1}x` : ''}`
      })
      .filter((s) => !!s)
      .join(', ')
  }

  return srcSet as undefined
}

export const Image = (props: {
  readonly accessible?: boolean
  readonly sources?: {
    source: Promise<string | undefined>
    srcset: Promise<(string | null)[] | undefined>
  }
  readonly source?: string
  readonly srcset?: (string | null)[]
  readonly resize?: Resize
  readonly position?: string
  readonly flex?: boolean
  readonly loader?: boolean
  readonly alt?: string
  readonly background?: string
  readonly heightRatio?: number
  readonly widthRatio?: number
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onPress?: () => void
  readonly style?: StyleAttribute
  readonly rounded?: boolean
}) => {
  const loader = props.loader ?? true
  const [rect, setRect] = useStateSafe<{
    w?: number
    h?: number
  }>({})
  const [sources, setSources] = useStateSafe<{
    source?: string
    srcset?: string
  }>({})
  const [{ loading, source }, setState] = useStateSafe<{
    loading: boolean
    source: boolean
  }>({
    loading: (!!props.source || !props.srcset || !!props.sources) && loader,
    source: false,
  })

  // Set height / width based on heightRatio / widthRatio
  const onLayoutChanged = useCallback(
    (r: DOMRectReadOnly) => {
      if (props.heightRatio) {
        if (!rect.h || rect.h !== r.width * props.heightRatio) {
          setRect({
            h: r.width * props.heightRatio,
          })
        }
      } else if (props.widthRatio) {
        if (!rect.w || rect.w !== r.height * props.widthRatio) {
          setRect({
            w: r.height * props.widthRatio,
          })
        }
      }
    },
    [props.heightRatio, props.widthRatio, setRect, rect]
  )

  // Configure source / srcset on props.source or props.srcset change
  useEffect(() => {
    if (props.source || props.srcset || props.sources) {
      setSources({})

      if (!loading || source) {
        setState({
          loading: true,
          source: false,
        })
      }

      const src = props.source || props.sources?.source
      const srcset = props.srcset || props.sources?.srcset
      const image = new window.Image()

      image.onerror = () => {
        if (
          (src === props.source || props.sources?.source) &&
          (srcset === props.srcset || props.sources?.srcset)
        ) {
          setState({
            loading: false,
            source: false,
          })
        }
      }

      image.onload = () => {
        if (
          (src === props.source || props.sources?.source) &&
          (srcset === props.srcset || props.sources?.srcset)
        ) {
          setState({
            loading: false,
            source: true,
          })
        }
      }

      if (src) {
        if (src instanceof Promise) {
          src.then((_src) => {
            if (_src) {
              setSources({
                source: (image.src = _src),
                srcset: undefined,
              })
            }
          })
        } else {
          setSources({
            source: (image.src = src),
            srcset: undefined,
          })
        }
      }

      if (srcset) {
        if (srcset instanceof Promise) {
          srcset.then((_srcset) => {
            if (_srcset) {
              setSources({
                source: undefined,
                srcset: (image.srcset = generateSrcSet(_srcset) as string),
              })
            }
          })
        } else {
          setSources({
            source: undefined,
            srcset: (image.srcset = generateSrcSet(srcset) as string),
          })
        }
      }
    } else if (loading || source) {
      setState({
        loading: false,
        source: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source, props.srcset, props.sources])

  return (
    <Box
      accessible={props.accessible}
      flex={props.flex}
      onLayout={onLayoutChanged}
      tracker={props.tracker}
      trackerParam={props.trackerParam}
      onPress={props.onPress}
      style={[
        (!!props.heightRatio || !!props.widthRatio) && {
          width: rect.w,
          height: rect.h,
        },
        props.style,
      ]}
    >
      {source ? (
        <img
          src={sources.source}
          srcSet={sources.srcset}
          alt={props.alt}
          {...StyleSheet.classNameAndStyle([
            Styles.image,
            resize(props.resize),
            {
              backgroundColor: props.background ?? Colors.placeholder,
              ...(props.position
                ? {
                    objectPosition: props.position,
                  }
                : {}),
            },
            props.rounded && Styles.rounded,
          ])}
        />
      ) : (
        <Box
          flex
          centering
          style={[
            Styles.image,
            {
              backgroundColor: props.background ?? Colors.placeholder,
            },
          ]}
        >
          {loader && loading ? (
            <Loader />
          ) : (
            <img
              srcSet={generateSrcSet(BrokenImage)}
              alt={props.alt}
              className={Styles.broken}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

Image.resolver = ImageResolver.resolve
