import React, { ReactNode, useContext } from 'react'
import { AppContext } from '../../../contexts'
import { Colors } from '../../../constants'
import { IconName, StyleAttribute } from '../../../types'

import { Box } from '../../blocks/box'
import { Icon } from '../../blocks/icon'
import { Text } from '../../blocks/text'

import Styles from './styles'

type Types =
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'warning-triangle'
  | 'info-primary'
  | 'info-primary-outline'

function style(type: Types, accent: boolean) {
  switch (type) {
    case 'error':
      return accent ? Styles.accentError : Styles.error
    case 'success':
      return Styles.success
    case 'warning':
      return Styles.warning
    case 'warning-triangle':
      return Styles.warning
    case 'info-primary':
      return Styles.infoPrimary
    case 'info':
    default:
      return Styles.info
  }
}

function color(type: Types) {
  switch (type) {
    case 'error':
      return Colors.error
    case 'success':
      return Colors.success
    case 'warning':
      return Colors.palette('yellow')[3]
    case 'warning-triangle':
      return '#53575A'
    case 'info-primary':
      return Colors.primary
    case 'info-primary-outline':
      return Colors.primaryNew
    case 'info':
    default:
      return Colors.palette('blue')[3]
  }
}

function icon(type: Types): IconName {
  switch (type) {
    case 'error':
      return 'triangle.warning'
    case 'warning-triangle':
      return 'triangle.warning'
    case 'success':
      return 'circle.confirm'
    case 'info-primary-outline':
      return 'info'
    case 'info':
    case 'info-primary':
    default:
      return 'circle.info'
  }
}

export const NotificationComponent = (props: {
  readonly type: Types
  readonly flex?: boolean
  readonly children: string | ReactNode
  readonly style?: StyleAttribute
  readonly textStyle?: StyleAttribute
  readonly accent?: boolean
}) => {
  const app = useContext(AppContext)
  const isDesktop = app.media.larger('small')

  return (
    <Box
      flex={props.flex}
      centering={isDesktop ? 'v' : false}
      row
      style={[
        props.accent ? Styles.accent : Styles.container,
        style(props.type, !!props.accent),
        props.style,
      ]}
    >
      <Icon name={icon(props.type)} size={24} color={color(props.type)} />
      {typeof props.children === 'string' ? (
        <Text size="small" style={[Styles.text, props.textStyle]}>
          {props.children}
        </Text>
      ) : (
        <Box flex style={[Styles.text, props.textStyle]}>
          {props.children}
        </Box>
      )}
    </Box>
  )
}
