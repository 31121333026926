import React, {
  ReactElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA, StyleSheet } from '../../../libs/com'
import { useUpdate } from '../../../hooks'
import { PageContext } from '../../../contexts'
import { CommonHelper } from '../../../helpers'
import { Colors } from '../../../constants'
import { InputStyles } from '../../../styles'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Icon } from '../icon'

import { TimePart } from './_time'

import Styles from './styles'

export function InputTime(props: {
  readonly testId?: string
  readonly fit?: boolean
  readonly placeholder?: string
  readonly autofocus?: boolean
  readonly value?: Date
  readonly valid?: boolean
  readonly disabled?: boolean
  readonly readonly?: boolean
  readonly clearable?: boolean
  readonly tabindex?: number
  readonly prefix?: React.ReactNode
  readonly suffix?: React.ReactNode
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onChange?: (date?: Date) => void
  readonly onSubmit?: (date?: Date) => void
  readonly input?: StyleAttribute
  readonly style?: StyleAttribute
}): ReactElement {
  const page = useContext(PageContext)
  const input = useRef<HTMLButtonElement>(null)
  const fit = props.fit ?? false
  const clearable = props.clearable ?? true
  const placeholder = props.placeholder ?? 'Select time…'
  const [date, setDate] = useState(
    CommonHelper.date.valid(props.value) ? props.value : null
  )
  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(
    () => {
      setFocused(true)

      if (props.onChangeFocus) {
        props.onChangeFocus(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onBlur = useCallback(
    () => {
      setFocused(false)

      if (props.onChangeFocus) {
        props.onChangeFocus(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onSelectDate = useCallback(
    (d: Date) => {
      setDate(d)

      if (props.onChange) {
        props.onChange(d)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (props.onSubmit && e.key === 'Enter') {
        props.onSubmit(date || undefined)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSubmit, date]
  )

  const onShowTime = useCallback(() => {
    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }

    page.modal.open({
      component({ onClose }: { onClose: () => void }) {
        return (
          <TimePart
            date={date || undefined}
            onClose={() => {
              onClose()
              if (input.current) {
                input.current.focus()
              }
              // Force focus even when element looses focus
            }}
            onChange={onSelectDate}
            onFocusChange={() => {
              if (props.onChangeFocus) {
                props.onChangeFocus(true)
              }
            }}
          />
        )
      },
    })
  }, [page, date, onSelectDate, props])

  const onClear = useCallback(() => {
    setDate(null)

    if (props.onChange) {
      props.onChange(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onChange])

  useUpdate(() => {
    // Update date if props changed
    setDate(CommonHelper.date.valid(props.value) ? props.value : null)
  }, [props.value])

  useLayoutEffect(() => {
    if (props.autofocus && input.current) {
      input.current.focus()
    }
  }, [props.autofocus])

  return (
    <Box
      row
      centering="h"
      style={[
        InputStyles.container,
        props.disabled
          ? InputStyles['container-disabled']
          : props.readonly
          ? InputStyles['container-readonly']
          : undefined,
        props.valid && InputStyles['container-valid'],
        props.valid === false && InputStyles['container-invalid'],
        focused && InputStyles['container-focused'],
        fit && InputStyles.fit,
        props.style,
      ]}
    >
      {props.prefix ?? (
        <Icon
          name="time"
          color={Colors.palette('grey')[3]}
          style={Styles.icon}
        />
      )}
      <input
        readOnly
        data-testid={props.testId}
        tabIndex={-1}
        disabled={props.disabled}
        placeholder={placeholder}
        value={date ? CommonHelper.date.format(date, 'H:MM') : '00:00'}
        {...StyleSheet.classNameAndStyle([
          InputStyles.input,
          props.disabled
            ? InputStyles['input-disabled']
            : props.readonly
            ? InputStyles['input-readonly']
            : undefined,
          props.valid && InputStyles['input-valid'],
          props.valid === false && InputStyles['input-invalid'],
          focused && InputStyles['input-focused'],
          props.input,
          Styles.colorGrey,
        ])}
      />
      {props.suffix ??
        (date && !props.readonly && !props.disabled && clearable ? (
          <Box centering style={Styles.clearer} onPress={onClear}>
            <Icon name="decline" size={12} color={Colors.border} />
          </Box>
        ) : (
          false
        ))}
      <button
        ref={input}
        disabled={props.disabled}
        autoFocus={props.autofocus}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onShowTime}
        tabIndex={props.tabindex}
        {...StyleSheet.classNameAndStyle([Styles.input])}
      ></button>
    </Box>
  )
}
