export enum ERRORS {
  UNKNOWN,
  NOT_VALID,
  NOT_FOUND,
  NOT_VERIFIED,
  NOT_AUTHORIZED,
  NOT_SATISFIED,
  NULL_RESULT,
}

export enum STATE {
  ERROR,
  VALID,
  DEFAULT,
}
