/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useState } from 'react'
import { StateHelper } from '../../helpers'
import { STATE } from '../../constants'

export function useInputState(defaultState: STATE = STATE.DEFAULT) {
  const [state, setState] = useState(defaultState)

  return [state, setState] as [STATE, typeof setState]
}

export function useInputStates(states: STATE[]) {
  const [state, setState] = useState(StateHelper.sumState(states))

  useEffect(() => {
    setState(StateHelper.sumState(states))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...states])

  return state
}

/**
 * Use this hook to capture textinput value
 */
export function useInputAndState<T extends unknown>(defaultValue: T, defaultState: STATE = STATE.DEFAULT) {
  const [value, setValue] = useState(defaultValue)
  const [state, setState] = useState(defaultState)

  return [value, state, setValue, setState] as [T, STATE, typeof setValue, typeof setState]
}

export function useInputAndStateEffect<T extends unknown>(defaultValue: T, defaultState: STATE = STATE.DEFAULT, effect: (value: T) => void) {
  const [value, setValue] = useState(defaultValue)
  const [state, setState] = useState(defaultState)

  const setValueWithEffect = useCallback((val: T) => {
    setValue(val)
    effect(val)
  }, [effect]) as React.Dispatch<React.SetStateAction<T>>

  return [value, state, setValueWithEffect, setState] as [T, STATE, typeof setValue, typeof setState]
}
