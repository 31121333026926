import { BaseClass } from '../_base'
import { Setter, NonFunction } from '../../types'
import { Store } from '../../libs/com'

type SetConfig = {
  silent?: boolean
  wait?: boolean
}

export type NonFunctionOrNever<
  T extends unknown,
  U extends unknown
> = T extends NonFunction ? U : never

export class ManagerModel<
  Properties extends Record<string, Value> = Record<string, never>,
  Value extends unknown = NonFunction
> extends BaseClass {
  private _initialProperties: Properties
  private _properties: Properties
  private slug: string | null = null
  private persist = true
  private initialized: Promise<this> | null = null
  private subscribers = new Set<Setter<Properties>>()

  protected expiry = -1

  protected get properties(): Properties {
    return this._properties
  }

  protected set properties(properties: Properties) {
    this._properties = properties
    this.broadcast()
    this.save()
  }

  constructor(
    name: string,
    initialProperties: Properties = {} as Properties,
    config?: {
      slug: NonFunctionOrNever<Value, string | undefined>
      expiry?: number
    }
  ) {
    super(name)
    this._initialProperties = initialProperties
    this._properties = { ...initialProperties }
    this.persist = !!config?.slug
    this.slug = config?.slug ?? null
    this.expiry = config?.expiry ?? -1
    this.init()
  }

  protected async save(): Promise<boolean> {
    if (this.persist) {
      await Store.save(
        `manager/${this.slug}`,
        this.properties,
        this.expiry > -1 ? Date.now() + this.expiry : -1
      ).catch(() => null)
    }

    return true
  }

  protected broadcast(): void {
    this.subscribers.forEach((setter) => {
      setter(this._properties)
    })
  }

  protected async broadcastAndSave(wait?: boolean): Promise<void> {
    this.broadcast()

    if (wait) {
      await this.save()
    } else {
      this.save()
    }
  }

  protected async unsubscribe(setter: Setter<Properties>): Promise<void> {
    this.subscribers.delete(setter)
  }

  public async init(): Promise<this> {
    if (this.initialized === null) {
      this.initialized = Promise.resolve().then(async () => {
        // TODO: Fix if later has it's model changed
        if (this.persist) {
          this._properties = await Store.load<Properties>(
            `manager/${this.slug}`
          )
            .then((res) => {
              return {
                ...this._properties,
                ...res,
              }
            })
            .catch(() => {
              return this._properties
            })

          this.broadcast()
        }

        return this
      })
    }

    return this.initialized
  }

  public subscribe(setter: Setter<Properties>): () => void {
    this.subscribers.add(setter)

    return () => this.unsubscribe(setter)
  }

  public get(): Properties
  public get<T extends keyof Properties>(key: T): Properties[T]
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public get<T extends keyof Properties>(key?: T) {
    if (key) {
      return this._properties[key]
    } else {
      return this._properties
    }
  }

  public async set<T extends keyof Properties>(
    key: T,
    value: Properties[T],
    config?: SetConfig
  ): Promise<void> {
    const silent = config?.silent ?? false
    const wait = config?.wait ?? false

    this._properties[key] = value

    if (!silent) {
      this.broadcast()
    }

    if (wait) {
      await this.save()
    } else {
      this.save()
    }
  }

  public async sets<T extends keyof Properties>(
    keyAndValues: {
      [k in T]?: Properties[k]
    },
    config?: SetConfig
  ): Promise<void> {
    const silent = config?.silent ?? false
    const wait = config?.wait ?? false

    for (const k in keyAndValues) {
      if (k) {
        this._properties[k as T] = keyAndValues[k] as Properties[Extract<
          T,
          string
        >]
      }
    }

    if (!silent) {
      this.broadcast()
    }

    if (wait) {
      await this.save()
    } else {
      this.save()
    }
  }

  public async reset(config?: SetConfig): Promise<void> {
    const silent = config?.silent ?? false
    const wait = config?.wait ?? false

    this._properties = { ...this._initialProperties }

    if (!silent) {
      this.broadcast()
    }

    if (wait) {
      await this.save()
    } else {
      this.save()
    }
  }
}
