import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.thick + Sizes.margin.pad,
    paddingRight: Sizes.margin.thick + Sizes.margin.pad,
    borderWidth: 1,
    borderColor: Colors.black,
  },
  normal: {
    foreground: Colors.black,
    style: {
      backgroundColor: Colors.white,
    },
  },
  active: {
    foreground: Colors.palette('grey')[3],
    style: {
      borderColor: Colors.palette('grey')[3],
      backgroundColor: Colors.white,
    },
  },
  disabled: {
    foreground: Colors.border,
    style: {
      borderColor: Colors.border,
      backgroundColor: Colors.white,
    },
  },
  loading: {
    foreground: Colors.black,
    style: {
      backgroundColor: Colors.white,
    },
  },
}
