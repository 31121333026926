import { StyleSheet } from '../libs/com'
import { CommonHelper } from '../helpers'

function styleMaker<Values extends number | string = number | string>(
  type:
    | 'margin'
    | 'padding'
    | 'marginTop'
    | 'marginRight'
    | 'marginBottom'
    | 'marginLeft'
    | 'paddingTop'
    | 'paddingRight'
    | 'paddingBottom'
    | 'paddingLeft',
  size: Values
): string {
  return StyleSheet.create({
    size: {
      [type]: size,
    },
  }).size
}

export const SizeStyles = {
  make: CommonHelper.fn.memoize(styleMaker),
}
