import { useCallback, useState } from 'react'

import { useStateSafe } from '../state.safe'

export function useStateEffect<T extends unknown>(
  defaultValue: T,
  effect: (value: T) => void
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(defaultValue)

  const setValueWithEffect = useCallback(
    (val: T) => {
      setValue(val)
      effect(val)
    },
    [effect]
  ) as React.Dispatch<React.SetStateAction<T>>

  return [value, setValueWithEffect]
}

export function useStateEffectSafe<T extends unknown>(
  defaultValue: T,
  effect: (value: T) => void
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue, mounted] = useStateSafe(defaultValue)

  const setValueWithEffect = useCallback(
    (val: T) => {
      setValue(val)
      if (mounted.current) {
        effect(val)
      }
    },
    [effect, setValue, mounted]
  ) as React.Dispatch<React.SetStateAction<T>>

  return [value, setValueWithEffect]
}
