import React, { useContext, useState } from 'react'
import { useTransition } from 'react-spring'
import { AnimationStackModel } from '../../../models'
import { AppContext } from '../../../contexts'
import { useManager, useMount } from '../../../hooks'
import { ModalConfig } from '../../../types'

import { Box } from '../../blocks'
import { OverlayPart } from './_overlay'

import Styles from './styles'

export const ModalPart = (props: { readonly manager: AnimationStackModel<ModalConfig> }) => {
  const app = useContext(AppContext)
  const [stacks, setStack] = useState(Array.from(props.manager.get('stack').values()))
  const last = stacks[stacks.length - 1]
  const transitions = useTransition(stacks, (stack) => stack.id, {
    from: (stack) => {
      return stack.config.animation[0]
    },
    enter: (stack) => {
      return stack.config.animation[1]
    },
    leave: (stack) => {
      return stack.config.animation[0]
    },
    update: (stack) => {
      if (stack.state === 0) {
        return stack.config.animation[1]
      }

      return stack.config.animation[2] ? stack.config.animation[2](stack.state) : stack.config.animation[0]
    },
  })

  useManager(
    props.manager,
    (properties) => {
      app.overlay.set('modal', properties.stack.size)
      setStack(Array.from(properties.stack.values()))
    },
    []
  )

  useMount(() => {
    app.overlay.set('modal', props.manager.get('stack').size)
  }, [])

  return (
    <Box accessible="children" style={Styles.container}>
      <OverlayPart active={!!stacks.length} />
      {transitions.map(({ item, props: style, key }) => {
        return (
          <Box
            key={key}
            accessible={item.id === last?.id}
            acceptBubble={false}
            onPress={
              item.config.closeOnOverlayPress
                ? () => {
                    props.manager.remove(item.id)
                  }
                : undefined
            }
            centering
            animated
            style={[Styles.transitioner, style]}
          >
            <item.config.component
              key={key}
              onClose={() => {
                props.manager.remove(item.id)
              }}
              state={item.state}
            />
          </Box>
        )
      })}
    </Box>
  )
}
