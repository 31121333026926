export const EmailRegex =
  /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Need to have lowercase, upper case, and number,
// Minimum 8 characters length
export const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

// Numbers between 7 - 13 digits length
export const PhoneRegex = /^\+?\d{7,13}$/

// To convert number into currency
export const CurrencyRegex = /(\d)(?=(\d{3})+$)/g
