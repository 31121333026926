import React, { ReactElement, ReactNode, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AnimationStackModel } from '../../../models'
import {
  PageContext,
  usePageContext,
  PageStaticContext,
  usePageStaticContext,
} from '../../../contexts'
import { ModalConfig } from '../../../types'

import { ModalPart } from './_modal'

export const PageUtilities = ({
  component: Component,
  subroutes,
  ...props
}: Readonly<
  RouteComponentProps & {
    depth: number
    subroutes?: ReactNode
    component: React.FunctionComponent<{
      location: Omit<RouteComponentProps['location'], 'state'>
      match: Omit<RouteComponentProps['match'], 'params'>
      subroutes?: ReactNode
    }>
  }
>): ReactElement => {
  const manager = useRef(
    new AnimationStackModel<ModalConfig>('Modal Manager', {
      stack: new Map(),
      fromLast: true,
    })
  )

  const page = usePageContext(
    props.history,
    manager.current,
    props.location,
    props.depth
  )
  const spage = usePageStaticContext()

  const {
    location,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    history, // take history out of ...rest
    match: { params, ...match },
    ...rest
  } = props

  const { state, ...others } = location

  return (
    <PageContext.Provider value={page}>
      <PageStaticContext.Provider value={spage}>
        <ModalPart manager={manager.current} />
        <Component
          location={others}
          subroutes={subroutes}
          match={match}
          {...rest}
          {...state}
          {...params}
        />
      </PageStaticContext.Provider>
    </PageContext.Provider>
  )
}
