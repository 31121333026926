import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  shadow: {
    borderRadius: 8,
  },

  container: {
    background: Colors.white,
    borderRadius: 8,
    flexShrink: 1,
    padding: [Sizes.margin.thick, Sizes.margin.default],
    // maxHeight: '90%',
  },

  text: {
    marginTop: Sizes.margin.compact,
  },
})
