// import logger from 'js-logger'
import { Logger } from '../libs/com'

const COLORS = [
  '#6BDEDE',
  '#A4EC41',
  '#D95232',
  '#D9A202',
  '#AA3242',
  '#CEC56F',
  '#2838FE',
]

export class BaseClass {
  protected name: string
  protected info: (...args: unknown[]) => void
  protected warn: (...args: unknown[]) => void
  protected error: (...args: unknown[]) => void
  protected log: (...args: unknown[]) => void

  constructor(name: string) {
    this.name = name
    const color = COLORS.shift() as string
    const logger = Logger.create(name, color)

    COLORS.push(color)

    this.error = logger.error
    this.warn = logger.warn
    this.info = logger.info
    this.log = logger.log
  }
}
