/* eslint-disable prettier/prettier */
import { StyleSheet as SS } from 'jss'
import React, { useContext, useRef } from 'react'
import { Classes, GA, StyleSheet } from '../../../libs/com'
import { AppContext } from '../../../contexts'
import { useRefs } from '../../../hooks'
import { CommonHelper } from '../../../helpers'
import { Align, StyleAttribute, Transform } from '../../../types'

import Styles from './styles'

type Weights =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black'
type Element =
  | 'p'
  | 'span'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'li'
  | 'ul'
  | 'ol'
type SizeConfig<Weight> = {
  fontSize: number
  fontWeight: Weight
  lineHeight: number
  letterSpacing?: number
}

const weightMap: {
  [k in Weights]: number
} = {
  thin: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
}

function align(a?: Align) {
  switch (a) {
    case 'left':
      return Styles.left
    case 'center':
      return Styles.center
    case 'right':
      return Styles.right
    case 'justify':
      return Styles.justify
    default:
      return false
  }
}

function transform(t?: Transform) {
  switch (t) {
    case 'capitalize':
      return Styles.capitalize
    case 'lowercase':
      return Styles.lower
    case 'uppercase':
      return Styles.upper
    default:
      return false
  }
}

export function TextBlockFactory<
  W extends Weights[],
  F extends {
    [family: string]: {
      family: string
      weights: W
      sizes: {
        [k: string]: SizeConfig<F[keyof F]['weights'][number]>
      }
    }
  },
  D extends {
    family: keyof F
  } & {
    [f in keyof F]: {
      weight: F[f]['weights'][number]
      size: keyof F[f]['sizes']
    }
  },
  M extends {
    [f in keyof F]: {
      [K in keyof F[f]['sizes']]?: keyof F[f]['sizes']
    }
  }
>(config: F, defaults: D, mapping?: M) {
  // Generate font family style sheet
  const FamilyStyles = StyleSheet.create(
    CommonHelper.object
      .keys(config)
      .map((family) => {
        return {
          [family]: {
            fontFamily: config[family].family,
          },
        }
      })
      .reduce(CommonHelper.reduce.object, {})
  ) as Classes<SS<keyof F>>

  // Generate stylesheet for each family sizes
  const SizeStyles = CommonHelper.object
    .keys(config)
    .map((family) => {
      return {
        [family]: StyleSheet.create(
          CommonHelper.object
            .keys(config[family].sizes)
            .map((size) => {
              return {
                [size]: {
                  ...config[family].sizes[size],
                  fontWeight: weightMap[config[family].sizes[size].fontWeight],
                },
              }
            })
            .reduce(CommonHelper.reduce.object, {})
        ),
      }
    })
    .reduce(CommonHelper.reduce.object, {}) as {
    [k in keyof F]: Classes<SS<keyof F[k]['sizes']>>
  }

  const WeightStyles = StyleSheet.create({
    thin: {
      fontWeight: 100,
    },

    extralight: {
      fontWeight: 200,
    },

    light: {
      fontWeight: 300,
    },

    normal: {
      fontWeight: 400,
    },

    medium: {
      fontWeight: 500,
    },

    semibold: {
      fontWeight: 600,
    },

    bold: {
      fontWeight: 700,
    },

    extrabold: {
      fontWeight: 800,
    },

    black: {
      fontWeight: 900,
    },
  })

  type FamilyProps =
    | {
        // readonly family?: keyof F
        readonly family?: never
        readonly weight?: F[D['family']]['weights'][number]
        readonly size?: keyof F[D['family']]['sizes']
      }
    | {
        [k in keyof F]: {
          readonly family: k
          readonly weight?: F[k]['weights'][number]
          readonly size?: keyof F[k]['sizes']
        }
      }[keyof F]

  type ElementProps =
    | {
        readonly element?: Element
      }
    | {
        readonly element: 'a'
        readonly href?: string
        readonly rel?: string
        readonly target?: 'self' | 'parent' | 'top' | 'blank'
      }

  type Props = FamilyProps &
    ElementProps & {
      readonly underline?: boolean
      readonly italic?: boolean
      readonly mobile?: boolean
      readonly color?: string
      // readonly element?: Element
      readonly align?: Align
      readonly ellipsis?: boolean
      readonly lines?: number
      readonly transform?: 'capitalize' | 'uppercase' | 'lowercase'
      readonly selectable?: boolean
      readonly stopPropagation?: boolean
      readonly tracker?: string
      readonly download?: string
      readonly trackerParam?: Record<string, string>
      readonly onPress?: (
        e: React.MouseEvent<
          | HTMLParagraphElement
          | HTMLAnchorElement
          | HTMLLIElement
          | HTMLUListElement
          | HTMLOListElement
        >
      ) => void
      readonly children?: React.ReactNode | React.ReactNode[]
      readonly innerHTML?: string
      readonly style?: StyleAttribute
    }

  // eslint-disable-next-line react/display-name
  return React.forwardRef<
    | HTMLParagraphElement
    | HTMLHeadingElement
    | HTMLOListElement
    | HTMLUListElement
    | HTMLLIElement
    | HTMLAnchorElement
    | HTMLAnchorElement,
    Props
  >((props, ref) => {
    const app = useContext(AppContext)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const family: keyof F = (props as any).family ?? defaults.family
    // We may not give default to weight
    const weight = props.weight
    const selectable = props.selectable ?? true
    const lines = props.lines ?? 1
    // We may not give default to size
    let size: keyof F[keyof F]['sizes'] | undefined = props.size

    if (
      size &&
      mapping &&
      ((props.mobile !== false && app.media.smaller('medium')) || props.mobile)
    ) {
      // Map size to mobile size *IF*
      // - Size is defined, because we do not want the children
      // of the element to suddenly change size if it's not defined
      // - Mapping is defined
      // - props.mobile IS NOT false, because when it does,
      // it should be applying the desktop version
      // - app.media is smaller than ...
      size = mapping[family][size] ?? size
    }

    const el = useRefs(
      useRef<
        | HTMLParagraphElement
        | HTMLHeadingElement
        | HTMLOListElement
        | HTMLUListElement
        | HTMLLIElement
        | HTMLAnchorElement
        | HTMLAnchorElement
      >(null),
      ref
    )

    const stopPropagationHandler = props.stopPropagation
      ? {
          onClick: (
            e: React.MouseEvent<
              | HTMLParagraphElement
              | HTMLAnchorElement
              | HTMLLIElement
              | HTMLUListElement
              | HTMLOListElement
            >
          ) => {
            e.stopPropagation()

            if (props.onPress) {
              if (props.tracker) GA.sendClick(props.tracker, props.trackerParam)

              props.onPress(e)
            }
          },
          onPointerDown: (
            e: React.PointerEvent<
              | HTMLParagraphElement
              | HTMLAnchorElement
              | HTMLLIElement
              | HTMLUListElement
              | HTMLOListElement
            >
          ) => {
            e.stopPropagation()
          },
          onPointerUp: (
            e: React.PointerEvent<
              | HTMLParagraphElement
              | HTMLAnchorElement
              | HTMLLIElement
              | HTMLUListElement
              | HTMLOListElement
            >
          ) => {
            e.stopPropagation()
          },
        }
      : {
          onClick: props.onPress
            ? (
                e: React.MouseEvent<
                  | HTMLParagraphElement
                  | HTMLAnchorElement
                  | HTMLLIElement
                  | HTMLUListElement
                  | HTMLOListElement
                >
              ) => {
                if (props.tracker)
                  GA.sendClick(props.tracker, props.trackerParam)
                props.onPress?.(e)
              }
            : props.tracker
            ? () => {
                // Send tracker even if it does not have onPress but does have tracker
                GA.sendClick(props.tracker as string, props.trackerParam)
              }
            : undefined,
        }

    const { className, style } = StyleSheet.classNameAndStyle([
      Styles.text,
      !selectable && Styles.unselectable,
      FamilyStyles[family],
      size && SizeStyles[family][size],
      weight && WeightStyles[weight],
      align(props.align),
      transform(props.transform),
      props.color && {
        color: props.color,
      },
      props.underline && Styles.underline,
      props.italic && Styles.italic,
      props.onPress && Styles.clickable,
      props.ellipsis && [
        Styles.ellipsis,
        lines > 1
          ? [
              Styles.multilineEllipsis,
              {
                WebkitLineClamp: lines,
              },
            ]
          : false,
      ],
      props.style,
    ])

    const finalProps = {
      className,
      style,
      children: props.children,
      ...(props.innerHTML
        ? {
            dangerouslySetInnerHTML: {
              __html: props.innerHTML,
            },
          }
        : {}),
      ...stopPropagationHandler,
    }

    return React.createElement(props.element ?? 'p', {
      ref: el,
      ...finalProps,
      ...(props.element === 'a'
        ? {
            href: props.href,
            rel: props.rel,
            target: props.target ? `_${props.target}` : undefined,
            download: props.download,
          }
        : {}),
    })
  })
}
