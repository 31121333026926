import { StyleSheet } from '../../../libs/com'
import { Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    borderRadius: 8,
    alignSelf: 'flex-start',
  },

  hidden: {
    opacity: 0,
  },

  title: {
    margin: [0, Sizes.margin.pad],
  },

  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
})
