import { StyleSheet } from '../../../libs/com'
import { Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    // We want this to be the very top
    zIndex: 999,
    overflow: 'hidden',
  },

  content: {
    borderRadius: 8,
    padding: [Sizes.margin.compact, Sizes.margin.default],
  },

  message: {
    paddingRight: Sizes.margin.default,
  },

  notifier: {
    borderRadius: 8,
    width: `calc(100% - ${Sizes.margin.thick * 2})`,
    maxWidth: 480,
    alignSelf: 'flex-end',
    margin: [0, Sizes.margin.thick, Sizes.margin.compact],
  },

  mobile: {
    maxWidth: 'unset',
    alignSelf: 'stretch',
  },
})
