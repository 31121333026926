import { STATE } from '../../constants'

export const StateHelper = {
  sumState(states: STATE[]): STATE {
    return (
      states.reduce((final, state) => {
        if (final === STATE.ERROR) {
          return final
        } else {
          if (state === STATE.ERROR) {
            return STATE.ERROR
          } else if (state === STATE.VALID && (final === STATE.VALID || final === undefined)) {
            return STATE.VALID
          } else {
            return STATE.DEFAULT
          }
        }
      }, undefined as STATE | undefined) ?? STATE.DEFAULT
    )
  },
}
