import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.thick + Sizes.margin.pad,
    paddingRight: Sizes.margin.thick + Sizes.margin.pad,
  },
  normal: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.black,
    },
  },
  active: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('grey')[4],
    },
  },
  disabled: {
    foreground: Colors.palette('grey')[2],
    style: {
      backgroundColor: Colors.palette('grey')[1],
    },
  },
  loading: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('grey')[4],
    },
  },
}
