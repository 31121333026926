import { Placement } from '@popperjs/core'
import React, { useCallback, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Text } from '../text'
import { Box } from '../box'
import Styles from './styles'
import { Icon } from '../icon'
import { Colors } from '../../../constants'

export function Tooltip(props: {
  readonly message: string | React.ReactNode
  readonly placement?: Placement
  readonly children?: React.ReactNode
  readonly config?: {
    theme: 'default' | 'dark'
    size: 'default' | 'large'
  }
}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  // const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: props.placement ?? 'top',
    }
  )
  const [isActive, setActive] = useState(false)

  const show = useCallback(() => {
    setActive(true)

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    if (update) {
      update()
    }
  }, [update])

  const hide = useCallback(() => {
    setActive(false)
  }, [])

  const showEvents = ['mouseenter', 'focus']
  const hideEvents = ['mouseleave', 'blur']

  useEffect(() => {
    showEvents.forEach((event) => {
      referenceElement?.addEventListener(event, show)
    })

    hideEvents.forEach((event) => {
      referenceElement?.addEventListener(event, hide)
    })

    return () => {
      showEvents.forEach((event) => {
        referenceElement?.removeEventListener(event, show)
      })

      hideEvents.forEach((event) => {
        referenceElement?.removeEventListener(event, hide)
      })
    }
  })

  return (
    <>
      <Box flex ref={setReferenceElement}>
        {props.children}
      </Box>

      {isActive && (
        <Box
          ref={setPopperElement}
          style={[
            styles.popper,
            Styles.tooltip,
            props.config?.theme === 'dark' && Styles.dark,
            props.config?.size === 'large' && Styles.large,
          ]}
          {...attributes.popper}
        >
          <Box row centering="v">
            {props.config?.theme !== 'dark' && (
              <Box flex style={Styles.icon}>
                <Icon name="info" color={Colors.palette('grey')[3]} />
              </Box>
            )}
            {typeof props.message === 'string' ? (
              <Text style={Styles.message} size="tiny">
                {props.message}
              </Text>
            ) : (
              props.message
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
