import { Input } from '../../../constants'
import { ButtonBlockFactory } from './factory'

import PrimaryTheme from './themes/primary'
import SecondaryTheme from './themes/secondary'
import WarningTheme from './themes/warning'
import TextTheme from './themes/text'
import TextPrimaryTheme from './themes/text.primary'
import BorderTextTheme from './themes/border.text'
import BlackTheme from './themes/black'
import BorderTextSecondaryTheme from './themes/border.text.secondary'
import BorderTextErrorTheme from './themes/border.text.error'
import TextErrorBorderTheme from './themes/text.error.border'
import WhiteTheme from './themes/white'
import PrimaryNewTheme from './themes/primary.new'

export const Button = ButtonBlockFactory(
  {
    primary: PrimaryTheme,
    secondary: SecondaryTheme,
    warning: WarningTheme,
    text: TextTheme,
    'text-primary': TextPrimaryTheme,
    'border-text': BorderTextTheme,
    black: BlackTheme,
    white: WhiteTheme,
    'border-text-secondary': BorderTextSecondaryTheme,
    'border-text-error': BorderTextErrorTheme,
    'text-error-border': TextErrorBorderTheme,
    'primary-new': PrimaryNewTheme,
  },
  {
    regularBig: {
      height: 56,
      text: 'regular',
      weight: 'normal',
    },
    big: {
      height: 48,
      text: 'large',
      weight: 'bold',
    },
    default: {
      height: 44,
      text: 'regular',
      weight: 'bold',
    },
    input: {
      height: Input.height,
      text: 'regular',
      weight: 'bold',
    },
    small: {
      height: 30,
      text: 'small',
      weight: 'bold',
    },
    smallBig: {
      height: 54,
      text: 'small',
      weight: 'bold',
    },
  },
  {
    family: 'sans',
    size: 'default',
    theme: 'primary',
  }
)
