import React, { useContext } from 'react'
import { StyleSheet } from '../../../libs/com'
import { AppContext } from '../../../contexts'
import { CommonHelper } from '../../../helpers'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'

type Size = React.ContextType<typeof AppContext>['media']['size']

const style = CommonHelper.fn.memoize((width?: number | string, height?: number | string) => {
  return StyleSheet.create({
    pad: CommonHelper.object.defined({
      width,
      height,
    }),
  }).pad
})

export function Padder(props: {
  readonly width?: number | string
  readonly height?: number | string
  readonly is?: Size[] | Size
  readonly larger?: Size
  readonly smaller?: Size
  readonly pad?: boolean
  readonly flex?: boolean
  readonly style?: StyleAttribute
}) {
  const app = useContext(AppContext)
  const pad =
    props.pad ??
    ((props.is && (Array.isArray(props.is) ? app.media.is(...props.is) : app.media.is(props.is))) ||
      (props.larger && app.media.larger(props.larger)) ||
      (props.smaller && app.media.smaller(props.smaller)) ||
      false)

  return pad ? <Box flex={props.flex} style={[style(props.width, props.height), props.style]} /> : null
}
