import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  container: {
    height: 4,
  },

  bar: {
    transitionProperty: 'width',
    transitionTimingFunction: 'cubic-bezier(0.38, 0, 0.15, 1)',
  },
})
