import React, { ReactNode } from 'react'
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Async } from '../../../modules/blocks/async'
import { PageUtilities } from '../../../modules/utilities/page'

type RouteConfig = {
  type?: 'route'
  component: React.FunctionComponent<
    RouteComponentProps & {
      readonly depth: number
    }
  >
  exact?: boolean
  path?: string
}

type RedirectConfig = {
  type: 'redirect'
  exact?: boolean
  from?: string
  to: string
}

type RouterConfig = RouteConfig | RedirectConfig

const Switcher = (config: RouterConfig[], depth: number) => {
  return (
    <Switch>
      {config.map((c, i) => {
        switch (c.type) {
          case 'redirect':
            return (
              <Redirect
                key={i}
                exact={c.exact}
                from={c.from}
                to={{
                  pathname: c.to,
                  state: {
                    referrer: window?.location?.pathname,
                  },
                }}
              />
            )
          case 'route':
          default: {
            const Component = c.component
            return (
              <Route
                key={i}
                exact={c.exact}
                render={(p) => <Component {...p} depth={depth} />}
                path={c.path}
              />
            )
          }
        }
      })}
    </Switch>
  )
}

export const Router = {
  Root(config: RouterConfig[]) {
    return <BrowserRouter>{Switcher(config, 0)}</BrowserRouter>
  },
  // Providing easy access to loadability
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Page(module: () => Promise<{ default: React.FunctionComponent<any> }>, subroutes?: RouterConfig[]) {
    let _depth = 0
    let _branch: ReactNode | undefined = undefined

    function createBranch(depth: number) {
      if (depth !== _depth || !_branch) {
        _depth = depth
        _branch = subroutes ? Switcher(subroutes, _depth) : undefined
      }

      return _branch
    }

    const Component: React.FunctionComponent = (pr) => <Async props={pr} component={module} />

    return function PageRoute(
      props: RouteComponentProps & {
        readonly depth: number
      }
    ) {
      return (
        <PageUtilities
          history={props.history}
          location={props.location}
          match={props.match}
          depth={props.depth}
          component={Component}
          subroutes={createBranch(props.depth)}
        />
      )
    } as React.FunctionComponent<
      RouteComponentProps & {
        readonly depth: number
      }
    >
  },
}
