import React, { useEffect, useState } from 'react'
import { StyleSheet } from '../../../libs/com'
import { Colors } from '../../../constants'
import {
  StyleAttribute,
  IconName,
  IconPathAttributes,
  SVGPathAttributes,
} from '../../../types'

import { Box } from '../box'
import { Padder } from '../padder'

export const Icon = (props: {
  readonly name: IconName
  readonly size?: number
  readonly color?: string
  readonly flex?: boolean
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onPress?: () => void
  readonly style?: StyleAttribute
  readonly svgStyle?: StyleAttribute
  readonly outlined?: boolean
  readonly stroke?: string
  readonly testId?: string
}) => {
  const size = props.size ?? 24
  const [loading, setLoading] = useState(true)
  const [{ viewbox, path }, setConfig] = useState<{
    viewbox?: string
    path?: IconPathAttributes | IconPathAttributes[]
  }>({})

  const getPathAttrs = (attrs: IconPathAttributes): SVGPathAttributes => {
    return {
      stroke: attrs.stroke,
      strokeWidth: attrs['stroke-width'],
      strokeLinecap: attrs['stroke-linecap'],
      strokeLinejoin: attrs['stroke-linejoin'],
    }
  }

  useEffect(() => {
    setLoading(true)
    import('./svg.config/' + props.name + '.json')
      .then((res) => res.default)
      .then(setConfig)
      .then(() => setLoading(false))

    return () => setLoading(false)
  }, [props.name])

  return (
    <Box
      centering
      flex={props.flex}
      tracker={props.tracker}
      trackerParam={props.trackerParam}
      onPress={props.onPress}
      style={props.style}
      testId={props.testId}
    >
      {loading ? (
        <Padder pad width={size} height={size} />
      ) : (
        <svg
          viewBox={viewbox}
          height={size}
          fill={props.outlined ? 'none' : props.color ?? Colors.black}
          {...(props.svgStyle
            ? StyleSheet.classNameAndStyle(props.svgStyle)
            : {})}
          stroke={props.stroke ?? ''}
        >
          {Array.isArray(path)
            ? path.map((p, i) => {
                return (
                  <path
                    key={i}
                    d={p.d}
                    {...(props.outlined && getPathAttrs(p))}
                  />
                )
              })
            : !!path && (
                <path d={path.d} {...(props.outlined && getPathAttrs(path))} />
              )}
        </svg>
      )}
    </Box>
  )
}
