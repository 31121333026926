import React, { ComponentProps, ReactNode } from 'react'
import { CommonHelper } from '../../../helpers'
import { Colors, STATE } from '../../../constants'
import { StyleAttribute } from '../../../types'

import { Box } from '../../blocks/box'
import { InputCalendar } from '../../blocks/input.calendar'
import { InputTime } from '../../blocks/input.time'
import { InputDate } from '../../blocks/input.date'
import { InputDuration } from '../../blocks/input.duration'
import { InputFile } from '../../blocks/input.file'
import { Loader } from '../../blocks/loader'
import { Select } from '../../blocks/select'
import { Textarea } from '../../blocks/textarea'
import { Text } from '../../blocks/text'

import { State } from './__helper'
import { ContainerPart } from './_container'
import { FormattedPart } from './_formatted'

import Styles from './styles'

type MakeInputProps<
  T extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any
  }
> = Omit<
  {
    [key in keyof T]: T[key]
  },
  'valid'
> & {
  validation?: (
    ...args: Parameters<Exclude<T['onChange'], undefined>>
  ) => State | null | Promise<State | null>
}

type TextProps = MakeInputProps<ComponentProps<typeof FormattedPart>>

type DateProps = {
  type: 'date'
} & MakeInputProps<ComponentProps<typeof InputDate>>

type CalendarProps = {
  type: 'calendar'
} & MakeInputProps<ComponentProps<typeof InputCalendar>>

// We want to make sure that input component is a controlled input
type TextareaProps = Omit<
  {
    type: 'textarea'
  } & MakeInputProps<ComponentProps<typeof Textarea>>,
  'defaultValue'
>

type FileProps = {
  type: 'file'
} & MakeInputProps<ComponentProps<typeof InputFile>>

type DurationProps = {
  type: 'duration'
} & MakeInputProps<ComponentProps<typeof InputDuration>>

type SelectProps = {
  type: 'select'
} & MakeInputProps<ComponentProps<typeof Select>>

type TimeProps = {
  type: 'time'
} & MakeInputProps<ComponentProps<typeof InputTime>>

type InputProps =
  | DateProps
  | TextareaProps
  | TextProps
  | SelectProps
  | CalendarProps
  | FileProps
  | DurationProps
  | TimeProps

function Loading(props: { readonly style?: StyleAttribute }) {
  return (
    <Box centering style={[Styles.loader, props.style]}>
      <Loader size={20} color={Colors.palette('grey')[2]} />
    </Box>
  )
}

export type Props = InputProps & {
  readonly flex?: boolean
  readonly label?: string
  readonly labelWeight?: React.ComponentProps<typeof Text>['weight']
  readonly loading?: boolean
  readonly sublabel?: string
  readonly asterisk?: boolean
  readonly description?:
    | string
    | (State & {
        style?: StyleAttribute
      })
    | ReactNode
  readonly hideStates?: boolean
  readonly readonly?: boolean
  readonly disabled?: boolean
  readonly testId?: string
  readonly state?: STATE
  readonly states?: State[]
  readonly style?: StyleAttribute
  readonly container?: StyleAttribute
  readonly boxStyle?: boolean
}

export const StatedPart = (props: Props) => {
  const {
    flex,
    label,
    labelWeight,
    sublabel,
    asterisk,
    loading,
    description,
    hideStates,
    readonly,
    disabled,
    state,
    states = CommonHelper.defaults.array,
    style,
    container,
    boxStyle,
    ...otherProps
  } = props

  return (
    <ContainerPart
      fit={props.fit}
      flex={flex}
      label={label}
      labelWeight={labelWeight}
      sublabel={sublabel}
      asterisk={asterisk}
      loading={loading}
      description={description}
      hideStates={hideStates}
      readonly={readonly}
      disabled={disabled}
      states={states}
      style={style}
      boxStyle={boxStyle}
      state={state}
    >
      {props.type === 'date' ? (
        <InputDate
          {...(otherProps as React.ComponentProps<typeof InputDate>)}
          suffix={loading ? <Loading /> : props.suffix}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={[Styles.input, container]}
        />
      ) : props.type === 'calendar' ? (
        <InputCalendar
          {...(otherProps as React.ComponentProps<typeof InputCalendar>)}
          suffix={loading ? <Loading /> : props.suffix}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={[Styles.input, container]}
        />
      ) : props.type === 'time' ? (
        <InputTime
          {...(otherProps as React.ComponentProps<typeof InputTime>)}
          suffix={loading ? <Loading /> : props.suffix}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={[Styles.input, container]}
        />
      ) : props.type === 'select' ? (
        <Select
          {...(otherProps as React.ComponentProps<typeof Select>)}
          suffix={loading ? <Loading /> : props.suffix}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={
            boxStyle
              ? [Styles.input, Styles.box, disabled && Styles.inputDisabled]
              : [Styles.input, container]
          }
        />
      ) : props.type === 'textarea' ? (
        <Textarea
          {...(otherProps as React.ComponentProps<typeof Textarea>)}
          suffix={
            loading ? <Loading style={Styles.textareaLoader} /> : props.suffix
          }
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={[Styles.input, container]}
        />
      ) : props.type === 'file' ? (
        <InputFile
          {...(otherProps as React.ComponentProps<typeof InputFile>)}
          suffix={loading ? <Loading /> : props.suffix}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={[Styles.input, container]}
        />
      ) : props.type === 'duration' ? (
        <InputDuration
          {...(otherProps as React.ComponentProps<typeof InputDuration>)}
          suffix={loading ? <Loading /> : props.suffix}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          style={[Styles.input, container]}
        />
      ) : (
        <FormattedPart
          {...(otherProps as React.ComponentProps<typeof FormattedPart>)}
          suffix={loading ? <Loading /> : props.suffix}
          clearable={(props as Props & { type: 'text' }).clearable ?? true}
          readonly={readonly}
          disabled={disabled}
          valid={state !== STATE.ERROR}
          asterisk={asterisk}
          input={Styles.inputBox}
          style={
            boxStyle
              ? [Styles.input, Styles.box, disabled && Styles.inputDisabled]
              : [Styles.input, container]
          }
        />
      )}
    </ContainerPart>
  )
}
