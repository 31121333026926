import { Logger } from '../libs/com/logger'

let sans = 'Plus Jakarta Sans, sans-serif'
let serif = 'serif'

export const Fonts = Object.freeze({
  get sans() {
    return sans
  },
  set sans(c: string) {
    Logger.log('INFO', 'Setting sans font:', c)
    sans = c
  },

  get serif() {
    return serif
  },
  set serif(c: string) {
    Logger.log('INFO', 'Setting serif font:', c)
    serif = c
  },
})
