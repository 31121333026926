import { AppContextFactory } from './_factory'

export const { AppContext, useAppContextMediaQuery, useAppContextOverlayCounter } = AppContextFactory({
  mini: ['max', 320],
  small: ['max', 767],
  medium: ['min', 768],
  tablet: ['min', 1024],
  default: ['min', 1280],
  large: ['min', 1680],
})
