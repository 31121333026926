import React, { ReactNode } from 'react'

type Props = {
  readonly error?: ReactNode
  readonly children: ReactNode
}

type State = {
  readonly error: boolean
}

export class Error extends React.Component<Props, State> {
  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { error: true }
  }

  constructor(p: Props) {
    super(p)
    this.state = {
      error: false,
    }
  }

  componentDidCatch(): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render(): ReactNode {
    return this.state.error ? this.props.error ?? null : this.props.children
  }
}
