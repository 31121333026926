/* eslint-disable prettier/prettier */
import { CommonHelper } from '../../helpers'
import { Palettes } from '../../types'

import GreyPalette from './palettes/grey'
import RedPalette from './palettes/red'
import SalmonPalette from './palettes/salmon'
import OrangePalette from './palettes/orange'
import PurplePalette from './palettes/purple'
import TurquoisePalette from './palettes/turquoise'
import BluePalette from './palettes/blue'
import GreenPalette from './palettes/green'
import YellowPalette from './palettes/yellow'

const palettes: {
  [key in Palettes]: string[]
} = {
  blue: BluePalette,
  green: GreenPalette,
  grey: GreyPalette,
  orange: OrangePalette,
  purple: PurplePalette,
  red: RedPalette,
  salmon: SalmonPalette,
  turquoise: TurquoisePalette,
  yellow: YellowPalette,
}

export const Colors = Object.freeze({
  primary: '#0087C8',

  error: '#E60000',

  success: '#00A175',

  black: '#000000',

  white: '#FFFFFF',

  border: '#A0A4A8',

  placeholder: '#CACCCF',

  transparent: 'transparent',

  primaryNew: '#0B5FEF',

  text: '#2F3031',
  /**
   * @description		Get color palette
   * @param palette	Palette name.
   */
  palette(palette: Palettes) {
    // TODO: lazy load colors
    // if (!palettes[palette].length) {
    // 	Logger.log('INFO', `Calling a possibly uninitialised ${ palette } palette`)
    // }
    return palettes[palette]
  },

  // TODO: lazy load colors
  // /**
  //  * @description		Initialise color palette so that it can be used in the app
  //  * 					Call this method on app start before everything else loaded
  //  * @param palette	Palette to be initialized.
  //  */
  // async init(palette: Palettes) {
  // 	return import(`./palettes/${ palette }`).then(res => {
  // 		palettes[palette] = res?.default ?? []
  // 		Logger.log('INFO', `Color palette: ${ palette } initialized`, palettes[palette].join(', '))

  // 		return true
  // 	})
  // },

  /**
   * @description		Generate color opacity in hex from number (0 - 1)
   * @param color		Base color.
   * @param alpha		Should be a number between 0 - 1
   */
  opacity: CommonHelper.fn.memoize((color: string, alpha: number) => {
    return `${color}${Math.floor(alpha * 255)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0')}`
  }),
})
