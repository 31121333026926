/* eslint-disable prettier/prettier */
import type { FirebaseOptions } from 'firebase/app'
import { Logger } from '../libs/com/logger'
import { Lang } from '../types'

function detectWebpSupport() {
  const elem = document.createElement('canvas')
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  } else {
    // very old browser like IE 8, canvas not supported
    return false
  }
}

const YEAR = new Date().getFullYear()
const WEBP_SUPPORT = detectWebpSupport()
let LANG: Lang = 'en'
let TITLE = 'Recore App'
let DEBUG = true
let DEBUG_LEVEL: 1 | 2 | 3 | 4 = 1 // 1: error, 2: warn, 3: info, 4: debugging
let STORE_KEY = '@key' // Storage key
let SERVICE_TIMEOUT = 60 * 5 * 1000 // Default to 5 minutes
let SERVICE_CACHE: RequestCache = 'default' // Service cache
let LOADER_DELAY_DURATION = 300 // Loader delay duration before shown to user
let GA_ID = ''
let FIREBASE_CONFIG: FirebaseOptions
let FIREBASE_NO_CACHE: boolean

let IMAGE_RESOLVER: (folder: string) => Promise<{ default: string } | null> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  folder
) => {
  Logger.log('ERROR', "Image resolver isn't set yet!")
  return Promise.resolve(null)
}

let LANG_RESOLVER: (
  folder: string
) => Promise<{ default: Record<string, string> } | null> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  folder
) => {
  Logger.log('ERROR', "Language resolver isn't set yet!")
  return Promise.resolve(null)
}

export const Defaults = {
  get TITLE() {
    return TITLE
  },
  set TITLE(title: string) {
    Logger.log('INFO', 'Setting TITLE:', title)
    TITLE = title
  },

  get LANG() {
    return LANG
  },

  set LANG(lang: Lang) {
    Logger.log('INFO', 'Setting LANG:', lang)
    LANG = lang
  },

  get DEBUG() {
    return DEBUG
  },
  set DEBUG(debug: boolean) {
    Logger.log('INFO', 'Setting DEBUG:', debug)
    DEBUG = debug
  },

  get DEBUG_LEVEL() {
    return DEBUG_LEVEL
  },
  set DEBUG_LEVEL(level: 1 | 2 | 3 | 4) {
    Logger.log('INFO', 'Setting DEBUG_LEVEL:', level)
    DEBUG_LEVEL = level
  },

  get STORE_KEY() {
    return STORE_KEY
  },
  set STORE_KEY(key: string) {
    Logger.log('INFO', 'Setting STORE_KEY:', key)
    STORE_KEY = key
  },

  get SERVICE_TIMEOUT() {
    return SERVICE_TIMEOUT
  },
  set SERVICE_TIMEOUT(timeout: number) {
    Logger.log('INFO', 'Setting SERVICE_TIMEOUT:', timeout)
    SERVICE_TIMEOUT = timeout
  },

  get SERVICE_CACHE() {
    return SERVICE_CACHE
  },
  set SERVICE_CACHE(cache: RequestCache) {
    Logger.log('INFO', 'Setting SERVICE_CACHE:', cache)
    SERVICE_CACHE = cache
  },

  get LOADER_DELAY_DURATION() {
    return LOADER_DELAY_DURATION
  },
  set LOADER_DELAY_DURATION(timeout: number) {
    Logger.log('INFO', 'Setting LOADER_DELAY_DURATION:', timeout)
    LOADER_DELAY_DURATION = timeout
  },

  get IMAGE_RESOLVER() {
    return IMAGE_RESOLVER
  },
  set IMAGE_RESOLVER(resolver: typeof IMAGE_RESOLVER) {
    Logger.log('INFO', 'Setting IMAGE_RESOLVER')

    IMAGE_RESOLVER = resolver
  },

  get LANG_RESOLVER() {
    return LANG_RESOLVER
  },
  set LANG_RESOLVER(resolver: typeof LANG_RESOLVER) {
    Logger.log('INFO', 'Setting LANG_RESOLVER')

    LANG_RESOLVER = resolver
  },

  get FIREBASE_CONFIG() {
    return FIREBASE_CONFIG
  },

  set FIREBASE_CONFIG(config: FirebaseOptions) {
    Logger.log('INFO', 'Setting firebase config:', config)

    FIREBASE_CONFIG = config
  },

  get FIREBASE_NO_CACHE() {
    return FIREBASE_NO_CACHE
  },

  set FIREBASE_NO_CACHE(cache: boolean) {
    FIREBASE_NO_CACHE = cache
  },

  /**
   * @deprecated use measurementId in FIREBASE_CONFIG instead
   */
  get GA_ID() {
    return GA_ID
  },
  /**
   * @deprecated use measurementId in FIREBASE_CONFIG instead
   */
  set GA_ID(id: string) {
    Logger.log('INFO', 'Set GA ID', id)
    GA_ID = id
  },

  get WEBP_SUPPORT() {
    return WEBP_SUPPORT
  },

  get YEAR() {
    return YEAR
  },
}
