import React, { ComponentProps, ReactNode, useCallback, useContext, useRef, useState } from 'react'
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal'
import { useComponentId } from '../../../hooks'
import { PageStaticContext } from '../../../contexts'

import { Box } from '../../../modules'

export function PageSticky({
  bottom,
  style: propStyle,
  ...props
}: Omit<ComponentProps<typeof Box>, 'onLayout' | 'children' | 'ref'> & {
  readonly bottom?: boolean
  readonly offset?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  readonly children: ReactNode
}) {
  const id = useComponentId()
  const spage = useContext(PageStaticContext)
  const ref = useRef(createHtmlPortalNode())
  const style = useRef([
    propStyle,
    props.offset?.top
      ? {
          paddingTop: props.offset.top,
        }
      : undefined,
    props.offset?.bottom
      ? {
          paddingBottom: props.offset.bottom,
        }
      : undefined,
  ])
  const [rect, setRect] = useState<DOMRectReadOnly>()
  const [isSticking, setIsSticking] = useState(false)

  const setLayout = useCallback((r: DOMRectReadOnly) => {
    setRect(r)
  }, [])

  const refSetter = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        const r = node.getBoundingClientRect()

        spage.stickies.set(id, {
          el: node,
          bottom: !!bottom,
          portal: ref.current,
          offset: {
            left: props.offset?.left ?? r.left,
            right: props.offset?.right ?? r.right,
          },
          isVisible(isIntersecting) {
            if (!isIntersecting) {
              setIsSticking(true)
              return true
            } else {
              setIsSticking(false)
              return false
            }
          },
        })
      }
    },
    [id, bottom, spage.stickies, props.offset]
  )

  return (
    <Box
      ref={refSetter}
      onLayout={setLayout}
      style={[
        isSticking
          ? {
              height: rect?.height,
              width: rect?.width,
            }
          : undefined,
        props.offset?.top
          ? {
              marginTop: -props.offset.top,
            }
          : undefined,
        props.offset?.bottom
          ? {
              marginBottom: -props.offset.bottom,
            }
          : undefined,
      ]}
    >
      <InPortal node={ref.current}>
        <Box {...props} style={style.current} />
      </InPortal>
      {!isSticking ? <OutPortal node={ref.current} /> : false}
    </Box>
  )
}
