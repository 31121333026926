/* eslint-disable no-fallthrough */
import debounce from 'lodash/debounce'
import memoize from 'lodash/memoize'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import DateFormat from 'dateformat'

type NoUndefined<T extends Record<string, unknown>> = Pick<
  T,
  { [t in keyof T]: T[t] extends undefined ? never : t }[keyof T]
>

function memoResolver(...p: unknown[]) {
  return p.join('|')
}

function getRange(dates?: Date[]): string
function getRange(dates: Date[], asString: false): [Date, Date]
function getRange(
  dates: Date[],
  asString: true,
  dateFormat?: string,
  monthFormat?: string,
  yearFormat?: string
): string
function getRange(
  dates: Date[] = [],
  asString = true,
  dateFormat = 'dd',
  monthFormat = 'mmm',
  yearFormat = 'yyyy'
): string | [Date, Date] {
  if (!dates.length) {
    return asString
      ? DateFormat(`${dateFormat} ${monthFormat} ${yearFormat}`)
      : (Array(2).fill(new Date()) as [Date, Date])
  }

  const earliest = dates.reduce((date, curr) => {
    return !date ? curr : +date < +curr ? date : curr
  })

  const latest = dates.reduce((date, curr) => {
    return !date ? curr : +date < +curr ? curr : date
  })

  if (asString) {
    if (earliest.getFullYear() === latest.getFullYear()) {
      if (earliest.getMonth() === latest.getMonth()) {
        if (earliest.getDate() === latest.getDate()) {
          // year and month and date is the same
          return DateFormat(
            earliest,
            `${dateFormat} ${monthFormat} ${yearFormat}`
          )
        } else {
          // year and month is the same
          return `${DateFormat(earliest, `${dateFormat}`)} - ${DateFormat(
            latest,
            `${dateFormat} ${monthFormat} ${yearFormat}`
          )}`
        }
      } else {
        // only year is the same
        return `${DateFormat(
          earliest,
          `${dateFormat} ${monthFormat}`
        )} - ${DateFormat(
          latest,
          `${dateFormat} ${monthFormat} ${yearFormat}`
        )}`
      }
    } else {
      // not same at all
      return `${DateFormat(
        earliest,
        `${dateFormat} ${monthFormat} ${yearFormat}`
      )} - ${DateFormat(latest, `${dateFormat} ${monthFormat} ${yearFormat}`)}`
    }
  } else {
    return [earliest, latest] as [Date, Date]
  }
}

export const CommonHelper = {
  reduce: {
    /**
     * @description			Shallow flatten array, to be used with Array.reduce
     */
    array: <T extends unknown>(sum: T[] = [], value: T[]) => {
      return [...sum, ...value]
    },
    /**
     * @description			Shallow flatten object, to be used with Array.reduce
     */
    object: <
      T extends {
        [k: string]: unknown
      }
    >(
      sum: T,
      value: T
    ): {
      [k in keyof T]: T[k]
    } => {
      return {
        ...sum,
        ...value,
      }
    },
    /**
     * @description			Sum array of number, to be used with Array.reduce
     */
    number: (sum = 0, value: number): number => {
      return sum + value
    },
  },

  object: {
    /**
     * @description			Strip object of any undefined values.
     * @param obj			Object to be stripped.
     */
    defined: <T extends Record<string, unknown>>(obj: T): NoUndefined<T> => {
      for (const key in obj) {
        if (obj[key] === undefined) {
          delete obj[key]
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          CommonHelper.object.defined(obj[key] as Record<string, unknown>)
        }
      }

      return obj
    },
    /**
     * @description			Get object keys with infered key type
     * 						We need this because typescript infer type from Object.keys sucks
     * @param obj			Object to be stripped.
     */
    keys<
      T extends {
        [k: string]: unknown
      }
    >(obj: T): (keyof T)[] {
      return Object.keys(obj)
    },

    /**
     * @description			Map object with a new key, why? To have the keys strongly typed
     * @param obj				Object to be mapped.
     * @param mapper		Record of <new_key: old_key> (not the other way around!)
     */
    map<
      T extends {
        [k: string]: unknown
      },
      M extends {
        [k: string]: keyof T
      }
    >(
      obj: T,
      mapper: M
    ): {
      [o in {
        [key in keyof T]: {
          [k in keyof M]: M[k] extends key ? k : never
        }[keyof M] extends never
          ? key
          : {
              [k in keyof M]: M[k] extends key ? k : never
            }[keyof M]
      }[keyof T]]: o extends keyof M
        ? T[M[o]]
        : o extends keyof T
        ? T[o]
        : never
    } {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newObj: any = {}
      const keys = new Set(CommonHelper.object.keys(obj))

      for (const key in mapper) {
        keys.delete(mapper[key])
        newObj[key] = obj[mapper[key]]
      }

      // Add rest of it
      Array.from(keys.values()).forEach((key) => {
        newObj[key] = obj[key]
      })

      return newObj
    },

    isEqual,
  },

  date: {
    /**
     * @description			Check whether supplied value is a valid date object or not
     * @param date			Date object to be checked
     */
    valid(date: unknown) {
      return date instanceof Date && !isNaN(date.getTime())
    },
    /**
     * @description 		Parse date string without timestamp i.e. 17-12-2021 into an end of day date.
     */
    toDateWithoutTimestamp(date: string) {
      const _date = new Date(date)

      _date.setHours(23, 59, 59, 999)

      return _date
    },
    format: DateFormat,
    difference(
      a: Date,
      b: Date,
      unit: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year',
      precise?: boolean | number
    ) {
      let diff = +b - +a

      // if (unit === 'month') {
      // 	let months
      // 	months = (b.getFullYear() - a.getFullYear()) * 12
      // 	months -= a.getMonth()
      // 	months += b.getMonth()

      // 	return months <= 0 ? 0 : months
      // }

      switch (unit) {
        case 'year':
          // 365 days or 12.16 * 30 days
          diff /= 12.16
        case 'month':
          // 30 days or 4.29 week
          diff /= 4.29
        case 'week':
          diff /= 7
        case 'day':
          diff /= 24
        case 'hour':
          diff /= 60
        case 'minute':
          diff /= 60
        case 'second':
          diff /= 1000
      }

      return precise === true
        ? diff
        : typeof precise === 'number'
        ? parseFloat(diff.toPrecision(precise))
        : Math.floor(diff)
    },
    getRange: getRange,
  },

  defaults: {
    object: {},
    array: [],
  },

  fn: {
    /**
     * @description			Default no-operation fn to be used as default fn
     */
    NOOP() {
      // Returns void
    },
    /**
     * @description			Function memoize from lodash. Use this instead of calling lodash directly.
     */
    memoize<P extends unknown[], R extends unknown>(
      func: (...p: P) => R,
      res?: (...p: P) => string
    ) {
      return memoize(func, res ?? memoResolver)
    },
    /**
     * @description			Function throttling from lodash. Use this instead of calling lodash directly.
     */
    throttle,
    /**
     * @description			Function debouncing from lodash. Use this instead of calling lodash directly.
     */
    debounce,
  },
}
