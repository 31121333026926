import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  circle: {
    transition: 'stroke-dashoffset 1s linear',
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})
