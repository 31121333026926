import React, { ReactNode, Suspense, useEffect, useRef, useState } from 'react'

import { Box } from '../box'
import { Loader } from '../loader'

export function Async<T extends unknown>(props: {
  readonly props?: T
  readonly delay?: number
  readonly fallback?: ReactNode
  readonly component: () => Promise<{ default: React.ComponentType<T> }>
}) {
  const componentProps: T = props.props ?? ({} as T)
  const delay = props.delay ?? 0
  const [fallback, setFallback] = useState(delay > 0 ? false : true)
  const Component = useRef(React.lazy(props.component))
  const [updater, setUpdater] = useState(0)

  useEffect(() => {
    let timeout: number
    if (delay > 0) {
      timeout = window.setTimeout(() => setFallback(true), delay)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [delay])

  useEffect(() => {
    Component.current = React.lazy(props.component)
    setUpdater((u) => u + 1)
  }, [props.component])

  return (
    <Suspense
      fallback={
        fallback
          ? props.fallback ?? (
              <Box centering flex>
                <Loader />
              </Box>
            )
          : null
      }
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Component.current key={updater} {...(componentProps as any)} />
    </Suspense>
  )
}
