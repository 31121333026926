import React, { useCallback, useLayoutEffect, useState } from 'react'
import { GA } from '../../../libs/com'
import { useStateEffect, useUpdate } from '../../../hooks'
import { CommonHelper } from '../../..//helpers'
import { Defaults } from '../../../constants'
import { StyleAttribute, Option } from '../../../types'
import { InputStyles } from '../../../styles'

import { Box } from '../box'
import { Select } from '../select'

import Styles from './style'

const months: Option[] = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
].map((m, i) => {
  return {
    value: m,
    key: i,
  }
})

function getDates(year: number | null, month: number | null) {
  if (year !== null && month !== null) {
    const date = new Date(year, month, 1)
    const ds = []

    while (date.getMonth() === month) {
      ds.push(date.getDate())
      date.setDate(date.getDate() + 1)
    }

    return ds.map((d) => {
      return {
        value: d,
        key: d,
      }
    })
  } else {
    return Array(31)
      .fill(undefined)
      .map((u, i) => {
        const d = i + 1
        return {
          value: d,
          key: d,
        }
      })
  }
}

export function InputDate(props: {
  readonly testId?: string
  readonly value?: Date
  readonly fit?: boolean
  readonly autofocus?: boolean
  readonly years?: [number, number]
  readonly prefix?: React.ReactNode
  readonly suffix?: React.ReactNode
  readonly format?: string
  readonly valid?: boolean
  readonly readonly?: boolean
  readonly disabled?: boolean
  readonly tabindex?: number
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChange?: (date: Date | undefined, str: string) => void
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onSubmit?: (date: Date | undefined, str: string) => void
  readonly style?: StyleAttribute
}) {
  const fit = props.fit ?? false
  const format = props.format ?? 'dd mmmm yyyy'
  const date: Date | null = CommonHelper.date.valid(props.value)
    ? (props.value as Date)
    : null
  const [values, setValues] = useState<
    [number | null, number | null, number | null]
  >(
    date
      ? [date.getDate(), date.getMonth(), date.getFullYear()]
      : [null, null, null]
  )
  const [value, setValue] = useState<Date | null>(null)

  const [focused, setFocused] = useStateEffect(false, (f) => {
    if (props.onChangeFocus) {
      props.onChangeFocus(f)
    }
  })

  const [focusIndex, setFocusIndex] = useStateEffect(-1, (fi) => {
    if (fi === -1 && focused) {
      setFocused(false)
    } else if (fi > -1 && !focused) {
      setFocused(true)
    }
  })

  const [options, setOptions] = useState<[Option[], Option[], Option[]]>([
    getDates(date && date.getFullYear(), date && date.getMonth()),
    months,
    Array(props.years ? props.years[1] - props.years[0] : 100)
      .fill(undefined)
      .map((u, i) => {
        const d = props.years ? props.years[1] - i : Defaults.YEAR - i
        return {
          value: d,
          key: d,
        }
      }),
  ])

  const onDayChange = useCallback(
    (key: string) => {
      setValues([parseInt(key, 10), values[1], values[2]])
      setFocusIndex(1)
    },
    [values, setFocusIndex]
  )

  const onMonthChange = useCallback(
    (key: string) => {
      if (values[2] !== null) {
        let hasSelected = false

        setOptions([
          getDates(values[2], parseInt(key, 10)).map((d) => {
            if (d.key === values[0]) {
              hasSelected = true
            }

            return d
          }),
          options[1],
          options[2],
        ])
        setValues([
          hasSelected ? values[0] : null,
          parseInt(key, 10),
          values[2],
        ])
      } else {
        setValues([values[0], parseInt(key, 10), values[2]])
      }

      setFocusIndex(2)
    },
    [values, options, setFocusIndex]
  )

  const onYearChange = useCallback(
    (key: string) => {
      if (values[1] !== null) {
        let hasSelected = false

        setOptions([
          getDates(parseInt(key, 10), values[1]).map((d) => {
            if (d.key === values[0]) {
              hasSelected = true
            }

            return d
          }),
          options[1],
          options[2],
        ])
        setValues([
          hasSelected ? values[0] : null,
          values[1],
          parseInt(key, 10),
        ])
      } else {
        setValues([values[0], values[1], parseInt(key, 10)])
      }
    },
    [values, options]
  )

  const onDayChangeFocus = useCallback(
    (f: boolean) => {
      if (f) {
        setFocusIndex(0)
      } else if (focusIndex === 0) {
        setFocusIndex(-1)
      }
    },
    [focusIndex, setFocusIndex]
  )

  const onMonthChangeFocus = useCallback(
    (f: boolean) => {
      if (f) {
        setFocusIndex(1)
      } else if (focusIndex === 1) {
        setFocusIndex(-1)
      }
    },
    [focusIndex, setFocusIndex]
  )

  const onYearChangeFocus = useCallback(
    (f: boolean) => {
      if (f) {
        setFocusIndex(2)
      } else if (focusIndex === 2) {
        setFocusIndex(-1)
      }
    },
    [focusIndex, setFocusIndex]
  )

  const onPress = useCallback(() => {
    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }
  }, [props.tracker, props.trackerParam])

  const onSubmit = useCallback(() => {
    if (props.onSubmit) {
      props.onSubmit(
        value || undefined,
        value ? CommonHelper.date.format(value, format) : ''
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onSubmit, format, value])

  // Update focus when props autofocus changed
  useLayoutEffect(() => {
    if (props.autofocus) {
      setFocusIndex(0)
    }
  }, [props.autofocus, setFocusIndex])

  // Update value when values changes
  useUpdate(() => {
    if (values[0] !== null && values[1] !== null && values[2] !== null) {
      const _date = new Date(values[2], values[1], values[0], 0, 0, 0, 1)

      if (value === null || +_date !== +value) {
        setValue(_date)
      }
    } else if (value !== null) {
      setValue(null)
    }
  }, [values])

  // Call onChange when value changes
  useUpdate(() => {
    if (props.onChange) {
      props.onChange(
        value || undefined,
        value ? CommonHelper.date.format(value, format) : ''
      )
    }
  }, [props.onChange, value])

  // Update value when prop value changes
  useUpdate(() => {
    if (props.value && CommonHelper.date.valid(new Date(props.value))) {
      const d = new Date(props.value)
      setValues([d.getDate(), d.getMonth(), d.getFullYear()])
    }
  }, [props.value])

  return (
    <Box
      row
      centering="h"
      onPress={onPress}
      acceptBubble
      style={[
        InputStyles.container,
        props.disabled
          ? InputStyles['container-disabled']
          : props.readonly
          ? InputStyles['container-readonly']
          : undefined,
        props.valid && InputStyles['container-valid'],
        props.valid === false && InputStyles['container-invalid'],
        focused && InputStyles['container-focused'],
        fit && InputStyles.fit,
        props.style,
      ]}
    >
      {props.prefix}
      <Select
        testId={props.testId && `${props.testId}-date`}
        tabindex={props.tabindex}
        value={String(values[0])}
        placeholder="Tgl"
        readonly={props.readonly}
        disabled={props.disabled}
        autofocus={focusIndex === 0}
        options={options[0]}
        onChange={onDayChange}
        onChangeFocus={onDayChangeFocus}
        style={Styles.day}
      />
      <Select
        testId={props.testId && `${props.testId}-month`}
        value={String(values[1])}
        placeholder="Bulan"
        readonly={props.readonly}
        disabled={props.disabled}
        autofocus={focusIndex === 1}
        options={options[1]}
        onChange={onMonthChange}
        onChangeFocus={onMonthChangeFocus}
        style={Styles.month}
      />
      <Select
        testId={props.testId && `${props.testId}-year`}
        value={String(values[2])}
        placeholder="Tahun"
        readonly={props.readonly}
        disabled={props.disabled}
        autofocus={focusIndex === 2}
        options={options[2]}
        onChange={onYearChange}
        onChangeFocus={onYearChangeFocus}
        onSubmit={props.onSubmit ? onSubmit : undefined}
        style={Styles.year}
      />
      {props.suffix}
    </Box>
  )
}
