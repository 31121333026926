import React, { useCallback, useState } from 'react'
import Calendar from 'calendar-js'
import { CommonHelper } from '../../../../helpers'
import { Defaults } from '../../../../constants'
import { Option, StyleAttribute } from '../../../../types'

import { Box } from '../../box'
import { Button } from '../../button'
import { Select } from '../../select'
import { Text } from '../../text'

import Styles from './styles'

const options = {
  ...Array(12)
    .fill(undefined)
    .map((x, i) => {
      const month = new Date('01/01/1970').setMonth(i)
      return {
        months: CommonHelper.date.format(month, 'mmmm'),
        monthsAbbr: CommonHelper.date.format(month, 'mmm'),
      }
    })
    .reduce(
      (sum, curr) => {
        return {
          months: sum.months.concat(curr.months),
          monthsAbbr: sum.monthsAbbr.concat(curr.monthsAbbr),
        }
      },
      {
        months: [],
        monthsAbbr: [],
      } as {
        months: string[]
        monthsAbbr: string[]
      }
    ),
  ...Array(7)
    .fill(undefined)
    .map((x, i) => {
      const weekday = new Date('08/01/2021').setDate(i + 1)
      return {
        weekdays: CommonHelper.date.format(weekday, 'dddd'),
        weekdaysAbbr: CommonHelper.date.format(weekday, 'ddd'),
      }
    })
    .reduce(
      (sum, curr) => {
        return {
          weekdays: sum.weekdays.concat(curr.weekdays),
          weekdaysAbbr: sum.weekdaysAbbr.concat(curr.weekdaysAbbr),
        }
      },
      {
        weekdays: [],
        weekdaysAbbr: [],
      } as {
        weekdays: string[]
        weekdaysAbbr: string[]
      }
    ),
}

const cal = Calendar(options)
const monthOptions: Option[] = options.months.map((m, i) => {
  return {
    key: i,
    value: m,
  }
})

export const CalendarPart = (props: {
  readonly date?: Date
  readonly dates?: [Date?, Date?]
  readonly onChange?: (date: Date) => void
  readonly onClose?: () => void
  readonly onFocusChange?: (focused: boolean) => void
  readonly style?: StyleAttribute
  readonly enableBackDate?: boolean
  readonly bodyCalendar?: React.ReactNode
}) => {
  const dates = [
    CommonHelper.date.valid(props.dates ? props.dates[0] : undefined)
      ? (props.dates as Date[])[0]
      : new Date(
          Defaults.YEAR - 10,
          new Date().getMonth(),
          new Date().getDate()
        ),
    CommonHelper.date.valid(props.dates ? props.dates[1] : undefined)
      ? (props.dates as Date[])[1]
      : new Date(
          Defaults.YEAR + 10,
          new Date().getMonth(),
          new Date().getDate()
        ),
  ]
  const d = CommonHelper.date.valid(props.date)
    ? (props.date as Date)
    : new Date()
  const [date, setDate] = useState(d.getDate())
  const [month, setMonth] = useState(d.getMonth())
  const [year, setYear] = useState(d.getFullYear())
  const [years, setYears] = useState(
    cal
      .years(dates[0].getFullYear(), dates[1].getFullYear())
      .map((y) => ({ value: String(y) }))
  )
  const [calendar, setCalendar] = useState(cal.of(year, month))
  const [value, setValue] = useState([date, month, year])
  const [isChanged, setIsChanged] = useState(false)

  const now = useCallback(
    (dt) => {
      return dt === value[0] && month === value[1] && year === value[2]
    },
    [value, month, year]
  )

  const onSelectYear = useCallback(
    (key) => {
      const y = parseInt(key, 10)
      setYear(y)
      setCalendar(cal.of(y, month))
      setYears(
        cal
          .years(dates[0].getFullYear(), dates[1].getFullYear())
          .map((yy) => ({ value: String(yy) }))
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [month, props.dates]
  )

  const onSelectMonth = useCallback(
    (key) => {
      const m = parseInt(key, 10)
      setMonth(m)
      setCalendar(cal.of(year, m))
    },
    [year]
  )

  const onSelectDate = useCallback(
    (dt: number, set?: boolean) => {
      setDate(dt)
      setValue([dt, month, year])
      setIsChanged(true)

      if (set) {
        if (props.onChange) {
          props.onChange(new Date(year, month, dt))
        }

        if (props.onClose) {
          props.onClose()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [month, year, props.onChange]
  )

  const onSet = useCallback(() => {
    if (props.onChange) {
      props.onChange(new Date(year, month, date))
    }

    if (props.onClose) {
      props.onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, date, props.onChange, props.onClose])

  return (
    <Box
      tabindex={1}
      onFocus={
        props.onFocusChange ? () => props.onFocusChange?.(true) : undefined
      }
      onBlur={
        props.onFocusChange ? () => props.onFocusChange?.(false) : undefined
      }
      style={[Styles.container, props.style]}
    >
      <Box row style={Styles.header}>
        <Select
          fit
          options={monthOptions}
          value={options.months[month]}
          onChange={onSelectMonth}
          style={Styles.month}
        />
        <Select
          fit
          options={years}
          value={String(year)}
          onChange={onSelectYear}
          style={Styles.year}
        />
      </Box>
      <Box style={Styles.content}>
        {props.bodyCalendar}
        <Box row>
          {(calendar.weekdaysAbbr as unknown as string[]).map((title, i) => {
            return (
              <Box flex key={i} centering style={Styles.box}>
                <Text
                  size="tiny"
                  align="center"
                  transform="uppercase"
                  style={[
                    Styles.text,
                    i === 0 && Styles.weekend,
                    i === 6 && Styles.weekend,
                  ]}
                >
                  {title}
                </Text>
              </Box>
            )
          })}
        </Box>
        {calendar.calendar.map((dts, i) => {
          return (
            <Box row key={i} style={Styles.row}>
              {dts.map((dt, n) => {
                const isNow = now(dt)
                const dtt = +new Date(year, month, dt)
                const isSelectable =
                  !!dt &&
                  dtt <= +dates[1] &&
                  (!props.enableBackDate ? dtt >= +dates[0] : true)

                return dt ? (
                  <Box
                    flex
                    key={n}
                    accessible={isSelectable}
                    onPress={() => onSelectDate(dt)}
                    onDoublePress={() => onSelectDate(dt, true)}
                    style={[Styles.box, !isSelectable && Styles.unselectable]}
                  >
                    <Box centering flex style={isNow && Styles.boxActive}>
                      <Text
                        selectable={false}
                        size="tiny"
                        align="center"
                        weight={isNow ? 'bold' : 'normal'}
                        style={[
                          isNow ? Styles.textActive : Styles.text,
                          n === 0 && Styles.weekend,
                          n === dts.length - 1 && Styles.weekend,
                        ]}
                      >
                        {dt}
                      </Text>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    flex
                    key={n}
                    centering
                    style={[Styles.box, Styles.empty]}
                  />
                )
              })}
            </Box>
          )
        })}
      </Box>
      <Box centering="v" row>
        <Box flex />
        <Button
          theme="border-text-secondary"
          onPress={props.onClose}
          style={Styles.button}
        >
          Cancel
        </Button>
        <Button theme="warning" disabled={!isChanged} onPress={onSet}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
