import React, { useState, ReactNode, useCallback } from 'react'
import { CommonStyles } from '../../../../styles'
import { IconName } from '../../../../types'

import { Box } from '../../box'
import { Icon } from '../../icon'
import { Link } from '../../link'
import { Text } from '../../text'

import Styles from './styles'

function style(layout?: 'default' | 'big', hover?: boolean) {
  switch (layout) {
    case 'default':
    default:
      return false
    case 'big':
      return hover ? [Styles.big, Styles.bigHover] : Styles.big
  }
}

export const OptionPart = ({
  onHover: onHoverProps,
  ...props
}: {
  readonly title: string
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly target?: React.ComponentProps<typeof Link>['target']
  readonly color?: string
  readonly size?: React.ComponentProps<typeof Text>['size']
  readonly icon?:
    | IconName
    | {
        name: IconName
        color?: string
        size?: number
      }
  readonly borderless?: boolean
  readonly children?: ReactNode
  readonly onHover?: (hovering: boolean) => void
  readonly onPress?: () => void
  readonly layout?: 'default' | 'big'
  readonly style?: string
  readonly testId?: string
}) => {
  const [hover, setHover] = useState(false)

  const onHover = useCallback(
    (hovering: boolean) => {
      setHover(hovering)

      if (onHoverProps) {
        onHoverProps(hovering)
      }
    },
    [onHoverProps]
  )

  return (
    <Box
      testId={props.testId}
      row
      centering="v"
      key={props.title}
      onHover={onHover}
      tracker={props.tracker}
      trackerParam={props.trackerParam}
      onPress={props.onPress}
      style={[
        Styles.link,
        CommonStyles.transition,
        hover && Styles.hover,
        props.borderless && Styles.borderless,
        style(props.layout, hover),
      ]}
    >
      {props.icon ? (
        typeof props.icon === 'string' ? (
          <Icon name={props.icon} color={props.color} style={Styles.icon} />
        ) : (
          <Icon
            name={props.icon.name}
            color={props.icon.color ?? props.color}
            size={props.icon.size}
            style={Styles.icon}
          />
        )
      ) : (
        false
      )}
      <Text
        size={props.size ?? (props.layout === 'big' ? 'heading-4' : 'small')}
        color={props.color}
      >
        {props.title}
      </Text>
    </Box>
  )
}
