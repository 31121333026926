import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    padding: Sizes.margin.default,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: Sizes.margin.pad,
    backgroundColor: Colors.white,
  },

  clearer: {
    marginRight: Sizes.margin.half,
  },

  icon: {
    marginRight: Sizes.margin.pad,
  },

  input: {
    marginBottom: Sizes.margin.compact,
  },

  file: {
    opacity: 0,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    cursor: 'pointer',
    zIndex: -1,
  },

  button: {
    minWidth: 120,
  },

  inputfile: {
    paddingRight: 24,
    margin: [Sizes.margin.compact, 0, Sizes.margin.medium],
    borderRadius: 16,
    width: '100%',
  },
})
