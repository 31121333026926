import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  anim: {
    animationName: '$anim',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    transformOrigin: 'center center',
  },
  '@keyframes anim': {
    '0%': {
      transform: 'translateX(-32px) scale(0)',
    },
    '25%': {
      transform: 'translateX(16px) scale(1)',
    },
    '50%': {
      transform: 'translateX(50px) scale(1)',
    },
    '75%': {
      transform: 'translateX(84px) scale(1)',
    },
    '100%': {
      transform: 'translateX(32px) scale(0)',
    },
  },
})
