import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  prompt: {
    backgroundColor: Colors.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },

  content: {
    padding: Sizes.margin.default,
  },

  padder: {
    marginBottom: Sizes.margin.medium,
  },

  message: {
    marginBottom: Sizes.margin.default,
  },

  action: {
    paddingLeft: Sizes.margin.medium,
    paddingRight: Sizes.margin.medium,
    flexGrow: 1,
  },

  padRow: {
    marginRight: Sizes.margin.compact,
  },

  padColumn: {
    marginBottom: Sizes.margin.half,
  },

  icon: {
    width: '60px',
    height: '60px',
    margin: [Sizes.margin.default, 0],
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.palette('grey')[18],
  },
})
