import React, { useCallback, useEffect, useRef, useState } from 'react'
import { animated } from 'react-spring'
import { GA, StyleSheet } from '../../../libs/com'
import { useRefs, useUpdate } from '../../../hooks'
import { StyleAttribute } from '../../../types'

import Styles from './styles'

let RO: typeof ResizeObserver

if (typeof ResizeObserver === 'undefined') {
  import('resize-observer-polyfill').then((res) => {
    RO = res.default
  })
} else {
  RO = ResizeObserver
}

type Props = {
  readonly testId?: string
  readonly element?: 'div' | 'main' | 'section' | 'nav' | 'header' | 'footer'
  readonly accessible?: boolean | 'children'
  readonly animated?: boolean
  readonly reverse?: boolean
  readonly row?: boolean
  readonly flex?: boolean | number
  readonly centering?: boolean | 'h' | 'v'
  readonly spread?: 'between' | 'around' | 'evenly' | 'flex-end'
  readonly tabindex?: number
  readonly feedback?: boolean
  readonly innerHTML?: string
  readonly acceptBubble?: boolean
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
  readonly onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  readonly onPress?: (e: React.PointerEvent<HTMLDivElement>) => void
  readonly onHover?: (hovering: boolean) => void
  readonly onDoublePress?: (e: React.PointerEvent<HTMLDivElement>) => void
  readonly onChangePress?: (pressing: boolean) => void
  readonly onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  readonly onLayout?: (rect: DOMRectReadOnly, target: HTMLDivElement) => void
  readonly children?: React.ReactNode
  readonly style?: StyleAttribute
}

function spreading(spread?: 'between' | 'around' | 'evenly' | 'flex-end') {
  switch (spread) {
    case 'between':
      return Styles.between
    case 'around':
      return Styles.around
    case 'evenly':
      return Styles.evenly
    case 'flex-end':
      return Styles.flexEnd
    default:
      return false
  }
}

function centering(center?: boolean | 'h' | 'v', row?: boolean) {
  switch (center) {
    case true:
      return Styles.centering
    case 'v':
      return row ? Styles.centerY : Styles.centerX
    case 'h':
      return row ? Styles.centerX : Styles.centerY
    default:
      return false
  }
}

function reverse(rev?: boolean, row?: boolean) {
  switch (rev) {
    case true:
      return row ? Styles.reverseRow : Styles.reverse
    default:
      return false
  }
} // TODO: upgrade to latest eslint tooling

/* eslint-disable react/prop-types */ export const Box = React.forwardRef<
  HTMLDivElement,
  Props
>(
  (
    {
      onLayout,
      onHover,
      onPress: onPropsPress,
      onDoublePress: onPropsDoublePress,
      onChangePress,
      ...props
    },
    ref
  ) => {
    const div = useRefs(useRef<HTMLDivElement>(null), ref)
    const acceptBubble = props.acceptBubble ?? true
    const element = props.element ?? 'div'
    const accessible = props.accessible ?? true
    const feedback = props.feedback ?? true
    const pressable = accessible && (!!onPropsPress || !!onPropsDoublePress)
    const [{ pressing, x, y }, setState] = useState({
      pressing: false,
      x: 0,
      y: 0,
    })

    const onLayoutChange: ResizeObserverCallback = useCallback(
      (entries: readonly ResizeObserverEntry[]) => {
        const target = entries && entries[0] && entries[0].target

        if (onLayout) {
          onLayout(target.getBoundingClientRect(), target as HTMLDivElement)
        }
      },
      [onLayout]
    )

    const onPressing = useCallback(
      (e: React.MouseEvent | React.TouchEvent | React.PointerEvent) => {
        if (
          (e as React.MouseEvent).button
            ? (e as React.MouseEvent).button === 0
            : true
        ) {
          e.stopPropagation()

          setState({
            pressing: true,
            x: (e as React.MouseEvent).pageX
              ? (e as React.PointerEvent).pageX
              : (e as React.TouchEvent).touches[0].pageX,
            y: (e as React.MouseEvent).pageY
              ? (e as React.PointerEvent).pageY
              : (e as React.TouchEvent).touches[0].pageY,
          })
        }
      },
      []
    )

    const onDepress = useCallback(
      (e: React.MouseEvent | React.TouchEvent | React.PointerEvent) => {
        e.stopPropagation()

        setState({
          pressing: false,
          x: 0,
          y: 0,
        })
      },
      []
    )

    const onEnter = useCallback(
      (e: React.MouseEvent | React.TouchEvent | React.PointerEvent) => {
        if (onHover) {
          e.stopPropagation()
          onHover(true)
        }
      },
      [onHover]
    )

    const onLeave = useCallback(
      (e: React.MouseEvent | React.TouchEvent | React.PointerEvent) => {
        setState({
          pressing: false,
          x: 0,
          y: 0,
        })

        if (onHover) {
          e.stopPropagation()
          onHover(false)
        }
      },
      [onHover]
    )

    const onPress = useCallback(
      (e: React.PointerEvent<HTMLDivElement>) => {
        if (onPropsPress && (acceptBubble || e.target === div.current)) {
          // Send GA analytics tracker
          if (props.tracker) {
            GA.sendClick(props.tracker, props.trackerParam)
          }

          onPropsPress(e)
        }
      },
      [onPropsPress, div, acceptBubble, props.tracker, props.trackerParam]
    )

    const onDoublePress = useCallback(
      (e: React.PointerEvent<HTMLDivElement>) => {
        if (onPropsDoublePress && (acceptBubble || e.target === div.current)) {
          onPropsDoublePress(e)
        }
      },
      [onPropsDoublePress, div, acceptBubble]
    )

    const onMoving = useCallback(
      (e: React.MouseEvent | React.PointerEvent | React.TouchEvent) => {
        const mx = (e as React.MouseEvent).pageX
          ? (e as React.PointerEvent).pageX
          : (e as React.TouchEvent).touches?.[0].pageX ?? x
        const my = (e as React.MouseEvent).pageY
          ? (e as React.PointerEvent).pageY
          : (e as React.TouchEvent).touches?.[0].pageY ?? y
        const dx = Math.abs(x - mx)
        const dy = Math.abs(y - my)

        if (dx > 5 || dy > 5) {
          setState({
            pressing: false,
            x: 0,
            y: 0,
          })
        }
      },
      [x, y]
    )

    /**
     * Trigger onChangePress each time pressing state changes
     */
    useUpdate(() => {
      if (onChangePress) {
        onChangePress(pressing)
      }
    }, [pressing, onChangePress])

    useEffect(() => {
      if (div.current) {
        const observer = new RO(onLayoutChange)
        observer.observe(div.current, {
          box: 'border-box',
        })

        return () => observer.disconnect()
      }
    }, [div, onLayoutChange])

    const config = {
      ref: div,
      'data-testid': props.testId,
      onPointerDown: pressable ? onPressing : undefined,
      onPointerUp: pressable ? onDepress : undefined,
      onPointerMove: pressable ? onMoving : undefined,
      onPointerCancel: pressable ? onDepress : undefined,
      onPointerEnter: pressable || !!onHover ? onEnter : undefined,
      onPointerLeave: pressable || !!onHover ? onLeave : undefined,
      onFocus: props.onFocus,
      onBlur: props.onBlur,
      onClick: onPress,
      onDoubleClick: onDoublePress,
      onKeyDown: props.onKeyDown,
      tabIndex: props.tabindex,
      ...(props.innerHTML
        ? {
            dangerouslySetInnerHTML: {
              __html: props.innerHTML,
            },
          }
        : {}),
      ...StyleSheet.classNameAndStyle([
        Styles.box,
        typeof props.flex === 'number'
          ? {
              flex: props.flex,
            }
          : props.flex && Styles.flex,
        props.row && Styles.row,
        centering(props.centering, props.row),
        reverse(props.reverse, props.row),
        spreading(props.spread),
        pressable && Styles.clickable,
        pressing && feedback && Styles.pressing,
        accessible === 'children'
          ? Styles.unaccessibleSelf
          : !accessible && Styles.unaccessible,
        props.style,
      ]),
      children: props.children,
    }

    switch (element) {
      case 'div':
      default:
        return props.animated ? (
          <animated.div {...config} />
        ) : (
          <div {...config} />
        )
      case 'main':
        return props.animated ? (
          <animated.main {...config} />
        ) : (
          <main {...config} />
        )
      case 'section':
        return props.animated ? (
          <animated.section {...config} />
        ) : (
          <section {...config} />
        )
      case 'nav':
        return props.animated ? (
          <animated.nav {...config} />
        ) : (
          <nav {...config} />
        )
      case 'header':
        return props.animated ? (
          <animated.header {...config} />
        ) : (
          <header {...config} />
        )
      case 'footer':
        return props.animated ? (
          <animated.footer {...config} />
        ) : (
          <footer {...config} />
        )
    }
  }
)
