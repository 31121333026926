import './style.css'

import * as Recore from 'recore'

Recore.init({
  defaults: {
    LANG: 'en',
    TITLE: 'Kampus Merdeka Mentors',
    DEBUG: process.env.REACT_APP_DEBUG === 'true',
    DEBUG_LEVEL: parseInt(process.env.REACT_APP_DEBUG_LEVEL!, 10) as
      | 1
      | 2
      | 3
      | 4,
    STORE_KEY: '@mkbm',
    SERVICE_CACHE:
      process.env.REACT_APP_DEBUG !== 'true' ? 'default' : 'no-cache',
    FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_GA_UID,
    },
    IMAGE_RESOLVER: (path) => {
      return import('../assets/' + path)
    },
    FIREBASE_NO_CACHE: true,
  },
})

// Set minimum width viewport
const MIN_WIDTH = 1024

const viewport = document.querySelector('meta[name="viewport"]')
if (viewport && screen.width < MIN_WIDTH) {
  // document.body.style.width = `${MIN_WIDTH}px`
  viewport.setAttribute('content', `width=${MIN_WIDTH}, initial-scale=1`)
}
