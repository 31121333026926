import { StyleSheet } from '../libs/com'
import { Colors, Input } from '../constants'

export const InputStyles = {
  ...StyleSheet.create(
    {
      container: {
        height: Input.height,
        paddingLeft: Input.padding,
        paddingRight: Input.padding,
        borderRadius: Input.radius,
        borderWidth: 1,
        borderColor: Colors.palette('grey')[2],
        borderStyle: 'solid',
        transition: '.3s color, .3s border, .3s opacity',
        '&:hover': {
          cursor: 'text',
        },
      },
      input: {
        minWidth: 0,
        border: 0,
        padding: 0,
        fontFamily: Input.fontFamily,
        fontSize: Input.fontSize,
        fontWeight: Input.fontWeight,
        lineHeight: Input.lineHeight,
        letterSpacing: Input.letterSpacing,
        color: Colors.black,
        backgroundColor: Colors.transparent,
        flexGrow: 1,
        flexShrink: 1,
        width: '100%',
        textOverflow: 'ellipsis',
      },
      fit: {
        alignSelf: 'flex-start',
      },
    },
    {
      index: 1,
    }
  ),
  ...StyleSheet.create({
    // Container
    'container-readonly': {
      backgroundColor: Colors.palette('grey')[2],
    }, // no difference for now
    'container-focused': {
      borderColor: Colors.primary,
    },
    'container-disabled': {
      backgroundColor: Colors.palette('grey')[0],
    },
    'container-invalid': {
      borderColor: Colors.error,
    },
    'container-valid': {}, // no difference for now

    // Input
    'input-readonly': {}, // no difference for now
    'input-focused': {}, // no difference for now
    'input-disabled': {
      color: Colors.border,
    },
    'input-invalid': {}, // no difference for now
    'input-valid': {}, // no difference for now
  }),
}
