import { Defaults } from '../../../constants'
import { CommonHelper } from '../../../helpers'

const suffixes = ['', '@2x', '@3x']
const mobileSuffix = '.mobile'

type Folder = 'img' | 'icon'

class ImageResolverModel {
  private async resolver(
    folder: string,
    name: string,
    suffix: string,
    ext: string,
    isMobile: boolean
  ): Promise<string | null> {
    return Defaults.IMAGE_RESOLVER(
      `${folder}/${name}${isMobile ? mobileSuffix : ''}${suffix}.${ext}`
    )
      .catch(() => {
        if (isMobile) {
          // Fallback to desktop image if mobile image not defined
          return Defaults.IMAGE_RESOLVER(`${folder}/${name}${suffix}.${ext}`)
        }

        return null
      })
      .then((res) => {
        return res?.default ?? null
      })
  }

  public resolve = CommonHelper.fn.memoize(
    (
      folder: Folder,
      name: string,
      isMobile?: boolean
    ): {
      srcset: Promise<(string | null)[] | undefined>
      source: Promise<string | undefined>
    } => {
      const names = name.split('.')
      const file = names.shift() as string
      const ext = names.shift() as string

      const promise = Promise.resolve().then(async () => {
        return Promise.all(
          suffixes.map(async (suffix) => {
            if (Defaults.WEBP_SUPPORT) {
              return this.resolver(
                `${folder}-webp`,
                file,
                suffix,
                'webp',
                !!isMobile
              ).then((res) => {
                // Fallback to normal image if webp image not defined
                if (res === null) {
                  return this.resolver(folder, file, suffix, ext, !!isMobile)
                }

                return res
              })
            } else {
              return this.resolver(folder, file, suffix, ext, !!isMobile)
            }
          })
        ).then((images: (string | null)[]) => {
          return images[0] && (images[1] || images[2])
            ? {
                srcset: images,
              }
            : {
                source: images[0],
              }
        })
      })

      return {
        srcset: promise.then((p) => p.srcset),
        source: promise.then((p) => p.source || undefined),
      }
    },
    (folder, name, isMobile) => {
      return `${folder}|${name}|${!!isMobile}`
    }
  )
}

export const ImageResolver = new ImageResolverModel()
