import React, { ReactElement } from 'react'

import { Box } from '../box'
import { Image } from '../image'

import Styles from './styles'

export const Background = (
  props: React.ComponentProps<typeof Box> & {
    readonly sources?: React.ComponentProps<typeof Image>['sources']
    readonly source?: React.ComponentProps<typeof Image>['source']
    readonly srcset?: React.ComponentProps<typeof Image>['srcset']
    readonly resize?: React.ComponentProps<typeof Image>['resize']
    readonly position?:
      | 'revert'
      | 'center'
      | 'left'
      | 'right'
      | 'top'
      | 'bottom'
      | 'top left'
      | 'top right'
      | 'bottom left'
      | 'bottom right'
    readonly loader?: boolean
    readonly alt?: string
    readonly background?: string
    readonly rounded?: React.ComponentProps<typeof Image>['rounded']
  }
): ReactElement => {
  const {
    source,
    srcset,
    sources,
    resize = 'cover',
    position,
    loader,
    alt,
    background,
    children,
    rounded,
    ...boxProps
  } = props

  return (
    <Box {...boxProps}>
      <Image
        sources={sources}
        source={source}
        srcset={srcset}
        resize={resize}
        position={position}
        loader={loader}
        alt={alt}
        background={background}
        style={Styles.background}
        rounded={rounded}
      />
      {children}
    </Box>
  )
}
