import { StyleSheet } from '../../../../libs/com'
import { Colors, Sizes } from '../../../../constants'

export default StyleSheet.create({
  link: {
    padding: Sizes.margin.half,
    borderTopWidth: 1,
    borderColor: Colors.palette('grey')[1],
  },

  icon: {
    marginRight: Sizes.margin.half,
  },

  borderless: {
    borderTopWidth: 0,
  },

  hover: {
    background: Colors.palette('grey')[0],
  },

  big: {
    padding: [Sizes.margin.half, Sizes.margin.compact],
    margin: [Sizes.margin.pad, 0],
    border: 0,
    borderRadius: 8,
  },

  bigHover: {
    background: Colors.palette('blue')[4],
  },
})
