/* eslint-disable react/no-children-prop */
import React, { useCallback } from 'react'
import { StyleAttribute } from '../../../../types'
import { Colors } from '../../../../constants'

import { Box } from '../../box'
import { Text } from '../../text'
import { Icon } from '../../icon'

import Styles from './styles'
// import { useMount } from 'hooks'

export const PaginationPart = ({
  onChangeIndex,
  ...props
}: {
  readonly index: number
  readonly total: number
  readonly onChangeIndex?: (index: number) => void
  readonly style?: StyleAttribute
  readonly hidePageNumber?: boolean
}) => {
  const onPrev = useCallback(() => {
    if (onChangeIndex) {
      onChangeIndex(Math.max(0, props.index - 1))
    }
  }, [props.index, onChangeIndex])

  const onNext = useCallback(() => {
    if (onChangeIndex) {
      onChangeIndex(Math.min(props.index + 1, props.total - 1))
    }
  }, [props.index, props.total, onChangeIndex])

  const onChangeIndexPress = useCallback(
    (index: number) => {
      return () => {
        if (onChangeIndex) {
          onChangeIndex(index)
        }
      }
    },
    [onChangeIndex]
  )

  return props.total > 0 && props.total !== Infinity ? (
    <Box row style={[Styles.paging, props.style]}>
      <Box
        row
        centering
        style={[
          Styles.box,
          Styles.bordered,
          Styles.padder,
          props.index > 0 ? Styles.active : Styles.disabled,
        ]}
        onPress={props.index > 0 ? onPrev : undefined}
      >
        <Icon
          name="chevron-left"
          color={props.index > 0 ? Colors.black : Colors.palette('grey')[3]}
          style={Styles.icon}
        />
      </Box>
      {!props.hidePageNumber &&
        (() => {
          let ellipsis = true
          return Array(props.total)
            .fill(undefined)
            .map((u, i) => {
              if (
                i < 2 ||
                i >= props.total - 2 ||
                Math.abs(props.index - i) <= 2
              ) {
                const isPrevActive =
                  (i - 1 < 2 ||
                    i - 1 >= props.total - 2 ||
                    Math.abs(props.index - i + 1) <= 2) &&
                  i !== 0
                const isNextActive =
                  (i + 1 < 2 ||
                    i + 1 >= props.total - 2 ||
                    Math.abs(props.index - i - 1) <= 2) &&
                  i !== props.total - 1
                const isActive = i === props.index
                ellipsis = false

                return (
                  <Box
                    key={i}
                    row
                    centering
                    style={[
                      Styles.box,
                      Styles.bordered,
                      isPrevActive && Styles.noLeftBorder,
                      isNextActive && Styles.noRightBorder,
                      !isActive && Styles.active,
                    ]}
                    onPress={isActive ? undefined : onChangeIndexPress(i)}
                  >
                    <Text
                      size="heading-4"
                      color={
                        !isActive ? Colors.black : Colors.palette('grey')[3]
                      }
                    >
                      {i + 1}
                    </Text>
                  </Box>
                )
              } else {
                if (!ellipsis) {
                  ellipsis = true
                  return (
                    <Box
                      key={i}
                      row
                      centering
                      style={[Styles.box, Styles.active]}
                    >
                      <Text size="heading-4">...</Text>
                    </Box>
                  )
                } else {
                  return false
                }
              }
            })
            .filter((b) => !!b)
        })()}
      <Box
        row
        centering="h"
        style={[
          Styles.box,
          Styles.bordered,
          Styles.padder,
          props.index < props.total - 1 ? Styles.active : Styles.disabled,
        ]}
        onPress={props.index < props.total - 1 ? onNext : undefined}
      >
        <Icon
          name="chevron-right"
          color={
            props.index < props.total - 1
              ? Colors.black
              : Colors.palette('grey')[3]
          }
          style={Styles.icon}
        />
      </Box>
    </Box>
  ) : null
}
