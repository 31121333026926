import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  header: {
    flexGrow: 0,
    padding: [Sizes.margin.default, Sizes.margin.thick],
    borderBottomWidth: 1,
    borderColor: Colors.palette('grey')[1],
    background: Colors.white,
  },

  footer: {
    padding: [Sizes.margin.default, Sizes.margin.thick],
    borderTopWidth: 1,
    borderColor: Colors.palette('grey')[1],
  },

  paging: {
    padding: Sizes.margin.thick,
  },

  inlineBlock: {
    display: 'inline-block',
  },

  content: {
    padding: [0, Sizes.margin.half],
    width: 1,
  },

  row: {
    padding: [Sizes.margin.medium, Sizes.margin.thick],
    background: Colors.white,
  },

  loader: {
    padding: Sizes.margin.page,
  },

  odd: {
    backgroundColor: Colors.palette('grey')[0],
  },

  note: {
    paddingTop: 4,
  },

  required: {
    color: Colors.error,
  },

  text: {
    color: Colors.palette('grey')[4],
    marginRight: Sizes.margin.half,
    // flexGrow: 1,
  },

  checkbox: {
    marginRight: Sizes.margin.thick,

    '&>div>div ': {
      width: 24,
      height: 24,
    },
  },

  boxRemove: {
    width: 100,
  },
})
