import { Logger } from '../libs/com/logger'

import { Fonts } from './font'
import { Sizes } from './size'

let height = 40
let radius = 8
let padding = Sizes.margin.compact
let fontSize = 14
let fontWeight = 400
let fontFamily = Fonts.sans
let lineHeight = 1.4
let letterSpacing = 0

export const Input = Object.freeze({
  get radius() {
    return radius
  },
  set radius(r: number) {
    Logger.log('INFO', 'Setting input radius:', r)
    radius = r
  },

  get padding() {
    return padding
  },
  set padding(p: number) {
    Logger.log('INFO', 'Setting input padding:', p)
    padding = p
  },

  get height() {
    return height
  },
  set height(h: number) {
    Logger.log('INFO', 'Setting input height:', h)
    height = h
  },

  get fontSize() {
    return fontSize
  },
  set fontSize(fs: number) {
    Logger.log('INFO', 'Setting input font size:', fs)
    fontSize = fs
  },

  get fontWeight() {
    return fontWeight
  },
  set fontWeight(fw: number) {
    Logger.log('INFO', 'Setting input font weight:', fw)
    fontWeight = fw
  },

  get fontFamily() {
    return fontFamily
  },
  set fontFamily(ff: string) {
    Logger.log('INFO', 'Setting input font family:', ff)
    fontFamily = ff
  },

  get lineHeight() {
    return lineHeight
  },
  set lineHeight(lh: number) {
    Logger.log('INFO', 'Setting input line height:', lh)
    lineHeight = lh
  },

  get letterSpacing() {
    return letterSpacing
  },
  set letterSpacing(ls: number) {
    Logger.log('INFO', 'Setting input letter spacing:', ls)
    letterSpacing = ls
  },
})
