/* eslint-disable react/no-children-prop */
import React, { ComponentProps } from 'react'
import { StyleAttribute } from '../../../types'

import { Colors } from '../../../constants'

import { Box } from '../box'
import { Icon } from '../icon'

import { ContentPart } from './_content'

import Styles from './styles'
import { Checkbox } from '../checkbox'
import { Text } from '../text'
import { NotificationComponent } from '../../components/notification'
import { Checkbox as CheckboxDS } from '../../ds/checkbox'

export const RowsPart = (props: {
  readonly id?: string | number
  readonly index: number
  readonly checkbox?: boolean
  readonly removeItems?: boolean
  readonly checked?: boolean
  readonly data: React.ComponentProps<typeof ContentPart>[]
  readonly onPress?: () => void
  readonly onCheckChange?: (checked: boolean) => void
  readonly onRemove?: () => void
  readonly style?: StyleAttribute
  readonly error?: boolean
  readonly errorMessage?: string
  readonly fontSize?: ComponentProps<typeof Text>['size']
  readonly disableCheckbox?: boolean
  readonly type?: 'default' | 'ds'
}) => {
  return (
    <>
      <Box
        row
        onPress={props.onPress}
        style={[Styles.row, props.index % 2 === 0 && Styles.odd, props.style]}
      >
        {props.checkbox &&
          (props.type === 'ds' ? (
            <CheckboxDS
              size={50}
              disabled={props.disableCheckbox}
              style={Styles.checkbox}
              active={props.checked}
              onChange={props.onCheckChange}
            />
          ) : (
            <Checkbox
              theme="secondary"
              style={Styles.checkbox}
              active={props.checked}
              onChange={props.onCheckChange}
            />
          ))}
        {props.removeItems && (
          <Box flex>
            <Icon
              name="close"
              color={Colors.palette('red')[2]}
              onPress={props.onRemove}
            />
          </Box>
        )}
        {props.data.map((data, i) => (
          <ContentPart key={i} fontSize={props.fontSize} {...data} />
        ))}
      </Box>
      {props.error && (
        <NotificationComponent
          accent
          type="error"
          children={props.errorMessage}
        />
      )}
    </>
  )
}
