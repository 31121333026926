/**
 * Put initialization code here
 * Things that you need to load / do before the app begins
 */
import jss from 'jss'
import preset, { Options } from 'jss-preset-default'
import expand from 'jss-plugin-expand'
import { CommonHelper } from './helpers'
import { Defaults } from './constants'
import { Lang } from './types'
import './styles/css/normalize.css'
import './styles/css/default.css'
import './styles/css/index.css'

// tslint:disable-next-line: only-arrow-functions
export const init = async function (
  config: {
    jss?: Options
    defaults?: {
      TITLE?: string
      LANG?: Lang
      DEBUG?: boolean
      DEBUG_LEVEL?: typeof Defaults.DEBUG_LEVEL
      STORE_KEY?: string
      SERVICE_TIMEOUT?: number
      SERVICE_CACHE?: typeof Defaults.SERVICE_CACHE
      LOADER_DELAY_DURATION?: number
      IMAGE_RESOLVER?: typeof Defaults.IMAGE_RESOLVER
      LANG_RESOLVER?: typeof Defaults.LANG_RESOLVER
      GA_ID?: string
      FIREBASE_CONFIG?: typeof Defaults.FIREBASE_CONFIG
      FIREBASE_NO_CACHE?: boolean
    }
  } = {}
) {
  // config.autoAddCss = false
  if (typeof window !== 'undefined') {
    window.history.scrollRestoration = 'manual'
  }

  jss.setup(preset(config.jss))

  jss.use(expand())

  // Set default configs
  if (config.defaults) {
    CommonHelper.object.keys(config.defaults).forEach((key) => {
      if (Object.getOwnPropertyDescriptor(Defaults, key)?.set) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(Defaults as any)[key] = config.defaults?.[key]
      }
    })
  }

  return true
}
