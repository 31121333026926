import { StyleSheet } from '../../../libs/com'
import { Sizes } from '../../../constants'

export default StyleSheet.create({
  input: {
    marginRight: Sizes.margin.half,
  },

  select: {
    opacity: 0,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
})
