import { DependencyList, useEffect, useState } from 'react'
import { ManagerModel } from '../../models'
import { Setter } from '../../types'

/**
 * Use this hook to auto subscribe to any property changes. i.e. You want to
 * subscribe to app state change, then use this hook with AppManager,
 * so any changes on app state will be broadcasted to the component.
 */
export function useManager<
  Properties extends {
    [k: string]: unknown
  }
>(manager: ManagerModel<Properties, unknown>, setter: Setter<Properties>, keys?: DependencyList) {
  useEffect(() => {
    const unsub = manager.subscribe(setter)

    return () => {
      unsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys)
}

export function useManagerProps<
  Properties extends {
    [k: string]: unknown
  },
  R extends unknown
>(manager: ManagerModel<Properties, unknown>, selector: (props: Properties) => R, keys?: DependencyList): R {
  const [value, set] = useState(selector(manager.get()))

  useEffect(() => {
    const unsub = manager.subscribe((properties) => {
      set(selector(properties))
    })

    return () => {
      unsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys || [])

  return value
}

// Use multiple manager and props
// instead of one manager and one selector
export function useManagersProps<
  Properties extends {
    [k: string]: unknown
  },
  R extends unknown
>(
  selectors: {
    manager: ManagerModel<Properties, unknown>
    selector: (props: Properties) => R
  }[],
  keys?: DependencyList
): R[] {
  const [value, set] = useState(selectors.map((s) => s.selector(s.manager.get())))

  useEffect(() => {
    const unsubs = selectors.map((s, i) =>
      s.manager.subscribe((properties) => {
        value[i] = s.selector(properties)
        set([...value])
      })
    )

    return () => {
      unsubs.map((u) => u())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys || [])

  return value
}
