import { useRef, useEffect, MutableRefObject } from 'react'

/**
 * Use this hook to reuse ref when forwarding ref
 * @see https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 */
export function useRefs<T extends unknown>(
  ...refs: (MutableRefObject<T | null> | ((ref: T | null) => void) | null)[]
) {
  const targetRef = useRef<T>(null)

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}
