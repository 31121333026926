import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Icon } from '../icon'
import { Text } from '../text'

import Styles from './styles'

type Theme = 'primary' | 'secondary' | 'blue'

function getColor(theme: Theme) {
  switch (theme) {
    case 'primary':
    default:
      return Colors.black
    case 'secondary':
    case 'blue':
      return Colors.white
  }
}

export function Checkbox({
  onChange,
  ...props
}: {
  readonly testId?: string
  readonly active?: boolean
  readonly label?: string | ReactNode
  readonly color?: string
  readonly size?: number
  readonly theme?: Theme
  readonly autofocus?: boolean
  readonly readonly?: boolean
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChange?: (active: boolean) => void
  readonly style?: StyleAttribute
}): ReactElement {
  const theme = props.theme ?? 'primary'
  const color = props.readonly
    ? Colors.palette('grey')[3]
    : props.color ?? getColor(theme)
  const size = props.size ?? Sizes.margin.thick
  const box = useRef({} as HTMLDivElement)
  const [active, setActive] = useState(props.active)
  const [changed, setChanged] = useState<null | boolean>(null)
  const [focused, setFocused] = useState(false)

  if (changed !== !!props.active) {
    setChanged(!!props.active)
    setActive(props.active)
  }

  useLayoutEffect(() => {
    if (props.autofocus && box.current) {
      box.current.focus()
    }
  }, [props.autofocus])

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const onBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const onToggle = useCallback(() => {
    setActive(!active)

    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }

    if (onChange) {
      onChange(!active)
    }
  }, [active, onChange, props.tracker, props.trackerParam])

  return (
    <Box
      row
      ref={box}
      testId={props.testId}
      accessible={!props.readonly}
      tabindex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      onPress={onToggle}
      style={props.style}
    >
      <Box
        centering
        style={[
          Styles.icon,
          Styles[theme],
          focused && Styles.focused,
          active && Styles[`${theme}-active` as `${Theme}-active`],
          props.readonly && Styles.readonly,
          { width: size, height: size },
        ]}
      >
        {props.readonly && <Box style={Styles.checkboxDisabled}></Box>}
        {active ? (
          <Icon
            name={theme === 'primary' ? 'confirm' : 'check'}
            size={((theme === 'primary' ? 16 : 18) / 24) * size}
            color={color}
          />
        ) : (
          false
        )}
      </Box>
      {props.label ? (
        typeof props.label === 'string' ? (
          <Text size="small" style={Styles.label}>
            {props.label}
          </Text>
        ) : (
          <Box flex style={Styles.label}>
            {props.label}
          </Box>
        )
      ) : (
        false
      )}
    </Box>
  )
}
