import { useState, useCallback } from 'react'

/**
 * Use this hook to capture textinput value
 */
export function useCheckbox(defaultValue: boolean) {
  const [value, setValue] = useState(defaultValue)
  const setCheckboxValue = useCallback((e: boolean) => {
    setValue(e)
  }, [])

  return [value, setCheckboxValue, setValue] as [typeof value, typeof setCheckboxValue, typeof setValue]
}
