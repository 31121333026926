import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  message: {
    margin: [Sizes.margin.pad, 0, 0, Sizes.margin.compact],
    color: Colors.opacity(Colors.black, 0.5),
  },

  fit: {
    alignSelf: 'flex-start',
  },

  label: {
    marginBottom: Sizes.margin.pad,
  },

  sublabel: {
    marginBottom: Sizes.margin.default,
    color: Colors.opacity(Colors.black, 0.5),
  },
})
