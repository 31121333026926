// import React, { Suspense } from 'react'
import { Router } from 'recore/libs/advanced/router'

const config: Parameters<typeof Router['Root']>[0] = [
  {
    type: 'redirect',
    from: '/home',
    to: '/',
    exact: true,
  },
  {
    type: 'redirect',
    from: '/landing',
    to: '/',
    exact: true,
  },
  {
    type: 'redirect',
    from: '/',
    to: '/login',
    exact: true,
  },
  {
    path: '/login',
    component: Router.Page(() => import('pages/login')),
    exact: true,
  },
  {
    path: '/register',
    component: Router.Page(() => import('pages/register')),
    exact: true,
  },
  {
    path: '/forgot',
    component: Router.Page(() => import('pages/forgot')),
    exact: true,
  },
  {
    path: '/change-password',
    component: Router.Page(() => import('pages/change-password')),
    exact: true,
  },
  {
    path: '/terms',
    component: Router.Page(() => import('pages/terms')),
    exact: true,
  },
  {
    path: '/dashboard/:type/mentee/report',
    component: Router.Page(() => import('pages/dashboard/mentee/report')),
    exact: true,
  },
  {
    path: '/dashboard/:type/mentee/:cycle/report/:reportId',
    component: Router.Page(() => import('pages/dashboard/mentee/report')),
    exact: true,
  },
  {
    path: '/dashboard/log/:type/evaluation/activity/:activityId/:cycle/mentee/:menteeId?',
    component: Router.Page(() => import('pages/dashboard/evaluation/detail')),
    exact: true,
  },
  {
    path: '/dashboard',
    component: Router.Page(
      () => import('pages/dashboard'),
      [
        {
          path: '/dashboard/profile',
          component: Router.Page(
            () => import('pages/dashboard/profile'),
            [
              {
                path: '/dashboard/profile/details',
                component: Router.Page(
                  () => import('pages/dashboard/profile/personal')
                ),
                exact: true,
              },
              {
                path: '/dashboard/profile/npwp',
                component: Router.Page(
                  () => import('pages/dashboard/profile/npwp')
                ),
                exact: true,
              },
              {
                path: '/dashboard/profile/bank',
                component: Router.Page(
                  () => import('pages/dashboard/profile/bank-account')
                ),
                exact: true,
              },
              {
                path: '/dashboard/profile/password',
                component: Router.Page(
                  () => import('pages/dashboard/profile/password')
                ),
                exact: true,
              },
              {
                path: '/dashboard/profile/picture',
                component: Router.Page(
                  () => import('pages/dashboard/profile/password')
                ),
                exact: true,
              },
            ]
          ),
        },
        {
          path: '/dashboard/profile/npwp',
          component: Router.Page(() => import('pages/dashboard/profile')),
          exact: true,
        },
        {
          path: '/dashboard/statuspendanaan',
          component: Router.Page(
            () => import('pages/dashboard/statuspendanaan')
          ),
          exact: true,
        },
        {
          path: '/dashboard/log/monthly',
          component: Router.Page(() => import('pages/dashboard/monthly-log')),
          exact: true,
        },
        {
          path: '/dashboard/programs',
          component: Router.Page(() => import('pages/dashboard/programs')),
          exact: true,
        },
        {
          path: '/dashboard',
          component: Router.Page(() => import('pages/dashboard/homepage')),
          exact: true,
        },
        {
          path: '/dashboard/mentee/:cycle/add',
          component: Router.Page(() => import('pages/dashboard/mentee/add')),
          exact: true,
        },
        {
          path: '/dashboard/mentee/:cycle',
          component: Router.Page(() => import('pages/dashboard/report')),
          exact: true,
        },
        {
          path: '/dashboard/log/evaluation/:cycle/:type?',
          component: Router.Page(() => import('pages/dashboard/evaluation/')),
          exact: true,
        },

        {
          path: '/dashboard/mentee/:cycle/:menteeId',
          component: Router.Page(() => import('pages/dashboard/mentee')),
          exact: true,
        },
        {
          component: Router.Page(() => import('pages/404')),
        },
      ]
    ),
  },
  {
    component: Router.Page(() => import('pages/404')),
  },
]

// eslint-disable-next-line react/display-name
export default function () {
  return Router.Root(config)
}
