import React, { useContext, useState } from 'react'
import { useTransition } from 'react-spring'
// import { AlertManager } from 'managers/alert'
import { StackModel } from '../../../models'
import { useManager } from '../../../hooks'
import { AppContext, UtilitiesContext } from '../../../contexts'
import { Sizes } from '../../../constants'
import { AlertConfig } from '../../../types'

import { Box } from '../../blocks'
import { PromptPart } from './_prompt'
import { OverlayPart } from './_overlay'

import Styles from './styles'
import { PromptBossaPart } from './_prompt-bossa'

export const AlertUtilities = (props: {
  // Props goes here
  readonly manager: StackModel<AlertConfig>
}) => {
  const [stacks, setStack] = useState(
    Array.from(props.manager.get('stack').values())
  )
  const last = stacks[stacks.length - 1]
  const app = useContext(AppContext)
  const utilities = useContext(UtilitiesContext)
  const transitions = useTransition(stacks, (stack) => stack.id, {
    from: {
      opacity: 0,
      transform: 'scale(1.4) translateY(0px)',
    },
    enter: {
      opacity: 1,
      transform: 'scale(1) translateY(0px)',
    },
    update: (stack) => {
      return {
        opacity: 1 - stack.state / 4,
        transform: `scale(${1 - stack.state / 20}) translateY(${
          stack.state * Sizes.margin.half
        }px)`,
      }
    },
    leave: {
      opacity: 0,
      transform: 'scale(1.1) translateY(0px)',
    },
  })

  useManager(
    props.manager,
    (properties) => {
      app.overlay.set('alert', properties.stack.size)
      setStack(Array.from(properties.stack.values()))
    },
    [app.overlay]
  )

  return (
    <Box accessible="children" style={Styles.container}>
      <OverlayPart active={!!stacks.length} />

      {transitions.map(({ item, props: style, key }) => {
        if (item.config.type === 'bossa') {
          return (
            <Box
              key={key}
              accessible={item.id === last?.id}
              acceptBubble={false}
              onPress={
                item.config.closeOnOverlayPress
                  ? () => {
                      utilities.alert.hide(item.id)
                      if (item.config.onClose) {
                        item.config.onClose()
                      }
                    }
                  : undefined
              }
              centering
              animated
              style={[Styles.transitioner, style]}
            >
              <PromptBossaPart
                {...item.config}
                accessible={item.id === last?.id}
                onCloseModal={() => utilities.alert.hide(item.id)}
              />
            </Box>
          )
        }

        return (
          <Box
            key={key}
            accessible={item.id === last?.id}
            acceptBubble={false}
            onPress={
              item.config.closeOnOverlayPress
                ? () => {
                    utilities.alert.hide(item.id)
                    if (item.config.onClose) {
                      item.config.onClose()
                    }
                  }
                : undefined
            }
            centering
            animated
            style={[Styles.transitioner, style]}
          >
            <PromptPart
              {...item.config}
              accessible={item.id === last?.id}
              onCloseModal={() => utilities.alert.hide(item.id)}
            />
          </Box>
        )
      })}
    </Box>
  )
}
