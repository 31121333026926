import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: Sizes.margin.half,
    padding: Sizes.margin.default,
  },

  text: {
    marginLeft: Sizes.margin.default,
  },

  error: {
    borderColor: Colors.palette('red')[2],
    background: Colors.palette('red')[4],
  },

  warning: {
    borderColor: Colors.palette('yellow')[2],
    background: Colors.palette('yellow')[4],
  },

  success: {
    borderColor: Colors.palette('green')[3],
    background: Colors.palette('green')[0],
  },

  info: {
    borderColor: Colors.palette('blue')[2],
    background: Colors.palette('blue')[4],
  },

  infoPrimary: {
    borderColor: Colors.primary,
    background: Colors.palette('blue')[6],
  },

  accent: {
    borderWidth: 0,
    borderLeftWidth: Sizes.margin.half,
    marginBottom: Sizes.margin.thick,
    padding: Sizes.margin.default,
    borderRadius: Sizes.margin.half,
    margin: Sizes.margin.half,
  },

  accentError: {
    color: Colors.palette('red')[5],
    backgroundColor: Colors.palette('red')[6],
  },
})
