import { StyleSheet } from '../../../libs/com'
import { Sizes } from '../../../constants'
// import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  ul: {
    margin: 0,
    padding: 0,
    counterReset: 'item',
    listStyle: 'none',
    '&> li:before': {
      position: 'absolute',
      left: 0,
      content: '"∙ "',
      display: 'block',
    },
  },

  ol: {
    '&> li:before': {
      content: 'counter(item) ". "',
      counterIncrement: 'item',
    },
  },

  alphabet: {
    '&> li:before': {
      content: 'counter(item, lower-alpha) ". "',
      counterIncrement: 'item',
    },
  },

  roman: {
    '&> li:before': {
      content: 'counter(item, lower-roman) ". "',
      counterIncrement: 'item',
    },
  },

  li: {
    paddingLeft: Sizes.margin.thick,
  },
})
