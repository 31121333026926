import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { GA } from '../../../libs/com'
import { useMount, useUpdate } from '../../../hooks'
import {
  NotificationManager,
  PageContext,
  PageStaticContext,
} from '../../../contexts'
import { StringHelper, UIHelper } from '../../../helpers'
import { Defaults } from '../../../constants'
import { Rect, StyleAttribute } from '../../../types'

import { Box } from '../box'
import { ScrollView } from '../scroll.view'
import { StickyPart } from './_sticky'

import Styles from './styles'

const styles = {
  header: [Styles.fixed, Styles.header],
  footer: [Styles.fixed, Styles.footer],
}

type Props = {
  readonly name?: string
  readonly description?: string
  readonly referrer?: string
  readonly row?: boolean
  readonly flex?: boolean
  readonly scroll?: boolean
  readonly children: React.ReactNode
  readonly onEndReached?: () => void
  readonly onEndReachedThreshold?: number
  readonly style?: StyleAttribute
  readonly contentContainerStyle?: StyleAttribute
}

// eslint-disable-next-line react/display-name
export const Page = React.memo(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const page = useContext(PageContext)
    const spage = useContext(PageStaticContext)
    const location = useLocation()
    const scroll = props.scroll ?? true

    const onLayout = useCallback(
      (rect: Rect) => {
        if (
          rect.width &&
          rect.height &&
          !UIHelper.isRectSame(rect, spage.layout.rect)
        ) {
          spage.layout.rect = rect
        }
      },
      [spage.layout]
    )

    useMount(() => {
      // Listen for static page layout change
      // And set notification area
      spage.layout.onChange((layout) => {
        const npage = NotificationManager.get('page')
        const rect = {
          x: layout.rect.x,
          y: layout.rect.y + layout.header.height,
          height: Math.max(
            0,
            Math.min(layout.rect.height, window.innerHeight) -
              layout.header.height -
              layout.footer.height
          ),
          width: layout.rect.width,
        }

        if (
          npage.path !== location.pathname ||
          (npage.path === location.pathname && page.depth > npage.depth) ||
          (npage.path === location.pathname &&
            page.depth === npage.depth &&
            !UIHelper.isRectSame(rect, npage.rect))
        ) {
          NotificationManager.set('page', {
            path: location.pathname,
            depth: page.depth,
            rect,
          })
        }
      })
    })

    useUpdate(() => {
      // Change meta title
      const $title = document.getElementsByTagName('title')[0]
      $title.innerText = `${
        props.name ? `${props.name} | Merdeka Belajar - ` : ''
      }${Defaults.TITLE}`
    }, [props.name])

    useEffect(() => {
      // Change meta title
      const $title = document.getElementsByTagName('title')[0]
      const $description = document.querySelector('meta[name="description"]')
      const $ogtitle = document.querySelector('meta[property="og:title"]')
      const $ogdescription = document.querySelector(
        'meta[property="og:description"]'
      )

      const title = StringHelper.trim(
        `${props.name ? `${props.name} | Merdeka Belajar - ` : ''}${
          Defaults.TITLE
        }`,
        65
      )
      const description = StringHelper.trim(
        props.description ?? 'Shape the future Indonesian leaders',
        155
      )

      // https://stackoverflow.com/questions/19778620/provide-an-image-for-whatsapp-link-sharing
      if ($title) $title.innerText = title
      $ogtitle?.setAttribute('content', title)
      $description?.setAttribute('content', description)
      $ogdescription?.setAttribute('content', description)
    }, [props.name, props.description])

    useEffect(() => {
      // Scroll to top whenever path changed
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, [location.pathname])

    useEffect(() => {
      // Send GTAG pageview
      GA.sendPageView(location.pathname, props.name, props.referrer)
    }, [location.pathname, props.name, props.referrer])

    return scroll ? (
      <ScrollView
        flex
        useWindowScroll
        ref={ref}
        onLayout={onLayout}
        onEndReached={props.onEndReached}
        onEndReachedThreshold={props.onEndReachedThreshold}
        style={[Styles.page, props.flex && Styles.flex, props.style]}
        contentContainerStyle={[
          Styles.content,
          props.row && Styles.row,
          props.contentContainerStyle,
        ]}
      >
        <StickyPart style={styles.header} />
        {props.children}
        <StickyPart bottom style={styles.footer} />
      </ScrollView>
    ) : (
      <Box
        flex
        ref={ref}
        onLayout={onLayout}
        row={props.row}
        style={[
          Styles.page,
          props.flex && Styles.flex,
          Styles.content,
          props.style,
          props.contentContainerStyle,
        ]}
      >
        <StickyPart style={styles.header} />
        {props.children}
        <StickyPart bottom style={styles.footer} />
      </Box>
    )
  })
)
