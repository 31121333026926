import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA, StyleSheet } from '../../../libs/com'
import { useUpdate } from '../../../hooks'
import { Colors } from '../../../constants'
import { NestedOption, Option, StyleAttribute } from '../../../types'
import { InputStyles } from '../../../styles'

import { Box } from '../box'
import { Icon } from '../icon'

import Styles from './styles'

// Find value by importance
// If have defined value, then match by option key first
// If have defined value, also match by option value
// If doesn't have defined value, match by selected
const importance = ['key', 'value', 'selected']

function findValue(options: (Option | NestedOption)[], val?: string | number) {
  const value: {
    [k in 'key' | 'value']:
      | {
          key?: string | number | boolean
          value: string | number | boolean
        }
      | undefined
  } = {
    key: undefined,
    value: undefined,
  }

  options.forEach((option) => {
    if ((option as NestedOption).options) {
      ;(option as NestedOption).options.forEach((o) => {
        if (val && String(o.key) === val) {
          value.key = o
        } else if (val && String(o.value) === val) {
          value.value = o
        }
      })
    } else if (
      (val || val === '' || val === 0) &&
      String((option as Option).key) === val
    ) {
      value.key = option
    } else if (
      (val || val === '' || val === 0) &&
      String((option as Option).value) === val
    ) {
      value.value = option
    }
  })

  const key = importance.find((i) => {
    if (value[i as keyof typeof value]) {
      return true
    }

    return false
  }) as keyof typeof value | undefined

  if (key) {
    return {
      key: String(value[key]?.key ?? value[key]?.value),
      value: String(value[key]?.value),
    }
  } else {
    return {
      key: '',
      value: '',
    }
  }
}

export function Select(props: {
  readonly testId?: string
  readonly options?: (Option | NestedOption)[] | string
  readonly fit?: boolean
  readonly value?: string
  readonly valid?: boolean
  readonly prefix?: React.ReactNode
  readonly suffix?: React.ReactNode
  readonly placeholder?: string | false
  readonly autofocus?: boolean
  readonly disabled?: boolean
  readonly readonly?: boolean
  readonly tabindex?: number
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onChange?: (key: string, value: string) => void
  readonly onSubmit?: (key: string, value: string) => void
  readonly input?: StyleAttribute
  readonly style?: StyleAttribute
}): ReactElement {
  const input = useRef<HTMLSelectElement>(null)
  const fit = props.fit ?? false
  const placeholder = props.placeholder ?? 'Input here…'
  const options: (Option | NestedOption)[] =
    typeof props.options === 'string'
      ? props.options.split(',').map((s) => {
          return {
            key: s,
            value: s,
          } as Option
        })
      : props.options ?? []
  const [{ key, value }, setValue] = useState(findValue(options, props.value))
  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(
    () => {
      setFocused(true)

      if (props.onChangeFocus) {
        props.onChangeFocus(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onBlur = useCallback(
    () => {
      setFocused(false)

      if (props.onChangeFocus) {
        props.onChangeFocus(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const val = e.target.options[e.target.selectedIndex].text

      setValue({
        key: e.target.value,
        value: val,
      })

      if (props.onChange) {
        props.onChange(e.target.value, val)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLSelectElement>) => {
      if (props.onSubmit && e.key === 'Enter') {
        props.onSubmit(key as string, value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSubmit]
  )

  const onPress = useCallback(() => {
    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }
  }, [props.tracker, props.trackerParam])

  useLayoutEffect(() => {
    if (props.autofocus && input.current) {
      input.current.focus()
    }
  }, [props.autofocus])

  // What happened if the value or options changed?
  // Let's handle that
  useUpdate(() => {
    setValue(findValue(options, props.value))
  }, [props.value, props.options])

  return (
    <Box
      row
      centering="h"
      style={[
        InputStyles.container,
        props.disabled
          ? InputStyles['container-disabled']
          : props.readonly
          ? InputStyles['container-readonly']
          : undefined,
        props.valid && InputStyles['container-valid'],
        props.valid === false && InputStyles['container-invalid'],
        focused && InputStyles['container-focused'],
        fit && InputStyles.fit,
        props.style,
      ]}
    >
      {props.prefix}
      <input
        readOnly
        tabIndex={-1}
        disabled={props.disabled}
        placeholder={placeholder || ''}
        value={value}
        {...StyleSheet.classNameAndStyle([
          InputStyles.input,
          props.disabled
            ? InputStyles['input-disabled']
            : props.readonly
            ? InputStyles['input-readonly']
            : undefined,
          props.valid && InputStyles['input-valid'],
          props.valid === false && InputStyles['input-invalid'],
          focused && InputStyles['input-focused'],
          props.input,
        ])}
      />
      {props.suffix ?? (
        <Icon
          name="caret.down"
          size={24}
          color={
            focused
              ? Colors.primary
              : props.disabled
              ? Colors.border
              : undefined
          }
        />
      )}
      <select
        data-testid={props.testId}
        ref={input}
        disabled={props.disabled || props.readonly}
        value={key}
        autoFocus={props.autofocus}
        onClick={onPress}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={props.tabindex}
        {...StyleSheet.classNameAndStyle([Styles.select])}
      >
        {placeholder !== false && (
          <option disabled value={''}>
            {placeholder}
          </option>
        )}
        {options.map((option, i) => {
          if ((option as NestedOption).options) {
            return (
              <optgroup key={i} label={String(option.value)}>
                {(option as NestedOption).options.map((o, n) => {
                  return (
                    <option
                      key={n}
                      value={o.key !== undefined ? String(o.key) : undefined}
                      disabled={o.disabled}
                    >
                      {o.value}
                    </option>
                  )
                })}
              </optgroup>
            )
          } else {
            return (
              <option
                key={i}
                value={
                  (option as Option).key !== undefined
                    ? String((option as Option).key)
                    : undefined
                }
                disabled={(option as Option).disabled}
              >
                {(option as Option).value}
              </option>
            )
          }
        })}
      </select>
    </Box>
  )
}
