import { ReactElement, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router'

export function Page<
  Props extends {
    [k: string]: unknown
  }
>(
  renderer: (
    props: Props & {
      location: Omit<RouteComponentProps['location'], 'state'>
      match: Omit<RouteComponentProps['match'], 'params'>
      subroutes?: ReactNode
    }
  ) => ReactElement | null
) {
  return renderer
}
