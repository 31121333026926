import { ModalAnimation, ModalAnimationConfig, StackConfig } from '../../types'
import { ManagerModel } from '../manager'

function createAnimation(animation: ModalAnimation): ModalAnimationConfig {
  switch (animation) {
    case 'PAN_TOP':
      return [
        {
          transform: `translateY(100px)`,
          opacity: 0,
        },
        {
          transform: `translateY(0)`,
          opacity: 1,
        },
      ]
    case 'PAN_BOTTOM':
      return [
        {
          transform: `translateY(-100px)`,
          opacity: 0,
        },
        {
          transform: `translateY(0)`,
          opacity: 1,
        },
      ]
    case 'PAN_LEFT':
      return [
        {
          transform: `translateX(150px)`,
          opacity: 0,
        },
        {
          transform: `translateY(0)`,
          opacity: 1,
        },
      ]
    case 'SCALE':
    default:
      return [
        {
          transform: `scale(1.2)`,
          opacity: 0,
        },
        {
          transform: `scale(1)`,
          opacity: 1,
        },
      ]
  }
}

/**
 * State:
 * 0: component is on spotlight
 * 1 ... n : component is n behind the spotlight
 */
export class StackModel<
  T extends unknown,
  Config extends StackConfig<T> = StackConfig<T>
> extends ManagerModel<Config, unknown> {
  private id = 1

  public add(config: T) {
    const id = this.id++

    this.properties.stack.forEach((stack) => {
      stack.state++
    })

    this.set(
      'stack',
      new Map(this.properties.stack).set(id, {
        id,
        config,
        state: 0,
      })
    )

    return id
  }

  public remove(id?: number) {
    if (this.properties.stack.size > 0) {
      id =
        id ??
        (this.properties.fromLast
          ? Array.from(this.properties.stack.keys()).pop()
          : Array.from(this.properties.stack.keys()).shift())

      if (id && this.properties.stack.has(id)) {
        this.properties.stack.delete(id)

        this.properties.stack.forEach((stack) => {
          if (stack.id < (id as number)) {
            stack.state--
          }
        })

        this.set('stack', new Map(this.properties.stack))
      }
    }

    return true
  }
}

export class AnimationStackModel<
  T extends {
    animation?: ModalAnimation | ModalAnimationConfig
  }
> extends StackModel<
  T & {
    animation: ModalAnimationConfig
  },
  StackConfig<
    T & {
      animation: ModalAnimationConfig
    }
  >
> {
  public add(config: T) {
    // const [props, set] = useSpring(() => ({opacity: 0}))
    // const anim =
    const id = super.add({
      ...config,
      animation: Array.isArray(config.animation)
        ? config.animation
        : createAnimation(config.animation ?? 'PAN_TOP'),
    })

    return id
  }
}
