import React, {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA } from '../../../libs/com'
import { Colors } from '../../../constants'
import { CommonStyles } from '../../../styles'
import { IconName, StyleAttribute } from '../../../types'

import { Box } from '../../blocks/box'
import { Icon } from '../../blocks/icon'
import { Text } from '../../blocks/text'

import Styles from './styles'

function getCheckColor(disabled?: boolean) {
  if (disabled) {
    return Colors.palette('grey')[10]
  } else {
    return Colors.white
  }
}

function getIconColor(disabled?: boolean) {
  if (disabled) {
    return Colors.palette('grey')[7]
  } else {
    return Colors.palette('grey')[8]
  }
}

function getTextColor(disabled?: boolean, valid?: boolean) {
  if (valid === false) {
    return Colors.palette('red')[8]
  } else if (disabled) {
    return Colors.palette('grey')[7]
  } else {
    return Colors.palette('grey')[9]
  }
}

export function Checkbox({
  onChange,
  ...props
}: {
  readonly flex?: ComponentProps<typeof Box>['flex']
  readonly active?: boolean
  readonly label?: string | ReactNode
  readonly icon?: IconName
  readonly autofocus?: boolean
  readonly readonly?: boolean
  readonly disabled?: boolean
  readonly valid?: boolean
  readonly description?: string | ReactNode
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChange?: (active: boolean) => void
  readonly style?: StyleAttribute
} & (
  | {
      readonly theme: 'outlined'
      readonly size?: 64 | 50 | 44
    }
  | {
      readonly theme?: 'default'
      readonly size?: 50 | 44
    }
)) {
  const box = useRef<HTMLDivElement>(null)
  const actionable = !props.disabled && !props.readonly
  const theme = props.theme ?? 'default'
  const size = props.size ?? (theme === 'default' ? 50 : 64)
  const [active, setActive] = useState(props.active)
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const onBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const onToggle = useCallback(() => {
    if (actionable) {
      setActive(!active)

      // Send analytics to GA
      if (props.tracker) {
        GA.sendEvent('fill_input', {
          input_name: props.tracker,
          ...(props.trackerParam ?? {}),
        })
      }

      if (onChange) {
        onChange(!active)
      }
    }
  }, [active, actionable, onChange, props.tracker, props.trackerParam])

  useLayoutEffect(() => {
    if (props.autofocus && box.current && actionable) {
      box.current.focus()
    }
  }, [props.autofocus, actionable])

  useEffect(() => {
    if (active !== props.active) {
      setActive(!!props.active)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active])

  return (
    <Box style={props.style}>
      <Box
        row
        flex={props.flex}
        centering="v"
        ref={box}
        accessible={actionable}
        tabindex={props.disabled ? -1 : 0}
        onFocus={onFocus}
        onBlur={onBlur}
        onPress={onToggle}
        onHover={props.disabled ? undefined : setHovered}
        style={[
          Styles.container,
          CommonStyles.transition,
          theme === 'outlined' && Styles.outlined,
          {
            minHeight: size,
          },
          focused && Styles.focused,
          hovered && Styles.hovered,
          props.valid === false && Styles.invalid,
        ]}
      >
        <Box
          centering
          style={[
            Styles.icon,
            CommonStyles.transition,
            focused && Styles.focused,
            hovered && Styles.hovered,
            active && Styles.active,
            props.disabled && Styles.disabled,
            active && props.disabled && Styles.activeDisabled,
          ]}
        >
          {active ? (
            <Icon
              name={'check'}
              size={13.3}
              color={getCheckColor(props.disabled)}
            />
          ) : (
            false
          )}
        </Box>
        {props.icon ? (
          <Icon
            name={props.icon}
            size={20}
            color={getIconColor(props.disabled)}
            style={Styles.label}
          />
        ) : (
          false
        )}
        {props.label ? (
          typeof props.label === 'string' ? (
            <Text
              size="small"
              color={getTextColor(props.disabled)}
              style={Styles.label}
            >
              {props.label}
            </Text>
          ) : (
            <Box flex style={Styles.label}>
              {props.label}
            </Box>
          )
        ) : (
          false
        )}
      </Box>
      {props.description ? (
        typeof props.description === 'string' ? (
          <Text
            size="tiny"
            color={getTextColor(props.disabled, props.valid)}
            style={Styles.note}
          >
            {props.description}
          </Text>
        ) : (
          <Box flex style={Styles.note}>
            {props.description}
          </Box>
        )
      ) : (
        false
      )}
    </Box>
  )
}
