import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'
import type { FirebaseApp } from 'firebase/app'
import { Defaults } from '../../../constants'
import { CommonHelper } from '../../../helpers'

import { Logger } from '../logger'
import { Firebase } from '../firebase'
import { Store } from '../store'

const USER_STORAGE_SLUG = 'manager/user'

interface UserInfo {
  id: string
  name: string
  token: string
  refreshToken: string
  expiredAt: number
  roles: string[]
}

async function getParams(
  sendDefault = false,
  ...data: Array<Record<string, unknown>>
) {
  if (sendDefault) {
    const { id } = await (Store.load(USER_STORAGE_SLUG).catch(
      () => ({})
    ) as Promise<UserInfo>)

    return CommonHelper.object.defined({
      userId: id || undefined,
      timestamp: CommonHelper.date.format('yyyy-mm-dd hh:mm:ss'),
      ...data.reduce(CommonHelper.reduce.object, {}),
    })
  } else {
    return CommonHelper.object.defined(
      data.reduce(CommonHelper.reduce.object, {})
    )
  }
}

class GAModel {
  private analytics: Analytics | undefined = undefined

  constructor() {
    // Check if firebase is available
    if (Firebase) {
      this.reinit(Firebase)
    } else {
      Logger.log(
        'WARN',
        'GA disabled because Firebase is not ready yet. Try to call GA.reinit(<Firebase Instance>) to enable GA.'
      )
    }
  }

  public reinit(firebase: FirebaseApp) {
    if (this.analytics) {
      Logger.log('WARN', 'GA is already initialized.')
      return
    }

    this.analytics = getAnalytics(firebase)
  }

  public async sendPageView(
    path: string,
    title?: string,
    referrer?: string,
    sendDefaultParams = false
  ) {
    if (Defaults.DEBUG) {
      Logger.log(
        'DEBUG',
        'GA: PageView',
        await getParams(sendDefaultParams, {
          page_title: title,
          page_location: window?.location.href,
          page_path: path,
          page_referrer: referrer,
        })
      )
    }

    if (this.analytics) {
      logEvent(
        this.analytics,
        'page_view',
        await getParams(sendDefaultParams, {
          page_title: title,
          page_location: window?.location.href,
          page_path: path,
          page_referrer: referrer,
        })
      )
    } else {
      Logger.log(
        'WARN',
        'Firebase analytics not available, try to send via window.gtag'
      )

      window?.gtag?.(
        'event',
        'page_view',
        await getParams(sendDefaultParams, {
          page_title: title,
          page_location: window?.location.href,
          page_path: path,
          page_referrer: referrer,
        })
      )
    }
  }

  public async sendClick(
    linkName: string,
    additionalParam: Record<string, string> = {},
    sendDefaultParams = false
  ) {
    if (Defaults.DEBUG) {
      Logger.log(
        'DEBUG',
        'GA: Click',
        'link_name:',
        linkName,
        await getParams(sendDefaultParams, additionalParam)
      )
    }

    if (this.analytics) {
      logEvent(
        this.analytics,
        'click_clickable',
        await getParams(
          sendDefaultParams,
          {
            link_name: linkName,
          },
          additionalParam
        )
      )
    } else {
      Logger.log(
        'WARN',
        'Firebase analytics not available, try to send via window.gtag'
      )

      window?.gtag?.(
        'event',
        'click_clickable',
        await getParams(
          sendDefaultParams,
          {
            link_name: linkName,
          },
          additionalParam
        )
      )
    }
  }

  public async sendEvent(
    name: string,
    params: {
      [k: string]: unknown
    },
    sendDefaultParams = false
  ) {
    if (Defaults.DEBUG) {
      Logger.log(
        'DEBUG',
        'GA: Event',
        name,
        await getParams(sendDefaultParams, params)
      )
    }

    if (this.analytics) {
      logEvent(this.analytics, name, await getParams(sendDefaultParams, params))
    } else {
      Logger.log(
        'WARN',
        'Firebase analytics not available, try to send via window.gtag'
      )

      window?.gtag?.('event', name, await getParams(sendDefaultParams, params))
    }
  }
}

export const GA = new GAModel()
