import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.default,
    paddingRight: Sizes.margin.default,
    borderWidth: 1,
    borderColor: Colors.palette('grey')[0],
    boxShadow: `0 2px 2px ${Colors.opacity(Colors.black, 0.12)}`,
  },
  normal: {
    foreground: Colors.black,
    style: {
      backgroundColor: Colors.white,
    },
  },
  active: {
    foreground: Colors.primary,
    style: {
      backgroundColor: Colors.palette('grey')[0],
    },
  },
  disabled: {
    foreground: Colors.palette('grey')[3],
    style: {
      backgroundColor: Colors.palette('grey')[2],
    },
  },
  loading: {
    foreground: Colors.black,
    style: {
      backgroundColor: Colors.white,
    },
  },
}
