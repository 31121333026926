/* eslint-disable prettier/prettier */
import React from 'react'
import { AlertConfig } from '../../../types'

import { Box, Text, ScrollView } from '../../../modules'
import { Icon } from '../icon'
import { ButtonPart, ButtonPartDS } from './_button'

import Styles from './styles'

export const PagePrompt = (
  props: Readonly<AlertConfig> & {
    readonly onClose: () => void
  }
) => {
  const direction = props.direction ?? 'ROW'
  const icon = props.icon
  return (
    <Box style={[Styles.container, props.style]}>
      <Box flex />
      <ScrollView
        flex={false}
        style={Styles.prompt}
        contentContainerStyle={Styles.content}
      >
        {icon && (
          <Box style={Styles.icon}>
            <Icon name={icon.name} color={icon.color} size={30} />
          </Box>
        )}
        <Text size="heading-3" style={Styles.padder}>
          {props.title}
        </Text>
        <Box style={Styles.message}>
          {typeof props.message === 'string' ? (
            <Text size="regular">{props.message}</Text>
          ) : (
            props.message
          )}
        </Box>
        <Box row={direction === 'ROW'} centering="v">
          {props.buttonStyle === 'ds'
            ? props.actions.map((action, i) => {
                return (
                  <ButtonPartDS
                    key={i}
                    type={action.type}
                    title={action.title}
                    block={direction === 'COLUMN'}
                    onPress={() => {
                      if (action.onPress) {
                        action.onPress()
                      }

                      props.onClose()
                    }}
                    style={[
                      Styles.action,
                      i !== props.actions.length - 1 && direction === 'COLUMN'
                        ? Styles.padColumn
                        : Styles.padRow,
                    ]}
                  />
                )
              })
            : props.actions.map((action, i) => {
                return (
                  <ButtonPart
                    key={i}
                    type={action.type}
                    title={action.title}
                    block={direction === 'COLUMN'}
                    onPress={() => {
                      if (action.onPress) {
                        action.onPress()
                      }

                      props.onClose()
                    }}
                    style={[
                      Styles.action,
                      i !== props.actions.length - 1 && direction === 'COLUMN'
                        ? Styles.padColumn
                        : Styles.padRow,
                    ]}
                  />
                )
              })}
        </Box>
      </ScrollView>
    </Box>
  )
}
