import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: Sizes.margin.compact,
  },

  outlined: {
    background: Colors.white,
    borderWidth: 1,
    borderColor: Colors.palette('grey')[11],
    padding: Sizes.margin.default,
  },

  icon: {
    height: 18,
    width: 18,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.palette('grey')[6],
  },

  invalid: {
    borderColor: Colors.palette('red')[8],
  },

  focused: {
    borderColor: Colors.primary,
  },

  label: {
    paddingLeft: Sizes.margin.compact,
  },

  hovered: {
    background: Colors.palette('blue')[12],
    borderColor: Colors.palette('blue')[13],
  },

  active: {
    borderColor: Colors.palette('blue')[3],
    background: Colors.palette('blue')[2],
  },

  disabled: {
    borderColor: Colors.palette('grey')[10],
    background: Colors.palette('grey')[11],
  },

  activeDisabled: {
    borderColor: Colors.palette('grey')[7],
    background: Colors.palette('grey')[7],
  },

  note: {
    marginTop: 2,
  },
})
