import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.thick + Sizes.margin.pad,
    paddingRight: Sizes.margin.thick + Sizes.margin.pad,
    boxShadow: `0 2px 2px ${Colors.opacity(Colors.black, 0.12)}`,
  },
  normal: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('blue')[2],
    },
  },
  active: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('blue')[3],
    },
  },
  disabled: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.opacity(Colors.palette('blue')[2], 0.5),
    },
  },
  loading: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('blue')[3],
    },
  },
}
