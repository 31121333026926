import { DependencyList, useEffect, useRef } from 'react'

/**
 * Use this hook to auto subscribe to any property changes. i.e. You want to
 * subscribe to app state change, then use this hook with AppManager,
 * so any changes on app state will be broadcasted to the component.
 */
export function useMount(fn: () => (() => void) | void, keys?: DependencyList) {
  const mount = useRef(false)

  useEffect(() => {
    if (!mount.current) {
      mount.current = true
      return fn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys ?? [])
}
