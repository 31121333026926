import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA, StyleSheet } from '../../../libs/com'
import { useUpdate } from '../../../hooks'
import { Colors } from '../../../constants'
import { Lang, StyleAttribute } from '../../../types'
import { InputStyles } from '../../../styles'

import { Box } from '../box'
import { Button } from '../button'
import { Icon } from '../icon'

import Styles from './style'

async function getBase64(files: FileList) {
  return Promise.resolve(files).then((fs) => {
    return Promise.all(
      Array.from(fs).map(async (file) => {
        return new Promise<string>((res, rej) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => res(reader.result as string)
          reader.onerror = (error) => rej(error)
        })
      })
    )
  })
}

function getClearableButtonText(lang?: Lang) {
  switch (lang) {
    case 'en':
    default:
      return 'Remove'
    case 'id':
      return 'Hapus'
  }
}

function getBrowseButtonText(lang?: Lang) {
  switch (lang) {
    case 'en':
    default:
      return 'Browse'
    case 'id':
      return 'Pilih file'
  }
}

function getDefaultPlaceholder(lang?: Lang) {
  switch (lang) {
    case 'en':
    default:
      return 'Select a file…'
    case 'id':
      return 'Upload file'
  }
}

export function InputFile(props: {
  readonly testId?: string
  readonly value?: string
  readonly lang?: Lang
  readonly fit?: boolean
  readonly autofocus?: boolean
  readonly placeholder?: string
  readonly prefix?: React.ReactNode
  readonly suffix?: React.ReactNode
  readonly accept?: string
  readonly multiple?: boolean
  readonly clearable?: boolean | 'button'
  readonly valid?: boolean
  readonly readonly?: boolean
  readonly disabled?: boolean
  readonly tabindex?: number
  readonly button?: string
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onChange?: (
    files: FileList | undefined,
    base64?: Promise<string[]>
  ) => void
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onSubmit?: (
    date: FileList | undefined,
    base64?: Promise<string[]>
  ) => void
  readonly input?: StyleAttribute
  readonly style?: StyleAttribute
  readonly container?: StyleAttribute
}): ReactElement {
  const input = useRef<HTMLInputElement>(null)
  const fit = props.fit ?? false
  const clearable = props.clearable ?? true
  const placeholder = props.placeholder ?? getDefaultPlaceholder(props.lang)
  const [clearer, setClearer] = useState(0)
  const [value, setValue] = useState(props.value || '')
  const [files, setFiles] = useState<FileList | null>(null)
  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(
    () => {
      setFocused(true)

      if (props.onChangeFocus) {
        props.onChangeFocus(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onBlur = useCallback(
    () => {
      setFocused(false)

      if (props.onChangeFocus) {
        props.onChangeFocus(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onClear = useCallback(() => {
    setClearer(clearer + 1)
    setFiles(null)
    setValue('')
    if (props.onChange) {
      props.onChange(undefined, undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearer, props.onChange])

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.onSubmit && e.key === 'Enter') {
        if (props.onSubmit.length > 1) {
          props.onSubmit(
            files || undefined,
            files ? getBase64(files) : undefined
          )
        } else {
          props.onSubmit(files || undefined)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSubmit, files]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFiles(e.currentTarget.files)
      setValue(
        e.currentTarget.files
          ? Array.from(e.currentTarget.files)
              .map((f) => f.name)
              .join(', ')
          : ''
      )
      if (props.onChange) {
        props.onChange(
          e.currentTarget.files || undefined,
          e.currentTarget.files ? getBase64(e.currentTarget.files) : undefined
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  const onPress = useCallback(() => {
    // Send analytics to GA
    if (props.tracker) {
      GA.sendEvent('fill_input', {
        input_name: props.tracker,
        ...(props.trackerParam ?? {}),
      })
    }

    if (input.current) {
      input.current.click()
    }
  }, [props.tracker, props.trackerParam])

  // Update focus when props autofocus changed
  useLayoutEffect(() => {
    if (props.autofocus) {
      setFocused(true)
    }
  }, [props.autofocus])

  // Update when receiving new preview link
  useUpdate(() => {
    setValue(props.value ?? '')
  }, [props.value])

  return (
    <Box row style={[fit && InputStyles.fit, props.style]}>
      <Box
        flex
        row
        centering="h"
        style={[
          InputStyles.container,
          props.disabled
            ? InputStyles['container-disabled']
            : props.readonly
            ? InputStyles['container-readonly']
            : undefined,
          props.valid && InputStyles['container-valid'],
          props.valid === false && InputStyles['container-invalid'],
          focused && InputStyles['container-focused'],
          props.container,
        ]}
      >
        {props.prefix}
        <input
          readOnly
          tabIndex={-1}
          disabled={props.disabled}
          placeholder={placeholder}
          value={value}
          {...StyleSheet.classNameAndStyle([
            InputStyles.input,
            props.disabled
              ? InputStyles['input-disabled']
              : props.readonly
              ? InputStyles['input-readonly']
              : undefined,
            props.valid && InputStyles['input-valid'],
            props.valid === false && InputStyles['input-invalid'],
            focused && InputStyles['input-focused'],
            props.input,
          ])}
        />
        {props.suffix ??
          (value && !props.readonly && !props.disabled && clearable === true ? (
            <Box centering style={Styles.clearer} onPress={onClear}>
              <Icon name="decline" size={12} color={Colors.border} />
            </Box>
          ) : (
            false
          ))}
        <input
          type="file"
          data-testid={props.testId}
          key={clearer}
          ref={input}
          readOnly={props.readonly}
          disabled={props.disabled}
          autoFocus={props.autofocus}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onChange={onChange}
          tabIndex={props.tabindex}
          accept={props.accept}
          multiple={props.multiple}
          {...StyleSheet.classNameAndStyle([Styles.input])}
        />
      </Box>
      <Button
        theme="border-text-secondary"
        size="input"
        style={Styles.button}
        onPress={onPress}
      >
        {props.button ?? getBrowseButtonText(props.lang)}
      </Button>
      {/* Display clearable as button when type is 'button' */}
      {value && !props.readonly && !props.disabled && clearable === 'button' ? (
        <Button
          theme="border-text-secondary"
          size="input"
          icon={{
            name: 'trash',
            size: 18,
          }}
          style={Styles.button}
          onPress={onClear}
        >
          {getClearableButtonText(props.lang)}
        </Button>
      ) : (
        false
      )}
    </Box>
  )
}
