import { StyleSheet } from '../../../libs/com'
import { Colors } from '../../../constants'

export default StyleSheet.create({
  page: {
    backgroundColor: Colors.border,
    width: '100vw',
    minHeight: '100vh',
  },

  flex: {
    width: 'auto',
    minHeight: 'auto',
  },

  row: {
    flexDirection: 'row',
  },

  content: {
    backgroundColor: Colors.white,
  },

  fixed: {
    position: 'fixed',
    width: '100vw',
    zIndex: 4,
    left: 0,
  },

  header: {
    top: 0,
  },

  footer: {
    bottom: 0,
  },

  invisible: {
    opacity: 0,
  },
})
