import React from 'react'
import { useTransition } from 'react-spring'

import { Box } from '../../blocks'

import Styles from './styles'

export const OverlayPart = (props: { readonly active: boolean }) => {
  const overlay = useTransition(props.active, null, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  return (
    <React.Fragment>
      {overlay.map(({ item, props: style, key }) => {
        return (
          item && (
            <Box
              animated
              key={key}
              style={[Styles.transitioner, Styles.overlay, style]}
            />
          )
        )
      })}
    </React.Fragment>
  )
}
