export default [
  '#89D0F2', // 0  blue-10
  '#E6F2FF', // 1  blue-20
  '#0087C8', // 2  blue-30
  '#00699C', // 3  blue-40
  '#F3FAFE', // 4
  '#00486A', // 5
  '#E6F3FA', // 6
  '#1D5F96', // 7
  '#5886FD', // 8
  '#BDF3E9', // 9
  '#0B5FEF', // 10 Checkbox blue
  '#3C7FEE', // 11 Inner Border
  '#F2F8FE', // 12 Primary/blue-10
  '#69A2FA', // 13 Inner Border
  '#054BCC', // 14
  '#103178', // 15
  '#EBFAFF', // 16
  '#103173', // 17 Action/Pressed
]
