import { StyleSheet } from '../../../libs/com'
import { Sizes } from '../../../constants'

export default StyleSheet.create({
  container: {
    height: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'hidden',
  },

  textarea: {
    padding: [Sizes.margin.half, Sizes.margin.default],
    minHeight: 120,
    resize: 'none',
  },

  clearer: {
    marginLeft: Sizes.margin.half,
  },

  counterInfo: {
    bottom: 0,
    right: 0,
    position: 'absolute',
    margin: [Sizes.margin.pad, Sizes.margin.half],
  },

  resizable: {
    resize: 'vertical',
  },
})
