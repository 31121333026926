import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { StringHelper } from '../../../helpers'
import { CurrencyRegex } from '../../../constants'

import { Box } from '../../blocks'
import { InputText } from '../../blocks'
import { Text } from '../../blocks'

import Styles from './styles'

type Formats =
  | 'CURRENCY'
  | 'EMAIL'
  | 'UPPER'
  | 'TEL'
  | 'NOOP'
  | 'NUMBER'
  | 'NPWP'
  | 'SEMESTER'
  | 'IPK'

const phones = {
  '+62': {
    country: 'IDN',
    tester: /^\+62/,
  },
  '021': {
    country: 'IDN',
    tester: /^021/,
  },
  '0': {
    country: 'IDN',
    tester: /^0/,
  },
}

// const urls = {
// 	'https://': /^https:\/\//,
// 	'http://': /^http:\/\//,
// 	'ftp://': /^ftp:\/\//,
// }

const phoneCodes = Object.keys(phones) as (keyof typeof phones)[]
const phoneCodeRegex = phoneCodes.map((code) => {
  return phones[code].tester
})

function phoneCodeIndex(input: string) {
  return phoneCodeRegex.findIndex((regex) => {
    return regex.test(input)
  })
}

function phonePrefix(input: string, index = phoneCodeIndex(input + '')) {
  return (input && index > -1 && phoneCodes[index]) || ''
}

function addDot(val: string | number) {
  return val ? `${val}.` : ''
}

function addDash(val: string | number) {
  return val ? `${val}-` : ''
}

export function toNPWP(val: string) {
  return val.replace(
    /(\d{2})(\d{3})?((\d{3})(\d{1})?)?(\d{3})?(\d{3})?/,
    function (...params) {
      return params
        .slice(1, 8)
        .map((g, i) => {
          if (i === 2) {
            return ``
          } else if (i === 4) {
            return addDash(g)
          } else if (i === 6) {
            return g
          } else {
            return addDot(g)
          }
        })
        .filter((g) => !!g)
        .join('')
    }
  )
}

const FORMATS: {
  [k in Formats]: {
    formatter: (val?: string | number) => string
    reverter: (val: string) => string
    prefix: (prefix?: ReactNode) => ReactNode | void
  }
} = {
  CURRENCY: {
    formatter: (val) => {
      return `${StringHelper.safeToString(val).replace(CurrencyRegex, '$1.')}`
    },
    reverter: (val) => {
      return StringHelper.safeToString(val)
        .replace(/\./g, '')
        .replace(/ /g, '')
        .replace(/([-=_+!@#$%^&*(){}:;"',.<>?|/\\[\]])/g, '')
        .replace(/[a-z]/gi, '')
    },
    // eslint-disable-next-line react/display-name
    prefix: (prefix) => {
      return (
        prefix ?? (
          <Box centering="v" style={Styles.prefix}>
            <Text size="small">Rp</Text>
          </Box>
        )
      )
    },
  },
  NUMBER: {
    formatter: (val) => {
      return `${StringHelper.safeToString(val).replace(/[^0-9.]/g, '')}`
    },
    reverter: (val) => val,
    prefix: (p) => p,
  },
  TEL: {
    formatter: (val) => {
      const value = StringHelper.safeToString(val)
      const prefix = phonePrefix(value)

      return `${prefix ? `${prefix} ` : ''}${value
        .replace(prefix, '')
        .replace(/(\d{3})(\d{1,4})(\d{1,4})/, '$1-$2-$3')}`
    },
    reverter: (val) => {
      return StringHelper.safeToString(val)
        .replace(/ /g, '')
        .replace(/[^0-9+\-\s]/g, '')
    },
    prefix: (p) => p,
  },
  EMAIL: {
    formatter: (val) => {
      return StringHelper.safeToString(val).toLowerCase()
    },
    reverter: (val) => val,
    prefix: (p) => p,
  },
  UPPER: {
    formatter: (val) => {
      return StringHelper.safeToString(val).toUpperCase()
    },
    reverter: (val) => val,
    prefix: (p) => p,
  },
  NOOP: {
    formatter: (val) => StringHelper.safeToString(val),
    reverter: (val) => val,
    prefix: (p) => p,
  },
  NPWP: {
    formatter: (val) => {
      const value = StringHelper.safeToString(val)
      return toNPWP(value)
    },
    reverter: (val) => {
      return StringHelper.safeToString(val)
        .replace(/-/g, '')
        .replace(/\./g, '')
        .replace(/ /g, '')
        .replace(/([-=_+!@#$%^&*(){}:;"',.<>?|/\\[\]])/g, '')
        .replace(/[a-z]/gi, '')
    },
    prefix: (prefix) => prefix,
  },
  SEMESTER: {
    formatter: (val) => {
      const value = StringHelper.safeToString(val)

      return parseInt(value) >= 1 && parseInt(value) <= 14 ? value : ''
    },
    reverter: (val) => {
      const formattedString = StringHelper.safeToString(val)
        .replace(/\./g, '')
        .replace(/ /g, '')
        .replace(/([-=_+!@#$%^&*(){}:;"',.<>?|/\\[\]])/g, '')
        .replace(/[a-z]/gi, '')

      return formattedString.length > 2
        ? formattedString.slice(0, -1)
        : formattedString
    },
    prefix: (prefix) => prefix,
  },
  IPK: {
    formatter: (val) => {
      const value = StringHelper.safeToString(val)
      return value.replace(/(\d)(\d{2})$/, '$1.$2')
    },
    reverter: (val) => {
      const formattedString = StringHelper.safeToString(val)
        .replace(/([-=_+!@#$%^&*(){}:;"',.<>?|/\\[\]])/g, '')
        .replace(/[a-z]/gi, '')

      return formattedString.length > 3
        ? formattedString.slice(0, -1)
        : formattedString
    },
    prefix: (p) => p,
  },
}

/**
 * Input Formatted, format input into values we desire.
 * onChange should return pristine (unformatted) value and formatted value. Instead
 * of just value. That's why we need to overwrite onChange
 */
export const FormattedPart = (
  props: Omit<
    React.ComponentProps<typeof InputText>,
    'defaultValue' | 'onChange'
  > & {
    readonly mask?: Formats
    readonly forceFormat?: boolean
    readonly formatValue?: boolean
    readonly boxStyle?: boolean
    readonly onChange?: (
      value: string,
      formatted: string,
      key?: string | number | boolean
    ) => void
  }
): ReactElement => {
  const {
    mask,
    value: propsVal,
    prefix: propsPrefix,
    onChange: propsOnChange,
    ...inputTextProps
  } = props
  const forceFormat =
    props.forceFormat ??
    (mask === 'EMAIL' || mask === 'UPPER' || mask === 'NUMBER')
  const formatValue =
    props.formatValue ??
    (mask === 'EMAIL' || mask === 'UPPER' || mask === 'NUMBER')
  const { formatter, reverter, prefix } =
    FORMATS[props.mask ?? 'NOOP'] || FORMATS.NOOP
  const [focused, setFocused] = useState(false)
  const [values, setValues] = useState({
    value: propsVal,
    formatted: formatter(propsVal),
  })

  const onChangeFocus = useCallback(
    (f: boolean) => {
      setFocused(f)

      if (props.onChangeFocus) {
        props.onChangeFocus(f)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeFocus]
  )

  const onChange = useCallback(
    (val: string, key?: string | number | boolean) => {
      const value = reverter(val)
      const formatted = formatter(value)

      setValues({
        value: formatValue ? formatted : value,
        formatted,
      })

      if (propsOnChange) {
        propsOnChange(formatValue ? formatted : value, formatted, key)
      }
    },
    [formatter, reverter, propsOnChange, formatValue]
  )

  useEffect(() => {
    setValues({
      value: propsVal,
      formatted: formatter(propsVal),
    })
  }, [propsVal, formatter])

  return props.boxStyle ? (
    <InputText
      {...inputTextProps}
      input={Styles.inputBox}
      style={Styles.box}
      prefix={prefix(propsPrefix) || undefined}
      onChangeFocus={onChangeFocus}
      onChange={onChange}
      value={focused && !forceFormat ? values.value : values.formatted}
    />
  ) : (
    <InputText
      {...inputTextProps}
      prefix={prefix(propsPrefix) || undefined}
      onChangeFocus={onChangeFocus}
      onChange={onChange}
      value={focused && !forceFormat ? values.value : values.formatted}
    />
  )
}
