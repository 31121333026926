import React, { useRef } from 'react'
import { useUpdate } from '../../../hooks'
import { Colors } from '../../../constants'
import { StyleAttribute, Option } from '../../../types'

import { Box } from '../box'
import { Text } from '../text'

import Styles from './styles'

export const SuggestionPart = (props: {
  readonly selectable?: boolean
  readonly value: Option
  readonly focused?: boolean
  readonly shouldScrollIntoView?: boolean
  readonly onPress?: (opt: Option) => void
  readonly onHover?: (hovered: boolean) => void
  readonly style?: StyleAttribute
}) => {
  const div = useRef<HTMLDivElement>(null)
  useUpdate(() => {
    if (props.focused && div.current && props.shouldScrollIntoView) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((div.current as any).scrollIntoViewIfNeeded) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(div.current as any).scrollIntoViewIfNeeded()
      } else {
        div.current.scrollIntoView()
      }
    }
  }, [props.focused, props.shouldScrollIntoView])

  return (
    <Box
      ref={div}
      accessible={props.selectable}
      onHover={props.onHover}
      onPress={() => props.onPress && props.onPress(props.value)}
      style={[Styles.suggestion, props.focused && Styles.hover, props.style]}
    >
      <Text
        size="small"
        color={props.focused ? Colors.black : Colors.palette('grey')[4]}
        weight={props.focused ? 'bold' : undefined}
      >
        {props.value.value}
      </Text>
    </Box>
  )
}
