import { useRef } from 'react'

let uniqueId = 0
const getUniqueId = () => uniqueId++

export function useComponentId() {
  const idRef = useRef<number | null>(null)

  if (idRef.current === null) {
    idRef.current = getUniqueId()
  }

  return idRef.current as number
}
