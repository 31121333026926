import React, { ComponentProps, isValidElement, ReactNode } from 'react'
import { StyleAttribute } from '../../../types'

import { Label } from '../../blocks/label'

import { State } from './__helper'
import { MessagePart } from './_message'
import Styles from './styles'
import { STATE } from '../../../constants'

function Description(props: {
  readonly description:
    | string
    | (State & {
        style?: StyleAttribute
      })
    | ReactNode
}) {
  if (props.description) {
    if (typeof props.description === 'string') {
      return <MessagePart message={props.description} />
    } else if (isValidElement(props.description)) {
      return <MessagePart children={props.description} />
    } else {
      return (
        <MessagePart
          message={(props.description as State).message}
          state={(props.description as State).state}
          icon={(props.description as State).icon}
          children={(props.description as State).children}
          loading={(props.description as State).loading}
          style={
            (props.description as State & { style?: StyleAttribute }).style
          }
        />
      )
    }
  }

  return null
}

export const ContainerPart = (
  props: Omit<ComponentProps<typeof Label>, 'description'> & {
    readonly loading?: boolean
    readonly description?:
      | string
      | (State & {
          style?: StyleAttribute
        })
      | ReactNode
    readonly hideStates?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    // readonly children?: React.ReactNode
    readonly states?: State[]
    readonly boxStyle?: boolean
    readonly state?: STATE
    // readonly style?: StyleAttribute
  }
) => {
  const {
    loading,
    disabled,
    readonly,
    description,
    states,
    hideStates,
    children,
    style,
    boxStyle,
    ...labelProps
  } = props

  return (
    <Label
      style={
        boxStyle
          ? [
              Styles.inputWrapper,
              style,
              disabled && Styles.inputDisabled,
              props.state === STATE.ERROR && Styles.inputInvalid,
            ]
          : style
      }
      {...labelProps}
    >
      {children}
      {!disabled && !readonly && !hideStates
        ? (states ?? []).map((s, i) => {
            return s.message || s.children ? (
              <MessagePart
                key={i}
                state={s.state}
                message={s.message}
                children={s.children}
                icon={s.icon}
                loading={s.loading ?? loading}
                style={boxStyle ? { marginLeft: 0 } : null}
              />
            ) : (
              false
            )
          })
        : false}
      <Description description={description} />
    </Label>
  )
}
