/* eslint-disable prettier/prettier */
import { TextBlockFactory } from '../../ds/text/factory'
import '../../ds/text/font/font.css'

export const Text = TextBlockFactory(
  {
    sans: {
      family: 'Plus Jakarta Sans, sans-serif',
      weights: ['normal', 'bold'],
      sizes: {
        mini: {
          fontSize: 10,
          fontWeight: 'normal',
          lineHeight: 16 / 10,
        },
        tiny: {
          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: 16 / 12,
        },
        small: {
          fontSize: 14,
          fontWeight: 'normal',
          lineHeight: 22 / 14,
        },
        regular: {
          fontSize: 16,
          fontWeight: 'normal',
          lineHeight: 24 / 16,
        },
        large: {
          fontSize: 20,
          fontWeight: 'normal',
          lineHeight: 28 / 20,
        },
        'heading-6': {
          fontSize: 12,
          fontWeight: 'bold',
          lineHeight: 16 / 12,
        },
        'heading-5': {
          fontSize: 14,
          fontWeight: 'bold',
          lineHeight: 20 / 14,
        },
        'heading-4': {
          fontSize: 16,
          fontWeight: 'bold',
          lineHeight: 24 / 16,
        },
        'heading-3': {
          fontSize: 20,
          fontWeight: 'bold',
          lineHeight: 28 / 20,
        },
        'heading-2': {
          fontSize: 24,
          fontWeight: 'bold',
          lineHeight: 32 / 24,
        },
        'heading-1': {
          fontSize: 32,
          fontWeight: 'bold',
          lineHeight: 40 / 32,
        },
        'display-big': {
          fontSize: 40,
          fontWeight: 'bold',
          lineHeight: 48 / 40,
        },
        'display-huge': {
          fontSize: 64,
          fontWeight: 'bold',
          lineHeight: 72 / 64,
        },
      },
    },
  },
  {
    family: 'sans',
    sans: {
      weight: 'normal',
      size: 'regular',
    },
  }
)
