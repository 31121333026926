import { useContext, useEffect } from 'react'
import { FormContext } from '../../../contexts'
import { STATE } from '../../../constants'

export function InputHidden(props: {
  readonly id: string
  readonly value?: boolean | string | number | Date | FileList | [Date, Date]
  readonly formatted?: string | Promise<string[]>
  readonly valueKey?: string | number | boolean
  readonly state?: STATE
}) {
  const form = useContext(FormContext)

  useEffect(() => {
    form.update(props.id, {
      value: [props.value, props.formatted, props.valueKey],
      state: props.state ?? STATE.DEFAULT,
    })
  }, [form, props.id, props.value, props.state, props.formatted, props.valueKey])

  return null
}
