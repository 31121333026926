import React from 'react'
import { StackModel } from '../../models'
import { Logger } from '../../libs/com'
import { CommonHelper } from '../../helpers'
import {
  AlertConfig,
  NotificationConfig,
  NotificationStackConfig,
} from '../../types'

export function UtilitiesContextFactory() {
  const NotificationManager = new StackModel<
    NotificationConfig,
    NotificationStackConfig
  >('Notification Manager', {
    stack: new Map(),
    fromLast: false,
    page: {
      path: '',
      rect: {
        x: 0,
        y: 0,
        height: window.innerHeight,
        width: window.innerWidth,
      },
      depth: -1,
    },
  })

  const AlertManager = new StackModel<AlertConfig>('Alert Manager', {
    stack: new Map(),
    fromLast: true,
  })

  return {
    AlertManager,
    NotificationManager,
    UtilitiesContext: React.createContext({
      alert: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        show(config: AlertConfig) {
          Logger.log(
            'WARN',
            'You are calling alert.show from outside of a utilities context.'
          )
          return CommonHelper.fn.NOOP
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hide(id?: number): boolean {
          Logger.log(
            'WARN',
            'You are calling alert.hide from outside of a utilities context.'
          )
          return true as boolean
        },
      },
      notification: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        show(config: NotificationConfig) {
          Logger.log(
            'WARN',
            'You are calling notification.show from outside of a utilities context.'
          )
          return CommonHelper.fn.NOOP
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hide(id?: number) {
          Logger.log(
            'WARN',
            'You are calling notification.hide from outside of a utilities context.'
          )
          return true as boolean
        },
      },
    }),
    useUtilitiesContext() {
      return {
        alert: {
          show(...args: Parameters<typeof AlertManager['add']>) {
            const modalId = AlertManager.add(...args)

            return () => {
              AlertManager.remove(modalId)
            }
          },
          hide(id?: number): boolean {
            return AlertManager.remove(id)
          },
        },
        notification: {
          show(...args: Parameters<typeof NotificationManager['add']>) {
            args[0].timeout =
              args[0].timeout ??
              (typeof args[0].message === 'string'
                ? Math.max(args[0].message.split(' ').length * 600, 6000)
                : 6000)

            const modalId = NotificationManager.add(...args)
            const timeout = setTimeout(
              () => NotificationManager.remove(modalId),
              args[0].timeout
            )

            return () => {
              if (timeout) {
                clearTimeout(timeout)
              }

              NotificationManager.remove(modalId)
            }
          },
          hide(id?: number) {
            return NotificationManager.remove(id)
          },
        },
      }
    },
  }
}
