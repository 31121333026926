import { FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE } from 'app/constants'
import { useEffect, useState } from 'react'
import { FirebaseRemoteConfigModel } from 'recore/models'

export const FirebaseRemoteConfig = new FirebaseRemoteConfigModel(
  FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE
)

export function useFirebaseRCValue(
  key: keyof typeof FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE
) {
  const [value, setValue] = useState(FirebaseRemoteConfig.getValue(key))

  useEffect(() => {
    let eligible = true

    FirebaseRemoteConfig.fetch()
      .then(() => FirebaseRemoteConfig.getValue(key))
      .then((res) => {
        // Prevent setting value if component has already unmounted
        if (eligible) setValue(res)
      })

    return () => {
      eligible = false
    }
  }, [key])

  return value
}
