import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  clearer: {
    marginLeft: Sizes.margin.half,
  },

  suggestions: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    borderRadius: 8,
    marginTop: Sizes.margin.default,
    padding: Sizes.margin.half,
    maxHeight: Sizes.margin.half + 12 + 42 * 4,
    boxShadow: `0 2px 4px ${Colors.opacity(Colors.black, 0.12)}`,
    background: Colors.white,
    zIndex: 9999,
    cursor: 'pointer',
  },

  suggestion: {
    margin: [2, 0],
    padding: [Sizes.margin.pad, Sizes.margin.half],
    borderRadius: 4,
    background: Colors.white,
  },

  group: {
    margin: [0, Sizes.margin.half],
  },

  title: {
    margin: [Sizes.margin.default, Sizes.margin.half, Sizes.margin.half],
  },

  hover: {
    background: Colors.palette('blue')[1],
  },
})
