import React from 'react'
import { Colors } from '../../../constants'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Text } from '../text'

import Styles from './styles'

export const Label = (props: {
  readonly fit?: boolean
  readonly label?: string
  readonly labelWeight?: React.ComponentProps<typeof Text>['weight']
  readonly sublabel?: string
  readonly asterisk?: boolean
  readonly flex?: boolean
  readonly description?: string
  readonly header?: React.ReactNode
  readonly children?: React.ReactNode
  readonly style?: StyleAttribute
}) => {
  return (
    <Box flex={props.flex} style={[props.fit && Styles.fit, props.style]}>
      <Box row centering="v">
        <Box flex>
          {props.label ? (
            <Text size="small" weight={props.labelWeight} style={Styles.label}>
              {props.label}
              {props.asterisk ? (
                <Text color={Colors.error} element="span">
                  *
                </Text>
              ) : (
                false
              )}
            </Text>
          ) : (
            false
          )}
          {props.sublabel ? (
            <Text size="small" style={Styles.sublabel}>
              {props.sublabel}
            </Text>
          ) : (
            false
          )}
        </Box>
        {props.header}
      </Box>
      {props.children}
      {props.description ? (
        <Text size="tiny" style={Styles.message}>
          {props.description}
        </Text>
      ) : (
        false
      )}
    </Box>
  )
}
