import React, { useEffect, useRef } from 'react'
import { useRefs } from '../../../hooks'
import { CommonHelper } from '../../../helpers'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'

import Styles from './styles'

type Props = {
  readonly accessible?: boolean
  readonly horizontal?: boolean
  readonly flex?: boolean
  readonly allDirection?: boolean
  readonly centering?: boolean | 'v' | 'h'
  readonly scrollEnabled?: boolean
  readonly children?: React.ReactNode
  readonly onHover?: (hover: boolean) => void
  readonly onLayout?: React.ComponentProps<typeof Box>['onLayout']
  readonly onEndReached?: () => void
  readonly onEndReachedThreshold?: number
  readonly useWindowScroll?: boolean
  readonly style?: StyleAttribute
  readonly contentContainerStyle?: StyleAttribute
}

export const ScrollView = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const scrollEnabled = props.scrollEnabled ?? true
    const flex = props.flex ?? true
    const div = useRefs(useRef<HTMLDivElement>(null), ref)

    useEffect(() => {
      if (props.onEndReached) {
        const win = props.useWindowScroll ? window : div.current
        const current = props.useWindowScroll
          ? document.getElementsByTagName('html')[0]
          : div.current

        const onEndReached = CommonHelper.fn.throttle(props.onEndReached, 500, {
          leading: true,
          trailing: false,
        })

        const scrollListener = function () {
          const top = props.useWindowScroll
            ? window.scrollY
            : current?.scrollTop ?? 0
          const viewportHeight = current?.clientHeight ?? 0
          const threshold =
            (props.onEndReachedThreshold ?? 0.5) * viewportHeight
          const height = (current?.scrollHeight ?? 0) - viewportHeight

          if (top > height - threshold) {
            onEndReached()
          }
        }

        win?.addEventListener('scroll', scrollListener)

        return () => {
          win?.removeEventListener('scroll', scrollListener)
        }
      }
    }, [
      props.onEndReached,
      props.useWindowScroll,
      props.onEndReachedThreshold,
      div,
    ])

    return (
      <Box
        ref={div}
        onLayout={props.onLayout}
        accessible={props.accessible}
        flex={flex}
        style={[
          Styles.container,
          !scrollEnabled && Styles.disableScroll,
          props.allDirection && Styles.scrollAllDirection,
          props.style,
        ]}
      >
        <Box
          row={props.horizontal}
          centering={props.centering}
          onHover={props.onHover}
          style={[
            Styles.content,
            props.horizontal && Styles.horizontal,
            props.contentContainerStyle,
          ]}
        >
          {props.children}
        </Box>
      </Box>
    )
  }
)
