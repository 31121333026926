import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.thick + Sizes.margin.pad,
    paddingRight: Sizes.margin.thick + Sizes.margin.pad,
    boxShadow: `0 2px 2px ${Colors.opacity(Colors.black, 0.12)}`,
  },
  normal: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('salmon')[2],
    },
  },
  active: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('salmon')[3],
    },
  },
  disabled: {
    foreground: Colors.white,
    style: {
      opacity: 0.5,
      backgroundColor: Colors.palette('salmon')[2],
    },
  },
  loading: {
    foreground: Colors.white,
    style: {
      backgroundColor: Colors.palette('salmon')[3],
    },
  },
}
