import { Colors, Sizes } from '../../../../constants'

export default {
  style: {
    paddingLeft: Sizes.margin.half,
    paddingRight: Sizes.margin.half,
    background: Colors.transparent,
  },
  normal: {
    foreground: Colors.primaryNew,
  },
  active: {
    foreground: Colors.primaryNew,
  },
  disabled: {
    foreground: Colors.border,
  },
  loading: {
    foreground: Colors.black,
  },
}
