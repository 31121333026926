export default [
  '#FFE9BF', // 0  yellow-10
  '#FFCF75', // 1  yellow-20
  '#F2BC57', // 2  yellow-30
  '#D2982A', // 3  yellow-40
  '#FEF8EE', // 4  yellow-custom
  '#FFED8F', // 5
  '#FFEB40', // 6  extended/yellow-40
  '#FFC000', // 7  extended/yellow-60
  '#FFFCE1', // 8
  '#FFDE16', // 9  extended/yellow-50
  '#ECAF00', // 10 primary/yellow-70
]
