import React, { useContext, useEffect, useState } from 'react'
import { useTransition } from 'react-spring'
import { StackModel } from '../../../models'
import { AppContext, UtilitiesContext } from '../../../contexts'
import { useManager, useManagerProps } from '../../../hooks'
import { CommonStyles } from '../../../styles'
import { NotificationConfig, NotificationStackConfig } from '../../../types'

import { Box } from '../../blocks'
import { NotifierPart } from './_notifier'

import Styles from './styles'

export const NotificationUtilities = (props: {
  // Props goes here
  readonly test?: number
  readonly manager: StackModel<NotificationConfig, NotificationStackConfig>
}) => {
  const app = useContext(AppContext)
  const utilities = useContext(UtilitiesContext)
  const [stacks, setStack] = useState(
    Array.from(props.manager.get('stack').values())
  )
  const [area, setArea] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })
  const rect = useManagerProps(props.manager, (pr) => pr.page.rect)
  const transitions = useTransition(stacks, (stack) => stack.id, {
    from: {
      opacity: 0,
      transform: 'scale(0.9) translateY(100px)',
    },
    enter: {
      opacity: 1,
      transform: 'scale(1) translateY(0px)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(1) translateY(0px)',
    },
  })

  useManager(
    props.manager,
    (properties) => {
      setStack(Array.from(properties.stack.values()))
    },
    []
  )

  useEffect(() => {
    if (rect.height && rect.width && app.media.smaller('tablet')) {
      setArea({
        top: rect.y,
        left: rect.x,
        bottom: window.innerHeight - rect.y - rect.height,
        right: window.innerWidth - rect.x - rect.width,
      })
    }
  }, [rect, app.media])

  return (
    <Box
      accessible="children"
      style={[Styles.container, CommonStyles.transition, area]}
    >
      {transitions.map(({ item, props: style, key }) => {
        return (
          <NotifierPart
            {...item.config}
            lang={app.lang}
            isMobile={app.media.smaller('tablet')}
            key={key}
            onPress={
              item.config.onPress
                ? () => {
                    utilities.notification.hide(item.id)
                    item.config.onPress?.()
                  }
                : undefined
            }
            onClose={() => utilities.notification.hide(item.id)}
            style={style}
          />
        )
      })}
    </Box>
  )
}
