import React, { ReactNode, useState } from 'react'
import { RouterProps, RouteComponentProps } from 'react-router'
import { AnimationStackModel } from '../../models'
import { Logger } from '../../libs/com'
import { useComponentId } from '../../hooks'
import { CommonHelper } from '../../helpers'
import { Defaults } from '../../constants'
import { AlertConfig, ModalConfig } from '../../types'

import { PageLoader, PagePrompt } from '../../modules/blocks'

export function PageContextFactory() {
  const context = React.createContext({
    // Modal needs to be provided by each page
    id: '',
    depth: 0,
    location: {} as RouteComponentProps['location'],
    heading: {
      headings: new Map() as Map<
        number,
        {
          rect: DOMRectReadOnly
          title: string
        }
      >,
      stickies: new Map() as Map<
        number,
        {
          rect: DOMRectReadOnly
          children: ReactNode
        }
      >,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      add(id: number, title: string, rect: DOMRectReadOnly) {
        Logger.log(
          'WARN',
          'You are calling heading.add from outside of a page context.'
        )
      },
      addStickies(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id: number,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        rect: DOMRectReadOnly,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        children: ReactNode,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        scrollTop: number
      ) {
        Logger.log(
          'WARN',
          'You are calling heading.addStickies from outside of a page context.'
        )
      },
    },
    modal: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      loader(children?: ReactNode | string) {
        Logger.log(
          'WARN',
          'You are calling modal.loader from outside of a page context.'
        )
        return CommonHelper.fn.NOOP
      },
      prompt(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        config: AlertConfig &
          Pick<ModalConfig, 'animation' | 'closeOnOverlayPress'>
      ) {
        Logger.log(
          'WARN',
          'You are calling modal.prompt from outside of a page context.'
        )
        return CommonHelper.fn.NOOP
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      open(config: ModalConfig) {
        Logger.log(
          'WARN',
          'You are calling modal.loader from outside of a page context.'
        )
        return CommonHelper.fn.NOOP
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      close(id?: number): boolean {
        Logger.log(
          'WARN',
          'You are calling modal.close from outside of a page context.'
        )
        return true as boolean
      },
    },
    // Navigator also needs to be provided by each page
    navigator: {
      navigate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        path: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        params?: Record<string, unknown>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        search?: string
      ) {
        Logger.log(
          'WARN',
          'You are calling navigator.navigate from outside of a page context.'
        )
      },
      back() {
        Logger.log(
          'WARN',
          'You are calling navigator.back from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      replace(path: string, params?: Record<string, unknown>, search?: string) {
        Logger.log(
          'WARN',
          'You are calling navigator.replace from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      reset(path: string, params?: Record<string, unknown>, search?: string) {
        Logger.log(
          'WARN',
          'You are calling navigator.reset from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      push(path: string, params?: Record<string, unknown>, search?: string) {
        Logger.log(
          'WARN',
          'You are calling navigator.push from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      top(params?: Record<string, unknown>, search?: string) {
        Logger.log(
          'WARN',
          'You are calling navigator.top from outside of a page context.'
        )
      },
      tab(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        path: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        target?: 'self' | 'parent' | 'top' | 'blank',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        features?: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        replace?: boolean
      ) {
        Logger.log(
          'WARN',
          'You are calling navigator.tab from outside of a page context.'
        )
      },
      reload() {
        Logger.log(
          'WARN',
          'You are calling navigator.reload from outside of a page context.'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      prefetch(url: string) {
        Logger.log(
          'WARN',
          'You are calling navigator.prefetch from outside of a page context.'
        )
      },
    },
  })

  return {
    PageContext: context,
    usePageContext(
      history: RouterProps['history'],
      ModalManager: AnimationStackModel<ModalConfig>,
      location: RouteComponentProps['location'],
      depth: number
    ) {
      const _id = useComponentId()
      const [headings, setHeadings] = useState<
        Map<
          number,
          {
            title: string
            rect: DOMRectReadOnly
          }
        >
      >(new Map())
      const [stickies, setStickies] = useState<
        Map<
          number,
          {
            children: ReactNode
            rect: DOMRectReadOnly
          }
        >
      >(new Map())

      return {
        id: String(_id),
        depth,
        location,
        heading: {
          get headings() {
            return headings
          },
          get stickies() {
            return stickies
          },
          add(id: number, title: string, r: DOMRectReadOnly) {
            setHeadings(
              new Map(
                headings.set(id, {
                  title,
                  rect: r,
                })
              )
            )
          },
          addStickies(
            id: number,
            r: DOMRectReadOnly,
            c: ReactNode,
            sT: number
          ) {
            setStickies(
              new Map(
                stickies.set(id, {
                  rect: new DOMRectReadOnly(r.x, r.top - sT, r.width, r.height),
                  children: c,
                })
              )
            )
          },
        },
        modal: {
          loader(children?: string | ReactNode) {
            // Will wait LOADER_WAIT ms before displaying loader
            let id: number | null = null
            const timeout = setTimeout(() => {
              id = ModalManager.add({
                closeOnOverlayPress: false,
                component() {
                  return <PageLoader children={children} />
                },
              })
            }, Defaults.LOADER_DELAY_DURATION)

            return () => {
              if (id === null) {
                clearTimeout(timeout)
              } else {
                ModalManager.remove(id)
              }
            }
          },
          prompt({
            closeOnOverlayPress,
            animation,
            ...config
          }: AlertConfig &
            Pick<ModalConfig, 'animation' | 'closeOnOverlayPress'>) {
            const modalId = ModalManager.add({
              closeOnOverlayPress,
              animation,
              component(props) {
                return <PagePrompt onClose={props.onClose} {...config} />
              },
            })

            return () => {
              ModalManager.remove(modalId)
            }
          },
          open(...args: Parameters<typeof ModalManager['add']>) {
            const modalId = ModalManager.add(...args)

            return () => {
              ModalManager.remove(modalId)
            }
          },
          close(id?: number): boolean {
            return ModalManager.remove(id)
          },
        },
        navigator: {
          navigate(routeName: string, params = {}, search) {
            history.push({
              pathname: '/' + routeName,
              state: params,
              search,
            })
          },

          back() {
            history.goBack()
          },

          replace(routeName = '', params = {}, search) {
            history.replace({
              pathname: '/' + routeName,
              state: params,
              search,
            })
          },

          reset(routeName = '', params = {}, search) {
            history.replace({
              pathname: '/' + routeName,
              state: params,
              search,
            })
          },

          push(routeName: string, params = {}, search) {
            history.push({
              pathname: '/' + routeName,
              state: params,
              search,
            })
          },

          top(params: Record<string, unknown>, search) {
            history.replace({
              pathname: '/',
              state: params,
              search,
            })
          },

          tab(
            routeName: string,
            target: 'self' | 'blank' | 'parent' | 'top' = 'blank',
            features?: string,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            replace?: boolean
          ) {
            if (routeName.match(/^([a-z][a-z0-9+\-.]*):/)) {
              window.open(routeName, `_${target}`, features)
            } else {
              window.open(`/${routeName}`, `_${target}`, features)
            }
          },

          reload() {
            window.location.reload()
          },

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          prefetch(url: string) {
            // TODO: provide a means to prefetch programmatically
          },
        },
      } as React.ContextType<typeof context>
    },
  }
}
