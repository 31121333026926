import { StyleSheet } from '../../../libs/com'
import { Input, Sizes } from '../../../constants'

export default StyleSheet.create({
  day: {
    height: Input.height - 2,
    flexBasis: 55,
    flexShrink: 1,
    flexGrow: 1,
    marginRight: Sizes.margin.default,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
  },

  month: {
    height: Input.height - 2,
    flexBasis: 140,
    flexShrink: 1,
    flexGrow: 1,
    marginRight: Sizes.margin.default,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
  },

  year: {
    height: Input.height - 2,
    flexBasis: 85,
    flexShrink: 1,
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
  },
})
