import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  box: {
    borderStyle: 'solid',
    borderWidth: 0,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    outline: 0,
  },

  flex: {
    flex: 1,
  },

  pressing: {
    opacity: 0.6,
  },

  row: {
    flexDirection: 'row',
  },

  centerX: {
    justifyContent: 'center',
  },

  centerY: {
    alignItems: 'center',
  },

  centering: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  clickable: {
    transition: '.1s opacity',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  reverse: {
    flexDirection: 'column-reverse',
  },

  reverseRow: {
    flexDirection: 'row-reverse',
  },

  unaccessible: {
    pointerEvents: 'none !important' as 'none',
    '& *': {
      pointerEvents: 'none !important' as 'none',
    },
  },

  unaccessibleSelf: {
    pointerEvents: 'none !important' as 'none',
    '& *': {
      pointerEvents: 'auto',
    },
  },

  between: {
    justifyContent: 'space-between',
  },

  around: {
    justifyContent: 'space-around',
  },

  evenly: {
    justifyContent: 'space-evenly',
  },

  flexEnd: {
    justifyContent: 'flex-end',
  },
})
