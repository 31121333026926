import React from 'react'
import { Colors } from '../../../constants'

import { Text } from '../text'

import Styles from './styles'

export const TitlePart = (props: { readonly title: string }) => {
  return (
    <Text size="heading-6" transform="uppercase" color={Colors.palette('grey')[4]} style={Styles.title}>
      {props.title}
    </Text>
  )
}
