export default [
  '#FFB6B6', // 0  red-10
  '#FF6969', // 1  red-20
  '#E60000', // 2  red-30
  '#B00000', // 3  red-40
  '#FDE6E6', // 4  red-custom
  '#FA2B2C', // 5
  '#FEF2F2', // 6
  '#FFDADA', // 7
  '#DB361A', // 8 text/critical
  '#F6C6D9', // 9
  '#FDE1DC', // 10 extended/red-20
  '#FEF3F1', // 11
  '#E02D38', // 12
  '#C2250A', // 13
  '#E2508A', // 14
]
