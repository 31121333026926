import { StyleSheet } from '../../../libs/com'
import { Colors, Input, Sizes } from '../../../constants'

export default StyleSheet.create({
  message: {
    marginLeft: Sizes.margin.compact,
  },

  prefix: {
    marginLeft: -Sizes.margin.compact,
    background: Colors.palette('grey')[0],
    borderRightWidth: 1,
    borderRightColor: Colors.palette('grey')[2],
    borderRightStyle: 'solid',
    borderTopLeftRadius: Input.radius,
    borderBottomLeftRadius: Input.radius,
    padding: [0, Input.padding],
    alignSelf: 'stretch',
    marginRight: Input.padding,
  },

  icon: {
    marginRight: Sizes.margin.half,
  },

  loader: {
    width: Sizes.margin.thick,
  },

  textareaLoader: {
    marginRight: Sizes.margin.compact,
  },

  input: {
    marginBottom: Sizes.margin.pad,
  },

  inputWrapper: {
    marginBottom: Sizes.margin.thick,
    padding: [10, Sizes.margin.default, 14],
    background: Colors.white,
    border: ['1px', 'solid', Colors.palette('grey')[11]],
    borderRadius: 16,
    color: Colors.palette('grey')[8],
  },

  inputDisabled: {
    marginBottom: Sizes.margin.thick,
    padding: [10, Sizes.margin.default, 14],
    border: ['1px', 'solid', Colors.palette('grey')[11]],
    borderRadius: 16,
    background: Colors.palette('grey')[11],
  },

  inputBox: {
    fontFamily: Input.fontFamily,
    fontSize: Input.fontSize + 2,
    color: Colors.palette('grey')[13],
  },

  box: {
    border: ['0px', 'solid', Colors.palette('grey')[11]],
    paddingLeft: 0,
    marginBottom: 0,
  },

  inputInvalid: {
    border: ['1px', 'solid', Colors.error],
  },
})
