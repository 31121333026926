import { StyleSheet } from '../../../libs/com'
import { Colors, Sizes } from '../../../constants'

export default StyleSheet.create({
  clearer: {
    marginLeft: Sizes.margin.half,
    zIndex: 1,
  },

  icon: {
    marginRight: Sizes.margin.compact,
  },

  input: {
    opacity: 0,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    cursor: 'pointer',
  },

  colorGrey: {
    color: Colors.palette('grey')[4],
  },
})
