import { CommonHelper } from '../helpers'
import { Logger } from '../libs/com/logger'

let margin = {
  pad: 4,
  half: 8,
  compact: 12,
  default: 16,
  medium: 20,
  thick: 24,
  double: 32,
  page: 40,
}

export const Sizes = Object.freeze({
  get margin() {
    return margin
  },
  set margin(m: typeof margin) {
    CommonHelper.object.keys(m).forEach((key) => {
      Logger.log('INFO', `Setting margin ${key}:`, m[key])
    })

    margin = m
  },

  app: Object.freeze({
    height: window.innerHeight,
    width: window.innerWidth,
    percent(num: number, type: 'w' | 'h') {
      if (type === 'w') {
        return (window.innerWidth * num) / 100
      } else {
        return (window.innerHeight * num) / 100
      }
    },
  }),
})
