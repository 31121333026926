import React from 'react'
import { StyleSheet } from '../../../libs/com'
import { CommonHelper } from '../../../helpers'
import { Colors } from '../../../constants'
import { StyleAttribute } from '../../../types'

import { Box } from '../box'

const shadow = CommonHelper.fn.memoize(
  (x: number, y: number, blur: number, spread: number, color: string) => {
    return StyleSheet.create({
      shadow: {
        boxShadow: `${x}px ${y}px ${blur}px ${spread}px ${color}`,
      },
    }).shadow
  }
)

export const Shadow = (props: {
  readonly accessible?: boolean
  readonly animated?: boolean
  readonly flex?: boolean
  readonly inactive?: boolean
  readonly element?: React.ComponentProps<typeof Box>['element']
  readonly centering?: React.ComponentProps<typeof Box>['centering']
  readonly boxSpread?: React.ComponentProps<typeof Box>['spread']
  readonly row?: boolean
  readonly color?: string
  readonly x?: number
  readonly y?: number
  readonly blur?: number
  readonly spread?: number
  readonly children?: React.ReactNode
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  readonly onPress?: () => void
  readonly onHover?: (hovering: boolean) => void
  readonly style?: StyleAttribute
}) => {
  const color = props.color ?? Colors.opacity(Colors.black, 0.12)
  const x = props.x ?? 0
  const y = props.y ?? 2
  const blur = props.blur ?? 2
  const spread = props.spread ?? 0

  return (
    <Box
      element={props.element}
      spread={props.boxSpread}
      animated={props.animated}
      centering={props.centering}
      accessible={props.accessible}
      flex={props.flex}
      row={props.row}
      tracker={props.tracker}
      trackerParam={props.trackerParam}
      onPress={props.onPress}
      onHover={props.onHover}
      style={[!props.inactive && shadow(x, y, blur, spread, color), props.style]}
    >
      {props.children}
    </Box>
  )
}
