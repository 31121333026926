import React from 'react'
import { StyleAttribute, AlertAction } from '../../../types'

import { Button } from '../../blocks'

const title = (type: AlertAction['type']) => {
  switch (type) {
    case 'CANCEL':
      return 'Cancel'
    case 'OK':
    case 'INFO':
    default:
      return 'Okay'
  }
}

const button = (
  type: AlertAction['type']
): React.ComponentProps<typeof Button>['theme'] => {
  switch (type) {
    case 'CANCEL':
      return 'secondary'
    case 'OK':
      return 'warning'
    case 'OK_BLACK':
      return 'black'
    case 'INFO':
    default:
      return 'border-text'
  }
}

export const ButtonPart = (
  props: AlertAction & {
    readonly style?: StyleAttribute
  }
) => {
  return (
    <Button
      theme={button(props.type)}
      onPress={props.onPress}
      style={props.style}
    >
      {props.title ?? title(props.type)}
    </Button>
  )
}
