export const FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE = {
  TOGGLE_LACAK_STATUS_PENDANAAN_MENTOR: false,
  TOGGLE_NPWP_VERIFICATION_MENTOR: false,
  TOGGLE_BANK_VERIFICATION_MENTOR: false,
  TOGGLE_MENTOR_MAINTENANCE_MODE: false,
  MENTOR_ENABLE_HIDE_MENTOR_HONORARIUM: false,
  MENTOR_SYSTEM_BANNER: '',
  MSIB_ONGOING_CYCLE: 3,
  BATCH_TIMELINE: `{
    "data": [
      {
        "title": "Batch 1",
        "cycle": 1,
        "start": "2021-08-01",
        "end": "2021-12-01",
        "length": 5
      },
      {
        "title": "Batch 2",
        "cycle": 2,
        "start": "2022-02-01",
        "end": "2022-07-01",
        "length": 5
      },
      {
        "title": "Batch 3",
        "cycle": 3,
        "start": "2022-08-01",
        "end": "2022-12-01",
        "length": 5
      }
    ]
  }`,
}
