import { StyleSheet } from '../../../libs/com'

export default StyleSheet.create({
  // sans: {
  // 	fontFamily: config.sans,
  // 	whiteSpace: 'pre-wrap',
  // },

  text: {
    whiteSpace: 'pre-wrap',
    userSelect: 'text',
    MozUserSelect: 'text',
    WebkitUserSelect: 'text',
  },

  unselectable: {
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },

  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  multilineEllipsis: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap',
  },

  left: {
    textAlign: 'left',
  },

  center: {
    textAlign: 'center',
  },

  right: {
    textAlign: 'right',
  },

  justify: {
    textAlign: 'justify',
  },

  italic: {
    fontStyle: 'italic',
  },

  capitalize: {
    textTransform: 'capitalize',
  },

  lower: {
    textTransform: 'lowercase',
  },

  upper: {
    textTransform: 'uppercase',
  },

  underline: {
    textDecorationLine: 'underline',
  },
})
