import {
  ensureInitialized,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  RemoteConfig,
} from 'firebase/remote-config'

// import { Defaults } from '../constants'
import { NonFunction } from '../types/common'
import { Firebase } from '../libs/com/firebase'
import { BaseClass } from './_base'
import { FirebaseApp } from 'firebase/app'

export class FirebaseRemoteConfigModel<
  Model extends
    | Record<string, boolean | string | number>
    | Record<string, NonFunction>
> extends BaseClass {
  private rc: RemoteConfig | undefined = undefined
  private dc: Model

  constructor(defaultConfig: Model) {
    super('Firebase Remote Config')

    this.dc = defaultConfig

    // Disable remote config if Firebase is not defined
    if (!Firebase) {
      this.warn(
        "Remote Config is disabled because Firebase isn't ready yet. Try to call .reinit(<Firebase Instance>) to enable Remote Config."
      )
      return
    }

    this.reinit(Firebase)
  }

  public reinit(firebase: FirebaseApp) {
    if (this.rc) {
      this.warn('Remote Config is already initialized.')
      return
    }

    this.rc = getRemoteConfig(firebase)
    this.rc.defaultConfig = this.dc as Record<string, boolean | string | number>

    ensureInitialized(this.rc).then(() => {
      this.info('Firebase Remote Config Initialized')
    })

    this.rc.settings.minimumFetchIntervalMillis = 0
  }

  public getValue<T extends keyof Model>(key: T): Model[T]
  public getValue<T extends keyof Model>(
    key: T,
    forceRemote: true
  ): Model[T] | undefined
  public getValue<T extends keyof Model>(
    key: T,
    forceRemote?: true
  ): Model[T] | undefined {
    if (!this.rc) {
      this.warn("Firebase remote config isn't ready yet!")
      // return undefined instead of default value when forceRemote is true
      return forceRemote ? undefined : this.dc[key]
    }

    const value = getValue(this.rc, key as string)

    this.info(
      `Getting remote config for key: ${key}, source: ${value.getSource()}, value:`,
      value.asString()
    )

    if (typeof this.dc[key] === 'string') {
      return value.asString() as Model[T]
    } else if (typeof this.dc[key] === 'boolean') {
      return value.asBoolean() as Model[T]
    } else if (typeof this.dc[key] === 'number') {
      return value.asNumber() as Model[T]
    }

    return value.asString() as Model[T]
  }

  public async fetch() {
    if (!this.rc) {
      this.warn("Firebase remote config isn't ready yet!")
      return false
    }

    return fetchAndActivate(this.rc)
  }
}

export class TargetedFlagModel<
  Model extends
    | Record<string, boolean | string | number>
    | Record<string, NonFunction>
> extends FirebaseRemoteConfigModel<Model> {
  private tc: Model
  private uids: string[]

  constructor(defaultConfig: Model, testerConfig: Model, userIds: string[]) {
    super(defaultConfig)
    this.tc = testerConfig
    this.uids = userIds
  }

  public getValueForUser<T extends keyof Model>(
    key: T,
    userId: string
  ): Model[T] | undefined {
    if (key in this.tc && this.uids.includes(userId)) {
      return this.tc[key]
    }
    return super.getValue(key)
  }
}
