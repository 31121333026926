import React, { useRef } from 'react'
import { FormContext, useFormContext } from '../../../contexts'
import { useMount } from '../../../hooks'
import { STATE } from '../../../constants'

import { Box } from '../box'

export function Form({
  onUpdate,
  onUpdateState,
  ...props
}: React.ComponentProps<typeof Box> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly onUpdate: <T extends [any, any, any]>(
    data: Map<
      string,
      {
        value: T
        state: STATE
      }
    >
  ) => void
  readonly onUpdateState?: (state: STATE) => void
}) {
  const form = useRef(useFormContext())

  useMount(() => {
    // Register listener on update data
    form.current.onChange(onUpdate)

    if (onUpdateState) {
      // Register listener on state change
      form.current.onStateChange(onUpdateState)
    }
  }, [onUpdate, onUpdateState])

  return (
    <FormContext.Provider value={form.current}>
      <Box {...props} />
    </FormContext.Provider>
  )
}
