import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { GA, StyleSheet } from '../../../libs/com'
import { Colors } from '../../../constants'
import { InputStyles } from '../../../styles'
import { Autocomplete, StyleAttribute } from '../../../types'

import { Box } from '../box'
import { Icon } from '../icon'
import { Text } from '../text'

import Styles from './styles'

export const Textarea = ({
  onChange,
  onChangeFocus,
  onBackspace,
  onSubmit,
  ...props
}: {
  readonly testId?: string
  readonly fit?: boolean
  readonly placeholder?: string
  readonly autofocus?: boolean
  readonly autocomplete?: Autocomplete
  readonly autocorrect?: boolean
  readonly autocapitalize?: boolean
  readonly defaultValue?: string | number
  readonly value?: string | number
  readonly valid?: boolean
  readonly clearable?: boolean
  readonly disabled?: boolean
  readonly readonly?: boolean
  readonly spellcheck?: boolean
  readonly minlength?: number
  readonly maxlength?: number
  readonly tabindex?: number
  readonly rows?: number
  readonly prefix?: React.ReactNode
  readonly suffix?: React.ReactNode
  readonly resizable?: boolean
  readonly counter?: boolean
  readonly count?: 'word' | 'char'
  readonly tracker?: string
  readonly trackerParam?: Record<string, string>
  // TODO: readonly autogrow?: boolean | number
  readonly onChangeFocus?: (focused: boolean) => void
  readonly onChange?: (value: string) => void
  readonly onBackspace?: (value: string) => void
  readonly onSubmit?: (value: string) => void
  readonly input?: StyleAttribute
  readonly style?: StyleAttribute
}): ReactElement => {
  const fit = props.fit ?? false
  const placeholder = props.placeholder ?? 'Input here…'
  const input = useRef<HTMLTextAreaElement>(null)
  const cursor = useRef<number>(-1)
  const shouldSendTracker = useRef(true)
  const [hasValue, setHasValue] = useState(
    !!(props.defaultValue ?? props.value)
  )
  const [focused, setFocused] = useState(false)
  const [counter, setCounter] = useState(
    props.count === 'word'
      ? String(props.defaultValue ?? props.value ?? '')
          .split(' ')
          .filter(Boolean).length
      : String(props.defaultValue ?? props.value ?? '').length
  )

  useLayoutEffect(() => {
    if (props.autofocus && input.current) {
      input.current.focus()
    }
  }, [props.autofocus])

  const onPress = useCallback(() => {
    input.current?.focus()
  }, [])

  const onClear = useCallback(() => {
    if (input.current) {
      input.current.value = ''

      setHasValue(false)

      if (onChange) {
        onChange('')
      }
    }
  }, [onChange])

  const onFocus = useCallback(() => {
    setFocused(true)

    if (onChangeFocus) {
      onChangeFocus(true)
    }
  }, [onChangeFocus])

  const onBlur = useCallback(() => {
    setFocused(false)
    shouldSendTracker.current = true

    if (onChangeFocus) {
      onChangeFocus(false)
    }
  }, [onChangeFocus])

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (onBackspace && (e.key === 'Delete' || e.key === 'Backspace')) {
        onBackspace(e.currentTarget.value)
      }
      if (onSubmit && e.key === 'Enter') {
        onSubmit(e.currentTarget.value)
      }
    },
    [onBackspace, onSubmit]
  )

  const onChangeVal = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value

      cursor.current = e.target.selectionStart

      setHasValue(!!val)

      // Send analytics to GA
      if (props.tracker && shouldSendTracker.current) {
        shouldSendTracker.current = false
        GA.sendEvent('fill_input', {
          input_name: props.tracker,
          ...(props.trackerParam ?? {}),
        })
      }

      if (props.counter) {
        const count =
          props.count === 'word'
            ? val.split(' ').filter(Boolean).length
            : val.length
        setCounter(count)
      }

      if (onChange) {
        onChange(val)
      }
    },
    [onChange, props.count, props.counter, props.tracker, props.trackerParam]
  )

  useEffect(() => {
    if (input.current && cursor.current > -1) {
      input.current.selectionStart = cursor.current
      input.current.selectionEnd = cursor.current
    }
  })

  return (
    <Box
      row
      centering
      onPress={onPress}
      style={[
        InputStyles.container,
        props.disabled
          ? InputStyles['container-disabled']
          : props.readonly
          ? InputStyles['container-readonly']
          : undefined,
        props.valid && InputStyles['container-valid'],
        props.valid === false && InputStyles['container-invalid'],
        focused && InputStyles['container-focused'],
        fit && InputStyles.fit,
        Styles.container,
        props.style,
      ]}
    >
      {props.prefix}
      <textarea
        data-testid={props.testId}
        ref={input}
        rows={props.rows}
        placeholder={placeholder}
        maxLength={props.count === 'word' ? undefined : props.maxlength}
        minLength={props.count === 'word' ? undefined : props.minlength}
        disabled={props.disabled}
        readOnly={props.readonly}
        tabIndex={props.tabindex}
        defaultValue={props.defaultValue}
        value={props.value}
        autoFocus={props.autofocus}
        autoComplete={props.autocomplete}
        autoCapitalize={props.autocapitalize ? 'on' : 'off'}
        autoCorrect={props.autocorrect ? 'on' : 'off'}
        spellCheck={props.spellcheck ? 'true' : 'false'}
        onChange={onChangeVal}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        {...StyleSheet.classNameAndStyle([
          InputStyles.input,
          props.disabled
            ? InputStyles['input-disabled']
            : props.readonly
            ? InputStyles['input-readonly']
            : undefined,
          props.valid && InputStyles['input-valid'],
          props.valid === false && InputStyles['input-invalid'],
          focused && InputStyles['input-focused'],
          props.resizable && Styles.resizable,
          Styles.textarea,
          props.input,
        ])}
      />
      {props.counter ? (
        <Box style={Styles.counterInfo}>
          <Text size="tiny" color="grey">
            {counter} / {props.maxlength}
          </Text>
        </Box>
      ) : (
        false
      )}
      {props.suffix ??
        (hasValue && !props.readonly && !props.disabled && props.clearable ? (
          <Box centering style={Styles.clearer} onPress={onClear}>
            <Icon name="decline" size={12} color={Colors.border} />
          </Box>
        ) : (
          false
        ))}
    </Box>
  )
}
